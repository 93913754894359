import React, { useEffect, useReducer, useState } from 'react';

import { Avatar, Card, Popover, Row, Col, Select, Button, Space, Table, Drawer, Form, DatePicker, Radio, TimePicker, Modal, Divider, Spin, Typography, message, Checkbox} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InfoOutlined, FilterOutlined} from '@ant-design/icons';


import { Icon } from 'leaflet';
import '../../App.css';
import 'leaflet/dist/leaflet.css';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { REALTIME_EVENT, REALTIME_EVENT_DETAILS, REALTIME_EVENT_ERROR, REALTIME_EVENT_SUCCESS, REALTIME_MAP_DATA, REALTIME_MAP_DATA_ERROR, REALTIME_MAP_DATA_SUCCESS, REALTIME_MAP_EVENT_DETAILS } from './constants';
import axios from 'axios';
import reducer from './reducer';
import moment from 'moment';

// import VehicleModal from '../../images/vehicleModal.png';
// import DrumModal from '../../images/drumModal.png';
// import ClampingModal from '../../images/clampingModal.png';
// import mapImg from '../../images/mapImg.png';
// import mapImgDeep from '../../images/ManualDeep.png';
// import mapImgMachine from '../../images/JCBMap.png';
// import mapImgTunneling from '../../images/TunnelingMap.png';
// import modalDefault from '../../images/modalDefault.png';
// import modalMan from '../../images/ManWalking.png';
import { useAuth } from '../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import eventsicons from '../eventstypes';
import { enddatetimeCoverter, startdatetimeCoverter } from '../datetime-converter';
import Map from '../Map/map';
import dayjs from 'dayjs';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import Papa from 'papaparse';
import Plot from 'react-plotly.js';
import leak from '../WellVisualization/LeakDetection/leak_coordinates.csv';


// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};


// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	iconSize:[38,38]
// });

// const pidsicon = new Icon({
// 	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
// 	iconUrl:require('../../images/refinery.png'),
// 	iconSize:[38,38]
// });

export default function RealtimeEvents() {
	const {getRegion, regiondata, handleGetMapdata, mapdata, getAllEvents, eventsData, optionalpipedata, handleOptionalPipelinedata, getUserAllocation ,getUserAllocationData, getPids, pidsData, wellinfo, getWellInfo} = useAuth();
	const [view, setSize] = useState('List');
	const { state } = useLocation();
	const [filterDrawer, setFilterdrawer] = useState(false);
	const [modelOpen, setModelOpen] = useState(false);
	const [filterEvent, setFilterEvent] = useState(['all']);
	const [filterDate, setFilterDate] = useState(state && state.data.date ? state && state.data.date : new Date());
	const [filterStartTime, setFilterStartTime] = useState(null);
	const [filterEndTime, setFilterEndTime] = useState(null);
	const [filterRegion, setFiltetRegion] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const [sort, setSort] = useState('-1');
	const navigate = useNavigate();
	const [allocationId, setAllocationId] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [date, setDate] = useState(state && state.data.date ? state.data.date : new Date());
	const [startTime, setStartTime] = useState(dayjs('00:00:00', 'HH:mm:ss'));
	const [endTime, setEndTime] = useState(dayjs('23:59:59', 'HH:mm:ss'));
	const [filterPids, setFilterPids] = useState(null);
	const [mapModelOpen, setMapModelOpen] = useState(false);
	const [filterLastHour, setFiltetLastHour] = useState(1);
	const [timeSelection, setTimeSelection] = useState(state && state.data.date ? true : false);
	const [wellId, setWellId] = useState({id:null,name:''});
	const [wellModal, setWellModal] = useState(false);
	const [wellCoordinatesurl, setWellCoordinatesurl] = useState(null);	
	const [meshData, setMeshData] = useState({ x: [], y: [], z: [], color: [] });
	const [leakData, setLeakData] = useState({ x: [], y: [], z: [] });
	const [meshSpin, setMeshSpin] = useState(false);	

	// const [regionId, setRegionId] = useState(null);
	// const [eventsMap, setEventsMap] = useState();
	// const [date, setDate] = useState(new Date().toLocaleDateString('en-UK', {
	// 	year: 'numeric',
	// 	month: '2-digit',
	// 	day: '2-digit',
	// }));
	const initalState = { realtime_events: [],events : [], pending: false, error: false, realtime_events_details: null, eventdatas : [], pagination: { total: 0, limit: 10, skip: 0, current: 1 }, mapdatas : [], realtime_map_events_details : null};
	const [data, dispatch] = useReducer(reducer, initalState);
	// let realtimeEventsList = [];
	let eventData = [];
	let regions = [];

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));

	const from_datetimeobj=[];

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const options = [
		{
			value: '-1',
			label: `${langdata && langdata.SortRecentEventFirst ? langdata.SortRecentEventFirst: 'Sort: Recent Event First'}`,
		},
		{
			value: '1',
			label:  `${langdata && langdata.SortOldEventFirst ? langdata.SortOldEventFirst: 'Sort: Old Event First'}`,
		},
	];

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.realtimeeventinfo1 ? langdata.realtimeeventinfo1: 'Details of events generated in the OneView system. Displays the events in the last 1 hour by default. Click on filter icon to use event filter options.'}</p>
			<br/>
			<p>{langdata && langdata.realtimeeventinfo2 ? langdata.realtimeeventinfo2: 'Toggle to Map view for viewing location of events plotted on pipeline.'}</p>
			<br/>
		</Card>
	);

	if(filterDate && filterDate !=='1970-01-01' && filterStartTime){
		var resultArray = filterStartTime.split(',').map(function(filterStartTime){return Number(filterStartTime);});
		if(resultArray.length > 0) {
			filterDate.setHours(resultArray[0],resultArray[1],resultArray[2]);
			let startdatetime=startdatetimeCoverter(filterDate);
			from_datetimeobj.push({startdatetime});

		}

	}

	const to_datetimeobj=[];
	if(filterDate && filterDate !=='1970-01-01' && filterEndTime ){


		var resultEndArray = filterEndTime.split(',').map(function(filterEndTime){return Number(filterEndTime);});
		if(resultEndArray.length > 0){
			filterDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2]);
			// filterDate.setHours(parseFloat(filterEndTime));
			let enddatetime=enddatetimeCoverter(filterDate);
			to_datetimeobj.push({enddatetime});

		}

	}
	

	useEffect(() => {
		if(!authtoken || featuresModule.realtimeEventsEnabled == false){
			navigate('/');
		}
		if(featuresModule.realtimeEventsEnabled == false) {
			navigate('/dashboard');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
			getRegion(authtoken);
		}
		if(oneviewType.toLowerCase() == 'well'){
			getWellInfo(authtoken);
		}
		getAllEvents(authtoken);
		getPids(authtoken);
		loadEvents(1);
		loadMapData();
	},[authtoken, filterRegion, sort, allocationId]);

	useEffect(() => {
		const interval = setInterval(() => {
			// loadEvents(1);
			// loadMapData();
			navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 120);
	}, []);

	useEffect(() => {
		if (map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,filterRegion,startCh,endCh);
			handleOptionalPipelinedata(authtoken,pipelineId,filterRegion,startCh,endCh);      
		}
	},[authtoken, sort, filterRegion, allocationId]);

	const loadEvents = (pagination) => {

		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let startFilterTime;
		let endFilterTime;
		let startFilterDate;
		let endFilterDate;

		if(timeSelection == false) {
			startFilterTime = `${moment.utc().subtract(filterLastHour, 'hours').format('HH:mm:ss')}.000000`;
			endFilterTime = `${moment.utc().format('HH:mm:ss')}.000000`;
		
			startFilterDate = `${moment.utc().subtract(filterLastHour, 'hours').format('YYYY-MM-DD')}`;
			endFilterDate = `${moment.utc().format('YYYY-MM-DD')}`;
		} else {
			startFilterTime = null;
			endFilterTime = null;
			startFilterDate = null;
			endFilterDate = null;
		}


		// const end = filterEndTime;
		// const start = filterStartTime;
		// const date = filterDate;

    
		// const starttime = moment(start, 'hh:mm:ss a').format('HH:mm:ss');
		// const endtime = moment(end, 'hh:mm:ss a').format('HH:mm:ss');
		// const utcstringstart = `${date} ${starttime}`;
		// const utcstringend = `${date} ${endtime}`;

		// const dateutcstart = moment(utcstringstart, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('HH:mm');
		// const dateutcend = moment(utcstringend, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('HH:mm');
		// const dateutcenddate = moment(utcstringend, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.add(1,'d')
		// 	.format('YYYY-MM-DD');
		// const dateutcstartdate = moment(utcstringstart, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('YYYY-MM-DD');
		let body = JSON.stringify({
			eventenddate: to_datetimeobj && to_datetimeobj.length > 0 ? to_datetimeobj[0].enddatetime.utcdate: endFilterDate ? endFilterDate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			eventstartdate: from_datetimeobj && from_datetimeobj.length > 0 ? from_datetimeobj[0].startdatetime.utcdate : startFilterDate ? startFilterDate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			eventchannel: 'all',
			eventtype: filterEvent,
			starteventtime: from_datetimeobj && from_datetimeobj.length > 0 ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : startFilterTime ? startFilterTime : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			endeventtime: to_datetimeobj && to_datetimeobj.length > 0 ? `${to_datetimeobj[0].enddatetime.utctime}.000000`: endFilterTime ? endFilterTime : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			region_id: filterRegion === 'all' ? null : admin_roles.includes(permission) ? filterRegion : null,
			allocationId : !admin_roles.includes(permission) ? allocationId : null,
			pidsId : filterPids
		});
		dispatch({ type : REALTIME_EVENT});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/events?limit=${_pagination.limit}&skip=${_pagination.skip}&sort=${sort}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : body
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_EVENT_SUCCESS, payload : response.data, page : pagination.current, pageSize : pagination.pageSize});
					// data = response.data
				}else {
					dispatch({ type : REALTIME_EVENT_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_EVENT_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const loadMapData = () => {

		let startFilterTime;
		let endFilterTime;
		let startFilterDate;
		let endFilterDate;

		if(timeSelection == false) {
			startFilterTime = `${moment.utc().subtract(filterLastHour, 'hours').format('HH:mm:ss')}.000000`;
			endFilterTime = `${moment.utc().format('HH:mm:ss')}.000000`;
		
			startFilterDate = `${moment.utc().subtract(filterLastHour, 'hours').format('YYYY-MM-DD')}`;
			endFilterDate = `${moment.utc().format('YYYY-MM-DD')}`;
		} else {
			startFilterTime = null;
			endFilterTime = null;
			startFilterDate = null;
			endFilterDate = null;
		}

		let body = JSON.stringify({
			eventenddate: to_datetimeobj && to_datetimeobj.length > 0 ? to_datetimeobj[0].enddatetime.utcdate: endFilterDate ? endFilterDate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			eventstartdate: from_datetimeobj && from_datetimeobj.length > 0 ? from_datetimeobj[0].startdatetime.utcdate : startFilterDate ? startFilterDate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			eventchannel: 'all',
			eventtype: filterEvent,
			starteventtime: from_datetimeobj && from_datetimeobj.length > 0 ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : startFilterTime ? startFilterTime : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			endeventtime: to_datetimeobj && to_datetimeobj.length > 0 ? `${to_datetimeobj[0].enddatetime.utctime}.000000`: endFilterTime ? endFilterTime : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			region_id: filterRegion === 'all' ? null : admin_roles.includes(permission) ? filterRegion : null,
			allocationId : !admin_roles.includes(permission) ? allocationId : null,
			pidsId : filterPids
		});
		dispatch({ type : REALTIME_MAP_DATA});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/events?limit=10000&sort=${sort}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : body
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_MAP_DATA_SUCCESS, payload : response.data});
					// data = response.data
				}else {
					dispatch({ type : REALTIME_MAP_DATA_ERROR,});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_MAP_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	eventData.push({
		label : `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`,
		value: 'all'
	});

	let pidsList = [{
		value : null,
		label: `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`
	}];

	const lastHours = [
		{
			value : 1,
			label : `1 ${langdata && langdata.Hour ? langdata.Hour :'Hour'}`
		},
		{
			value : 2,
			label : `2  ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 3,
			label : `3 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 6,
			label : `6 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 12,
			label : `12 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 24,
			label : `24 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		// {
		// 	value : 0,
		// 	label : 'Custom'
		// }
	];

	// eslint-disable-next-line array-callback-return
	eventsData && eventsData.map((item) => {
		eventData.push({
			value : item.eventType,
			label : item.eventType
		});
	});

	regions.push({
		label : `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`,
		value : null
	});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});

	pidsList.sort((a,b) => { return a.value - b.value; });

	// eslint-disable-next-line array-callback-return
	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allPidsDatas =[];
	
	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
		
	}
	
	const handleModelCancel = () => {
		setModelOpen(false);
		setMapModelOpen(false);
		setWellModal(false);

	};

	const handleModelOk = (value) => { 
		navigate(`eventDetails/${value}`);
	};

	const columns = [

		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'id',
			key: 'id',
			render: (_, record) => (
				<>	
					<Space size="middle">

						{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor:record.eventConfidence >= 0.9 && record.eventConfidence <= 1 ? 'red' :
					record.eventConfidence >= 0.8 && record.eventConfidence < 0.9 ? 'gold' : record.eventConfidence >= 0.5 && record.eventConfidence < 0.8 ? 'green' : 'violet' , 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>

						}
						<text >
							<a title="More Info" style={{ color : 'black'}} onClick={()=>{key(record.id);}}>{record.id}</a>
							{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
						</text>
					</Space>
				</>

				
			)
		},
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'eventType',
			key: 'eventType',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo :'More Info'} onClick={()=>{key(record.id);}}>{record.eventType}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
			render: (_, record) => (
				<>
					{regiondata && regiondata.map((region) => { if (record.regioninfoId === region.id){ return region.regionName.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pids',
			key: 'pids',
			render: (_, record) => (
				<>
					{pidsData && pidsData.map((pids) => { if (record.pidsinfoId === pids.id){ return pids.name.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'eventChannel',
			key: 'eventChannel',
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'eventDate',
			key: 'eventDate',   
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'odInMeter',
			key: 'odInMeter',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
		},
	];
  
	const handleFilterDrawer = () => {
		setFilterdrawer(true);
	};

	const onCloseFilterDrawer=() => {
		setFilterdrawer(false);
	};

	const key = (value) => {
		dispatch({ type : REALTIME_EVENT_DETAILS, payload : value});
		setModelOpen(true);
	};

	const mapKey = (value, type,name) => {
		if(type=='well'){
			setWellModal(true);
			setWellId({id:value, name:name});
		}
		else{

			dispatch({ type : REALTIME_MAP_EVENT_DETAILS, payload : value});
			setMapModelOpen(true);
		}

	};

	const handleActivity = (event) => {
		setFilterEvent(event);
	};

	const handlePids = (event) => {
		setFilterPids(event);
	};

	const handleLastHour = (event) => {
		setFiltetLastHour(event);
	};

	const changeTimeSelection = (e) => {
		setTimeSelection(e.target.checked);
	};

	const handleDate = (date) => {
		setFilterDate(new Date(date && date.$d));
		setDate(new Date(date && date.$d));
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleStartTime = (time) => {
		setFilterStartTime(moment(time && time.$d).format('HH,mm,ss'));
		setStartTime(moment(time && time.$d).format('HH,mm,ss'));
	};

	const handleEndTime = (time) => {
		setFilterEndTime(moment(time && time.$d).format('HH,mm,ss'));
		setEndTime(moment(time && time.$d).format('HH,mm,ss'));
	}; 

	const handleFilterApply = () => {
		setFilterdrawer(false);
		dispatch({type : REALTIME_EVENT_SUCCESS, payload : []});
		loadEvents(1);
		loadMapData();
	};

	const handleSetSort = (value) => {
		setSort(value);
	};

	const handleSetregion = (value, options) => {
		if(admin_roles.includes(permission)) {
			setFiltetRegion(value);
		} else {
			setAllocationId(value);
			setFiltetRegion(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	// const handleMapSetregion = (value) => {
	// 	setRegionId(value);
	// };

	// const handleSetDate = (e) =>{
	// 	setDate(new Date(e && e.$d).toLocaleDateString('en-UK', {
	// 		day: '2-digit',
	// 		month: '2-digit',
	// 		year: 'numeric'
	// 	}));
	// };


	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const event_markers= [];
	const wells = [];

	wellinfo && wellinfo.map((item) => {
		if(item.latitude && item.longitude){			
			wells.push({
				id:item.id,
				geocode:[item.latitude,item.longitude],
				pop: item.name.toUpperCase()
			},);	
		}
	});

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH :'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB :'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP :'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP :'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP :'TP'}`
			},);
		}


	});

	eventsicons && eventsicons.map((icondata)=>{
		data.mapdatas && data.mapdatas.map((item)=>{
			if(item.eventType==icondata.type){
				let severity = item.eventConfidence >= 0.9 &&item.eventConfidence <= 1 ? 3 : item.eventConfidence >= 0.8 && item.eventConfidence < 0.9 ? 2 : item.eventConfidence >= 0.5 && item.eventConfidence < 0.8 ? 1 : 0;

				event_markers.push({
					geocode:[item.locationDetails.Lat,item.locationDetails.Long],
					pop:item.eventType,
					id:item.id,
					// alarmSeverity:item.alarmSeverity,
					// eslint-disable-next-line no-undef
					ic: new L.icon({
						iconUrl: icondata.severity[parseInt(severity)],
						iconSize: [30, 40],
						iconAnchor:[18, 36]
					})
				},);
			}
		});
	});
  
	const pidsicon = new Icon({
		// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
		iconUrl:require('../../images/Refinery1.png'),
		iconSize: [30, 30],
		iconAnchor:[18, 30]
	});
  
  
	// const markericon = new Icon({
	// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	// 	// iconUrl:require("./location-pin.png"),
	// 	iconSize:[38,38],
	// 	iconAnchor:  [25, 50]
	// });
	const CHicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/ch.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});
	const WBicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/wb.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});
	const KMPicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/kmp.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});
	const TLPicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/tlp.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});

	const TPicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/tp.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});

	const defaulticon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/markerimages/default-white.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36]
	});

	const wellicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/oil-well.png'),
		iconSize: [40, 40],
		iconAnchor:[18, 36]
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const eventListLabel = (
		<>
			<Space>
				<label>{langdata && langdata.EventsinLast ? langdata.EventsinLast :'Events in Last'} </label>
			</Space>
		</>
	);

	const wellKey = (value) => {		
		setWellId({id:value.id, name:value.pop});
		setWellModal(true);
	};


	useEffect(() => {
		if(wellId.id){

			getWellCoordinates();
		}

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId]);

	const getWellCoordinates =()=>{		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellcooridnatesdata?wellId=${wellId.id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					setWellCoordinatesurl(response.data.url);
				}else{
					
					setWellCoordinatesurl(null);
				}
			})
			.catch((error) => {
				setWellCoordinatesurl(null);
				
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	useEffect(() => {
		setMeshSpin(true);
		
		if(wellCoordinatesurl){

		
			// Load cylinder mesh coordinates with Jet colors
			Papa.parse(wellCoordinatesurl, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setMeshData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
						color: data.map(row => `rgb(${Math.round(parseFloat(row.r) * 255)}, ${Math.round(parseFloat(row.g) * 255)}, ${Math.round(parseFloat(row.b) * 255)})`)
					});
					setMeshSpin(false);
				}
			});
			Papa.parse(leak, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setLeakData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
					});
				}
			});
		}else{
			setMeshSpin(false);
			setMeshData({});
		}
	}, [wellCoordinatesurl,wellId]);

	const numPointsPerCircle = 100;
	const numCircles = 100;
	const i = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + index % numPointsPerCircle);
	const j = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + (index % numPointsPerCircle + 1) % numPointsPerCircle);
	const k = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => i[index] + numPointsPerCircle);
	// const l = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => j[index] + numPointsPerCircle);

	const wellData = [
		{
			type: 'mesh3d',
			x: meshData.x,
			y: meshData.y,
			z: meshData.z,
			i: i,
			j: j,
			k: k,
			facecolor: meshData.color,  // Apply color
			colorscale: 'Jet',  // Ensure this is the correct scale for your color data
			showscale: true,
			hoverinfo: 'none',  // Customize the hover info displayed
			text:meshData &&  meshData.z && meshData.z.map(Math.floor),
			// hoverlabel: {
			// 	bgcolor: meshData.color,  // Background color of the tooltip
			// 	font: {
			// 		color: 'white'  // Text color in the tooltip
			// 	}
			// },
			hovertemplate : 'Depth : %{text}<extra></extra>',
			// reversescale:true
		},
		{
			type: 'scatter3d',
			mode: 'markers',
			x: leakData.x,
			y: leakData.y,
			z: leakData.z,
			marker: { size: 5, color: 'red' },
			name: 'Leak Detection',
			hovertemplate : 'Leak Depth : %{z}<extra></extra>',
		}
		
		// {
		//   type: 'scatter3d',
		//   mode: 'markers',
		//   x: leakData.x,
		//   y: leakData.y,
		//   z: leakData.z,
		//   marker: { size: 5, color: 'red' },
		//   name: 'Leak Detection'
		// }
	];

	const wellLayout = {
		scene: {
			xaxis: { title: 'X' },
			yaxis: { title: 'Y' },
			zaxis: { title: 'Depth (m)', 
				autorange: 'reversed',
				tickmode: 'auto', tickvals:  'auto',
				// dtick : depthRangeFrom && depthRangeTo ? (depthRangeFrom - depthRangeTo)/10 : null,
				// nticks : 10,
				// ticktext: ['3000 m', '2000 m', '1000 m', '0 m'],
				// range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 3000 , 0 ],
			},
			camera:{
				
				center: {
					'x': 0,
					'y': 0,
					'z': 0
				},
				eye: {
					'x': 1.4,
					'y': 1,
					'z': 0
				},
				up: {
					'x': 0,
					'y': 0,
					'z': 1
				}

			}
		},
		title: {
			text: wellId && wellId.name,
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	
	};

	return (
		<>
			{view&& view === 'List' ?
				<Content>
					<Row style={{
						margin: '10px',
						marginBottom: '0px',
						paddingTop: '10px',
						paddingBottom: '10px',
						background: '#FFFFFF',
						alignItems:'right'
					}} >
						<Col span={4}>
							<Row style={{alignItems:'center', textAlign: 'center'}}>
								<Col span={24} style={{textAlign: 'left', paddingLeft: '10px'}}>
									<Space>
										<Radio.Group style ={{margin:'5px'}} value={view} onChange={(e) => setSize(e.target.value)}>
											<Radio.Button value="List">{langdata && langdata.List ? langdata.List :'List'}</Radio.Button>
											<Radio.Button value="Map">{langdata && langdata.Map ? langdata.Map :'Map'}</Radio.Button>
										</Radio.Group>
									</Space>
								</Col>
							</Row>
						</Col>
						<Col span={20} style={{paddingRight: '10px'}}>
							<Row style={{alignItems:'center', textAlign: 'right'}}>
								<Col span={24}>
									<Space>
										<Select
											style={{ minWidth: '240px', textAlign: 'left', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											value={sort ? sort : '-1'}
											options={options}
											onSelect={handleSetSort}
										/>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Select
											style={{ minWidth: '240px', textAlign: 'left', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'}
											optionFilterProp="children"
											// defaultValue={'ALL'}
											value={admin_roles.includes(permission) ? filterRegion : allocationId}
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}
										/>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Button type='primary' style={{ margin:'5px'}} onClick={handleFilterDrawer}><FilterOutlined /></Button>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Popover title={langdata && langdata.RealtimeEvents ? langdata.RealtimeEvents :'Realtime Events'} content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
									<Space>&nbsp;&nbsp;</Space>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} dataSource={data.events}  pagination = { data.events.length > 0 ? data.pagination : 0} onChange={loadEvents}
								onRow={(record) => {
									return {
										onClick: () => { 
											key(record.id);
										}
									};
								}
								} 
							/>
						</Col>
					</Row>

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						justifyContent:'center',
						display:'flex'
					}} 
					>

					</Row>
					<Drawer
						title={langdata && langdata.Filteroptions ? langdata.Filteroptions :'Filter options'}
						// width={260}
						onClose={onCloseFilterDrawer}
						open={filterDrawer}>
						<Form layout="vertical" onFinish={handleFilterApply}>
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="activity" label={langdata && langdata.Activity ? langdata.Activity :'Activity'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity :'Select Activity'}
											optionFilterProp="children"
											onChange={handleActivity}
											options={eventData}
											defaultValue={filterEvent}
											mode="multiple"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="pids" label={langdata && langdata.PIDS ? langdata.PIDS :'PIDS'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS :'Select PIDS'}
											optionFilterProp="children"
											onSelect={handlePids}
											options={pidsList}
											defaultValue={filterPids}
											value={filterPids}
										/>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="lastHour" label={eventListLabel} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectTime ? langdata.SelectTime :'Select Time'}
											optionFilterProp="children"
											onSelect={handleLastHour}
											options={lastHours}
											defaultValue={filterLastHour}
											value={filterLastHour}
											disabled={timeSelection}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={24}>
									<Checkbox onChange={changeTimeSelection} checked={timeSelection}>{langdata && langdata.Advancedoptions ? langdata.Advancedoptions :'Advanced options'}</Checkbox>
								</Col>
							</Row>
							{timeSelection == true ?
								<>
									<Row gutter={24} style={{ marginTop : '20px'}}>
										<Col span={24}>
											<Form.Item name="date" label={langdata && langdata.Date ? langdata.Date :'Date'} >
												<DatePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleDate} format={settingsdateformat} defaultValue={dayjs(date)} disabledDate={disabledDate}/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime :'Start Time'} >
												<TimePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleStartTime} defaultValue={dayjs(startTime, 'HH:mm:ss')}/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime :'End Time'}  >
												<TimePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleEndTime} defaultValue={dayjs(endTime, 'HH:mm:ss')}/>
											</Form.Item>
										</Col>
									</Row>
								</> : null }
							<Row gutter={24}>
								<Col span={12} style={{ display:'flex', justifyContent: 'flex-end', marginTop : timeSelection == false ? '20px' : ''}}>
									<Button type='primary' htmlType="submit">{langdata && langdata.Apply ? langdata.Apply :'Apply'}</Button>
								</Col>
								<Col span={12} style={{ display:'flex', marginTop : timeSelection == false ? '20px' : ''}}>
									<Button onClick={onCloseFilterDrawer}>{langdata && langdata.Close ? langdata.Close :'Close'}</Button>
								</Col>
							</Row>
						</Form>
					</Drawer>

					{/* Event Filter Ends */}
					{/* Model Starts */}
					<Modal
						open={modelOpen}
						title={langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}
						onOk={handleModelOk}
						onCancel={handleModelCancel}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Button onClick={handleModelCancel}>
								{langdata && langdata.Close ? langdata.Close :'Close'}
							</Button>,
							// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
							//   More info
							// </Button>,
							// eslint-disable-next-line react/jsx-key
							<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_events_details, path: 'realtime-events' } });}}>
								{langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'}
							</Button>,
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}>
							<Col span={12}>          
								<Typography>{langdata && langdata.Id ? langdata.Id :'Id'}: {data && data.realtime_events_details && data.realtime_events_details.id}</Typography>
								<Typography>{langdata && langdata.Activity ? langdata.Activity :'Activity'}: <b>{data && data.realtime_events_details && data.realtime_events_details.eventType}</b></Typography>
								<Typography>{langdata && langdata.Date ? langdata.Date :'Date'}: <b>{data && data.realtime_events_details && data.realtime_events_details.eventDate}</b>
								</Typography>
								<Typography>{langdata && langdata.Region ? langdata.Region :'Region'}: {data && data.realtime_events_details && data.realtime_events_details.regioninfoId && regiondata && regiondata.map((region) => { if (data.realtime_events_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
								<Typography>{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}: {data && data.realtime_events_details && data.realtime_events_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (data.realtime_events_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								<Typography>{langdata && langdata.Channel ? langdata.Channel :'Channel'}: {data && data.realtime_events_details && data.realtime_events_details.eventChannel}</Typography>
								<Typography>{langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}: {data && data.realtime_events_details && data.realtime_events_details.chainage}</Typography>
								<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength :'Fiber Length'}: {data && data.realtime_events_details && data.realtime_events_details.odInMeter}{' m'}</Typography>
								<Typography>{langdata && langdata.Duration ? langdata.Duration :'Duration'}: {data && data.realtime_events_details && (data.realtime_events_details.duration / 60).toFixed(2)} Min</Typography>
								<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability :'Event Probability'}: <b>{(data && data.realtime_events_details && data.realtime_events_details.eventConfidence ? data.realtime_events_details.eventConfidence*100 : 0).toFixed(2)}%</b></Typography>
							</Col>
							<Col span={12}> 
								<img src={data && data.realtime_events_details && data.realtime_events_details.activityUrl} alt={`${data && data.realtime_events_details && data.realtime_events_details.eventType}`} align="right" />
							</Col>
							{/* <Col span={12}>  */}
							{/* {data && data.realtime_events_details && data.realtime_events_details.eventType === 'Manual Digging' ? 
									<img src={mapImg} alt="manual digging" align="right" /> :
									data && data.realtime_events_details && data.realtime_events_details.eventType === 'Machine Digging' ? 
										<img src={mapImgMachine} alt="Machine" align="right" /> :
										data && data.realtime_events_details && data.realtime_events_details.eventType === 'Tunneling' ? 
											<img src={mapImgTunneling} alt="Tunneling" align="right" /> :
											data && data.realtime_events_details && data.realtime_events_details.eventType === 'Vehicle Movement' ? 
												<img src={VehicleModal} alt="Tunneling" align="right" /> : 
												data && data.realtime_events_details && data.realtime_events_details.eventType === 'Drums Beating' ? 
													<img src={DrumModal} alt="Tunneling" align="right" /> : 
													data && data.realtime_events_details && data.realtime_events_details.eventType === 'Clamping' ? 
														<img src={ClampingModal} alt="Tunneling" align="right" /> : 
														data && data.realtime_events_details && data.realtime_events_details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
															<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
															data && data.realtime_events_details && data.realtime_events_details.eventType === 'Man Movement' ? 
																<img src={modalMan} alt="Man Movement" align="right" /> : 
																<img src={modalDefault} alt="Unclassified" align="right" /> } */}
							{/* </Col> */}
						</Row>
					</Modal>
					<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'}/>


				</Content>
				:
			//   Map View Starts

				<>	
					<Content>
						<Row style={{
							margin: '10px',
							marginBottom: '0px',
							paddingTop: '10px',
							paddingBottom: '10px',
							background: '#FFFFFF',
							alignItems:'right'
						}} >
							<Col span={4}>
								<Row style={{alignItems:'center', textAlign: 'center'}}>
									<Col span={24} style={{textAlign: 'left', paddingLeft: '10px'}}>
										<Space>
											<Radio.Group style={{margin:'5px'}} value={view} onChange={(e) => setSize(e.target.value)}>
												<Radio.Button value="List">{langdata && langdata.List ? langdata.List :'List'}</Radio.Button>
												<Radio.Button value="Map">{langdata && langdata.Map ? langdata.Map :'Map'}</Radio.Button>
											</Radio.Group>
										</Space>
									</Col>
								</Row>
							</Col>
							<Col span={20} style={{paddingRight: '10px'}}>
								<Row style={{alignItems:'center', textAlign: 'right'}}>
									<Col span={24}>
										<Space>
											<Select
												style={{ minWidth: '240px', textAlign: 'left',margin:'5px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'}
												optionFilterProp="children"
												// defaultValue={'ALL'}
												value={admin_roles.includes(permission) ? filterRegion : allocationId}
												options={regions}
												onSelect={(e, options)=>handleSetregion(e, options)}
											/>
										</Space>
										<Space>&nbsp;&nbsp;</Space>
										<Space>
											<Button style={{margin:'5px'}} type='primary' onClick={handleFilterDrawer}><FilterOutlined /></Button>
										</Space>
									</Col>
								</Row>
							</Col>
						</Row>

						{/* map filter starts  */}
						{map_type && map_type !== 'leaflet' ?

							< Map eventData = {data.mapdatas} onClick = {mapKey} regionId={filterRegion} startCh={startCh} endCh={endCh} filterDrawer = {filterDrawer} />

						// <Row span={24} style={{ color:'#000000a6', margin : '10px'}}>
						// 	<Col span={24}>
						// 		<Checkbox style={{ color:'#000000a6'}}>FIBER</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}} defaultChecked>PIPELINE</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>PIPELINE BORDER</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>KMP</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>CH</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>WB</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>Turning Point</Checkbox>
						// 		<Checkbox style={{ color:'#000000a6'}}>TLP</Checkbox>
						// 	</Col>
						// </Row>
						
							:
							<div className="App" style={{textAlign: 'left', margin : '10px'}}>
								<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>

									<LayersControl position="topright" >

										{oneviewType.toLowerCase() == 'well' ? 
											<>
												<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
													<MarkerClusterGroup>

														{wells && wells.map((marker,index)=>
															<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
																eventHandlers={{
																	click: () => {
																		wellKey(marker);
																	},
																}}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>

												<LayersControl.Overlay checked name={langdata && langdata.Events ? langdata.Events :'Events'}>

													<MarkerClusterGroup>

														{event_markers && event_markers.map((marker,index)=>
															<Marker eventHandlers={{
																click: () => {
																	mapKey(marker.id);
																},
															}} key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>
												</LayersControl.Overlay>
												<MarkerClusterGroup>
													{allPidsDatas && allPidsDatas.map((marker,index)=>
														<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

												

													)}       
												</MarkerClusterGroup>
											</>


											:
											<>
												<MarkerClusterGroup>
													{allPidsDatas && allPidsDatas.map((marker,index)=>
														<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

												

													)}       
												</MarkerClusterGroup>


												<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP :'KMP'}>
													<MarkerClusterGroup>

														{KMP && KMP.map((marker,index)=>
															<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>

  
												<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB :'WB'}>
													<MarkerClusterGroup>

														{WB && WB.map((marker,index)=>
															<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>




												<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP :'TP'}>
													<MarkerClusterGroup>

														{Turn && Turn.map((marker,index)=>
															<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>


												<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP :'TLP'}>
													<MarkerClusterGroup>

														{TLP && TLP.map((marker,index)=>
															<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>


												{/* <LayersControl.Overlay  name="POINTS">
										<MarkerClusterGroup>

											{points && points.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
													<Popup>
														{marker.pop}
													</Popup>
													<Tooltip>{marker.pop}</Tooltip>
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay> */}



												<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH :'CH'}>
													<MarkerClusterGroup>
														{markers && markers.map((marker,index)=>
															<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}

													</MarkerClusterGroup>
												</LayersControl.Overlay>

												<LayersControl.Overlay checked name={langdata && langdata.Events ? langdata.Events :'Events'}>

													<MarkerClusterGroup>

														{event_markers && event_markers.map((marker,index)=>
															<Marker eventHandlers={{
																click: () => {
																	mapKey(marker.id);
																},
															}} key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>
												</LayersControl.Overlay>



     
												{/* <LayersControl.Overlay  checked name="LINE">
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay> */}

    
												<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel :'Pipeline'}>
													<MarkerClusterGroup>
														<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
															{tempMapArray && Object.values(tempMapArray).map((item) => {
																let pipeline = [];
																item.map((data) => {
																	pipeline.push({lat : data.latitude,lng : data.longitude});
																});
																return (
																	<>
																		<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																	</>
																);
															})}
														</Pane>
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder :'Pipeline Border'}>
													<MarkerClusterGroup>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let pipelineBorder = [];
															item.map((data) => {
																pipelineBorder.push({lat : data.latitude,lng : data.longitude});
															});
															return (
																<>
																	<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber :'Fiber'}>
													<MarkerClusterGroup>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let odmeter = [];
															item.map((data) => {
																odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
															});
															return (
																<>
																	<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath :'Optional Path'}>
													<MarkerClusterGroup>
														{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
															let optionalpath = [];
															item.map((data) => {
																optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
															});
															return (
																<>
																	<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>
											</>
										}

									</LayersControl>
								</MapContainer>

							</div>
						}
						{/* map ends */}
						<Drawer
							title={langdata && langdata.Filteroptions ? langdata.Filteroptions :'Filter options'}
							// width={260}
							onClose={onCloseFilterDrawer}
							open={filterDrawer}>
							<Form layout="vertical">
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="activity" label={langdata && langdata.Activity ? langdata.Activity :'Activity'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity :'Select Activity'}
												optionFilterProp="children"
												onChange={handleActivity}
												options={eventData}
												defaultValue={filterEvent}
												mode="multiple"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="pids" label={langdata && langdata.PIDS ? langdata.PIDS :'PIDS'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS :'Select PIDS'}
												optionFilterProp="children"
												onSelect={handlePids}
												options={pidsList}
												defaultValue={filterPids}
												value={filterPids}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="lastHour" label={eventListLabel} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectTime ? langdata.SelectTime :'Select Time'}
												optionFilterProp="children"
												onSelect={handleLastHour}
												options={lastHours}
												defaultValue={filterLastHour}
												value={filterLastHour}
												disabled={timeSelection}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Checkbox onChange={changeTimeSelection} checked={timeSelection}>{langdata && langdata.Advancedoptions ? langdata.Advancedoptions :'Advanced options'}</Checkbox>
									</Col>
								</Row>
								{timeSelection == true ?
									<>
										<Row gutter={24} style={{ marginTop : '20px'}}>
											<Col span={24}>
												<Form.Item name="date" label={langdata && langdata.Date ? langdata.Date :'Date'} >
													<DatePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleDate} format={settingsdateformat} defaultValue={dayjs(date)} disabledDate={disabledDate}/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={24}>
											<Col span={24}>
												<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime :'Start Time'} >
													<TimePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleStartTime} defaultValue={dayjs(startTime, 'HH:mm:ss')}/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={24}>
											<Col span={24}>
												<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime :'End Time'}  >
													<TimePicker allowClear={false} style={{ minWidth: '100%'}} onChange={handleEndTime} defaultValue={dayjs(endTime, 'HH:mm:ss')}/>
												</Form.Item>
											</Col>
										</Row>
									</> : null }
								<Row gutter={24}>
									<Col span={12} style={{ display:'flex', justifyContent: 'flex-end', marginTop : timeSelection == false ? '20px' : ''}}>
										<Button type='primary' onClick={handleFilterApply}>{langdata && langdata.Apply ? langdata.Apply :'Apply'}</Button>
									</Col>
									<Col span={12} style={{ display:'flex', marginTop : timeSelection == false ? '20px' : ''}}>
										<Button onClick={onCloseFilterDrawer}>{langdata && langdata.Close ? langdata.Close :'Close'}</Button>
									</Col>
								</Row>
							</Form>
						</Drawer>

						{/* Model Starts */}
						<Modal
							open={mapModelOpen}
							title={langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}
							onOk={handleModelOk}
							onCancel={handleModelCancel}
							footer={[
							// eslint-disable-next-line react/jsx-key
								<Button onClick={handleModelCancel}>
									{langdata && langdata.Close ? langdata.Close :'Close'}
								</Button>,
								// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
								//   More info
								// </Button>,
								// eslint-disable-next-line react/jsx-key
								<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_map_events_details, path: 'realtime-events' } });}}>
									{langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'}
								</Button>,
							]}
						>
							<Divider/>
							<Row
								gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}>
								<Col span={12}>          
									<Typography>{langdata && langdata.Id ? langdata.Id :'Id'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.id}</Typography>
									<Typography>{langdata && langdata.Activity ? langdata.Activity :'Activity'}: <b>{data && data.realtime_map_events_details && data.realtime_map_events_details.eventType}</b></Typography>
									<Typography>{langdata && langdata.Date ? langdata.Date :'Date'}: <b>{data && data.realtime_map_events_details && data.realtime_map_events_details.eventDate}</b>
									</Typography>
									<Typography>{langdata && langdata.Region ? langdata.Region :'Region'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.regioninfoId && regiondata && regiondata.map((region) => { if (data.realtime_map_events_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
									<Typography>{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (data.realtime_map_events_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
									<Typography>{langdata && langdata.Channel ? langdata.Channel :'Channel'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.eventChannel}</Typography>
									<Typography>{langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.chainage}</Typography>
									<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength :'Fiber Length'}: {data && data.realtime_map_events_details && data.realtime_map_events_details.odInMeter}{' m'}</Typography>
									<Typography>{langdata && langdata.Duration ? langdata.Duration :'Duration'}: {data && data.realtime_map_events_details && (data.realtime_map_events_details.duration / 60).toFixed(2)}Min</Typography>
									<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability :'Event Probability'}: <b>{(data && data.realtime_map_events_details && data.realtime_map_events_details.eventConfidence ? data.realtime_map_events_details.eventConfidence*100 : 0).toFixed(2)}%</b></Typography>
								</Col>
								<Col span={12}> 
									<img src={data && data.realtime_map_events_details && data.realtime_map_events_details.activityUrl} alt={`${data && data.realtime_map_events_details && data.realtime_map_events_details.eventType}`} align="right" />
								</Col>
								{/* <Col span={12}> 
									{data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Manual Digging' ? 
										<img src={mapImg} alt="manual digging" align="right" /> : 
										data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Machine Digging' ? 
											<img src={mapImgMachine} alt="Machine" align="right" /> : 
											data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Tunneling' ? 
												<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
												data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Vehicle Movement' ? 
													<img src={VehicleModal} alt="Tunneling" align="right" /> : 
													data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Drums Beating' ? 
														<img src={DrumModal} alt="Tunneling" align="right" /> : 
														data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Clamping' ? 
															<img src={ClampingModal} alt="Tunneling" align="right" /> : 
															data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
																<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
																data && data.realtime_map_events_details && data.realtime_map_events_details.eventType === 'Man Movement' ? 
																	<img src={modalMan} alt="Man Movement" align="right" /> :
																	<img src={modalDefault} alt="Unclassified" align="right" /> }
								</Col> */}
							</Row>
						</Modal>

						{/* Event Filter Ends */}

						{/* well modal start */}

						<Modal
							open={wellModal}
							title={langdata && langdata.Well3d ? langdata.Well3d :'3d Well'}
							onOk={handleModelCancel}
							onCancel={handleModelCancel}
							footer={[
							// eslint-disable-next-line react/jsx-key
								// <Button type="primary" onClick={handleModelCancel}>
								// 	{langdata && langdata.Close ? langdata.Close :'Close'}
								// </Button>
							]}
							style={{backgroundColor:'transparent'}}
							width={800}
						>
							<Divider/>

							<Row
								gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}>
								<Col span={24} style={{ minHeight :'450px', display : 'flex', justifyContent : 'center' }}>          
									
									{/* <Card style={{width:'100%', height : '100%'}}> */}

									{/* {data3d && data3d && data3d.length > 0 ?
							
<> */}
									<Spin  spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
			
										{/* <button style={{marginTop:'10px', marginRight:'5px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'3dwell');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button> */}
										{(Object.keys(meshData).length !== 0) ?
											<Plot
												style={{ width: '750px', height: '600px',minHeight:'450px' }}
												config={{
													responsive: true,
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],

												}}
												data={wellData}
												layout={wellLayout}
											/>
											: 
											
											<Typography>{langdata && langdata.NoData ? langdata.NoData : 'No Data'}</Typography>
										}
									</Spin>
									{/* </>
:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px', backgroundColor:'#FFFFFF' }} />} */}


									{/* </Card> */}

								</Col>
							
							</Row>
						</Modal>

						{/* well modal end */}
						
  
						<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'}/>

					</Content>

				</>
			}
		</>
	);
} 