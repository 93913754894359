/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Card, Col, Empty, message, Radio, Row, Spin, Table, Tag } from 'antd';
import { useEffect, useReducer, useState } from 'react';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import Plot from 'react-plotly.js';
import axios from 'axios';
import reportWellReducer from './reducer';
import { DAS_GRAPH_MICROSEISMIC_AMP_DATA, DAS_GRAPH_MICROSEISMIC_AMP_ERROR, DAS_GRAPH_MICROSEISMIC_AMP_SUCCESS, DAS_LIST_MICROSEISMIC_AMP_DATA, DAS_LIST_MICROSEISMIC_AMP_SUCCESS, DAS_LIST_MICROSEISMIC_AMP_ERROR } from './constants';
import { FullscreenOutlined } from '@ant-design/icons';


export default function MicroseismicReports(props){
	const {onClick} = props;
	const viewType = props.viewType;
	const fullsize = props.fullsize;
	// const wellFrom = props.wellFrom;
	// const wellTo = props.wellTo;

	const wellId = props.wellId;
	const channelId = props.channelId;
	const startDate = props.startDate;
	const endDate = props.endDate;

	const [view, setView] = useState(props.view ? props.view : 'List');

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const initalState = {details: null, pending: false, error: false, dts_microseismic_temp_data:[], das_microseismic_amp_data:[], das_list_microseismic_amp_data : [],
		dasmicroseismiclistpending:false,dasmicroseismiccountpending: false
	};
	const [data, dispatch] = useReducer(reportWellReducer, initalState);	

	useEffect(() => {

		if(view=='List'){
			if(viewType=='DAS'){
				loadMicroSeismicType();                
			}
		}else{
			if(viewType=='DAS'){

				getWellAmplitudedataDAS();

			}
		}

	},[wellId,endDate, startDate, channelId, props.viewType, view]);	


	const loadMicroSeismicType = () => {
		dispatch({ type : DAS_LIST_MICROSEISMIC_AMP_DATA });
		let params = '';
		if(startDate && endDate){
			params += `&dateTimeFrom[$gte]=${startDate}&dateTimeTo[$lte]=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/wellfracturing?$sort[id]=1${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : DAS_LIST_MICROSEISMIC_AMP_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : DAS_LIST_MICROSEISMIC_AMP_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : DAS_LIST_MICROSEISMIC_AMP_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};
	

	const getWellAmplitudedataDAS =()=>{
		dispatch({ type : DAS_GRAPH_MICROSEISMIC_AMP_DATA});

		let params='';
		if(wellId){
			params += `wellinfoId=${wellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		params += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/microseismicCount?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					console.log('response.data', response.data);
					
					dispatch({ type : DAS_GRAPH_MICROSEISMIC_AMP_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DAS_GRAPH_MICROSEISMIC_AMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_GRAPH_MICROSEISMIC_AMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const dasGraphLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.MicroseismicEventsCount ? langdata.MicroseismicEventsCount : 'Microseismic Events Count',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Events ? langdata.Events : 'Events',
		},
	};	

	const dasGraph = [{
		// z: data && data.das_microseismic_amp_data && data.das_microseismic_amp_data && data.das_microseismic_amp_data.avg_depth_amps ? data.das_microseismic_amp_data.avg_depth_amps : [],
		x:  data && data.das_microseismic_amp_data && data.das_microseismic_amp_data && data.das_microseismic_amp_data.hour ? data.das_microseismic_amp_data.hour : [],
		y:  data && data.das_microseismic_amp_data && data.das_microseismic_amp_data && data.das_microseismic_amp_data.event_count ? data.das_microseismic_amp_data.event_count : [],
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		// colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'scatter',
		mode: 'lines+markers',
		line: { color: '#17BECF' }
		// reversescale:true
		// hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		// colorbar: {x: 1,   thickness: 10,
		// 	// thicknessmode: 'pixels',
		// 	// lenmode: 'fraction',
		// 	outlinewidth: 0},

		// orientation: graphScroll == true ? 'h' : ''


	},
	];	


	const columns = [
		{
			title: `${langdata && langdata.Name ? langdata.Name:'Name'}`,
			dataIndex: 'name',
			key: 'name',
			//   render: (_,text) => <a onClick={showEditDrawer}>{text}</a>

		},
		{
			title: `${langdata && langdata.StartDateandTime ? langdata.StartDateandTime:'Start Date and Time'}`,
			dataIndex: 'dateTimeFrom',
			key: 'dateTimeFrom',
		},
		{
			title: `${langdata && langdata.EndDateandTime ? langdata.EndDateandTime:'End Date and Time'}`,
			dataIndex: 'dateTimeTo',
			key: 'dateTimeTo',
		},
		{
			title: `${langdata && langdata.WellName ? langdata.WellName:'Well Name'}`,
			dataIndex: 'wellinfo',
			key: 'wellinfo',
		},
		{
			title: `${langdata && langdata.ChannelName ? langdata.ChannelName:'Channel Name'}`,
			dataIndex: 'channelName',
			key: 'channelName',
		},
		{
			title: `${langdata && langdata.WellSeismicType ? langdata.WellSeismicType:'Well Seismic Type'}`,
			dataIndex: 'wellseismictypes',
			key: 'wellseismictypes',
			render: (_, status) => (
				<>
					{status &&
                        <Tag color={'red'}>{status.wellseismictypes}</Tag>}
				</>
			),
		},
		{
			title: `${langdata && langdata.StartDepth ? langdata.StartDepth:'Start Depth'}`,
			dataIndex: 'depthFrom',
			key: 'depthFrom',
		},
		{
			title: `${langdata && langdata.EndDepth ? langdata.EndDepth:'End Depth'}`,
			dataIndex: 'depthTo',
			key: 'depthTo',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
		},
		// {
		// 	title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
		// 	key: 'action',
		// 	align:'center',
		// 	render: (_, record) => (
		// 		<Space style={{justifyContent:'center',display:'flex'}} size="middle">
		// 			<Button title={langdata && langdata.DeleteFractreMonitoring ? langdata.DeleteFractreMonitoring:'Delete Fractre Monitoring'} type='link' disabled = {record.status == 'New' ? false : true}  onClick={() => {handleDeleteMicroSeismic(record);}}><DeleteOutlined/></Button>
		// 		</Space>
		// 	)
		// }
    
	];

	const handlefullSize = (size,graph) =>{
		onClick(size, graph, dasGraph, dasGraphLayout, 4);

	};

	
	return(

		<>
			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}}
			style={{
				margin: '10px',
				marginBottom:'0px',
				marginTop:'10px',
				// paddingLeft: '10px',
				// paddingRight: '10px',
				padding:'10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col className="gutter-row" span={8}>
					<Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
						<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
						<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
					</Radio.Group>
				</Col>
				<Col span={16}>
					&nbsp;
				</Col>
			</Row>

			{viewType=='DAS' ?
				<>

					{view && view === 'List' ?
						<Row span={24} style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24} style={{ margin : '10px'}}>
							
								<Spin spinning={data.dasmicroseismiclistpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>

									{data && data.das_list_microseismic_amp_data && data.das_list_microseismic_amp_data.length > 0 ?
										<Table 
											scroll={{
												x: 900,
											}}
											columns={columns} dataSource={data.das_list_microseismic_amp_data}/>
										: 
										(
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)
									}
								</Spin>
							</Col>
						</Row>

						:
						<Card style={{ minHeight: '450px', margin: '10px', padding : '5px' }}>
							<Row style={{
								// margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
								alignItems:'center',
							}} >
								<Col span={24} style={{ minHeight : '450px'}}>
									<Spin spinning={data.dasmicroseismiccountpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
										<button
											style={{ marginTop : '5px', marginRight : '5px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'microSeismicCount');}} 
											className="graphZoomButton" ><FullscreenOutlined /></button>
										<Col>
											<Plot
												data={dasGraph}
												layout={ dasGraphLayout }
												style={{ width: '100%', height: '450px', minHeight : '450px' }}
												// onClick={(value) => {handleDTSClick(value, 'das');}}
												config={{ responsive: true, displaylogo : false }}
											/>
										</Col>
									</Spin>
								</Col>
							</Row>
						</Card>
					}
				</>
				:
				<>
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							
							{/* <Table 
									scroll={{
										x: 900,
									}}
								// columns={columns} dataSource={realtimeAlarmList}
								// pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
								/> */}
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row> 
						

				</>
			}
		</>
	);
}