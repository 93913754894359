/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/react-in-jsx-scope */

import { Avatar, Popover, Row, Col, Select, Card, Divider, DatePicker, Space, Spin, Typography, Tooltip, Collapse, theme, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InfoOutlined, DashboardOutlined, CaretRightOutlined } from '@ant-design/icons';
import '../../style.css';
import Vechilemovement from '../../images/vehiclemove.png';
import manualdig from '../../images/manualdig.jpg';
import machinedig from '../../images/machinedig.png';
import drumbeat from '../../images/drumbeat.png';
import tunnling from '../../images/tunnling.png';
import manmove from '../../images/manmove.png';
import manualdigdeep from '../../images/manualdigdeep.png';
import unidentified from '../../images/unidentified.png';

import { useAuth } from '../Context';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { DASHBOARD_LIST_ERROR, DASHBOARD_LIST_SUCCESS } from './constants';
import dashboarddatareducer from './reducer';
import { useNavigate } from 'react-router-dom';
import DataLoss from './dataLoss';
import HourChart from './hourChart';
import useResizeObserver from 'use-resize-observer';
import eventsImg from '../../images/Events.png';
import acknowledgementImg from '../../images/inspection.png';
import resolvedImg from '../../images/action.png';
import unresolvedImg from '../../images/positioncount.png';
import EdgeDown from './edgeDown';
import TlpContent from './tlp-content';
import redAlarmIcon from '../../images/red.png';
import yellowAlarmIcon from '../../images/amber.png';
import greenAlarmIcon from '../../images/green.png';
import clearAlarmIcon from '../../images/unclass.png';
import moment from 'moment';
import StsContent from './sts-content ';
import dayjs from 'dayjs';
import trainimg from '../../images/train-subway-solid.svg';
import agreegrateimg from '../../images/agreegrate_activity.png';
import mutipleimg from '../../images/multiple_activity.png';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


// const gridStyle = {
// 	width: '100%',
// 	// textAlign: 'center',
// };

// import '../../App.css';

const { Title } = Typography;
// // const { Text } = Typography;
// const dashboardtype = process.env.REACT_APP_IDENTIFIER;
// const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
// const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
// const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
// const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
// const currDate = moment().format(settingsdateformat);
// const currTime = moment().format(settingstimeformat);




// const customPanelStyle = {
// 	background: '#ffffff',
// 	// borderRadius: 4,
// 	marginBottom: 24,
// 	border: 0,
// 	// overflow: 'hidden',
// 	margin : '20px',
// 	// width : '100%'
// };

// const StyledCollapse = styled(AntCollapse.Panel)`
//   &&& {
//     border: none;
//     border-radius: 0px;
//     background-color: #f7f7f7;
//     box-shadow: none;
//   }
//   .ant-collapse-content {
//     background: palegreen;
//   }
// `;
  

export default function Dashboard() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData, getPids, pidsData} = useAuth();
	// const getregionId = localStorage.getItem(`${dashboardtype}_REGION_ID`);
	const initalState = {dashboarddata : [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(dashboarddatareducer, initalState);
	const [regionId, setRegionId] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [date, setDate] = useState(new Date().toLocaleDateString('en-UK', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}));
	const [datetime, setDateTime] = useState(new Date());
	const navigate = useNavigate();
	const { ref, height } = useResizeObserver();
	const envtlpEnable = process.env.REACT_APP_TLP == undefined || process.env.REACT_APP_TLP == 'undefined' ? 'disabled' : process.env.REACT_APP_TLP;
	const tlpEnable = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'null' ? envtlpEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envstsEnable = process.env.REACT_APP_STS == undefined || process.env.REACT_APP_STS == 'undefined' ? 'false' : process.env.REACT_APP_STS;
	const stsEnable = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_STS`) == 'null' ? envstsEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_STS`);	

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata ? langdata.DashboardContent1 : 'OneView Pro dashboard showcases numerical counts which provides a snapshot of the applications activity. It provides a quick insight into the PIDS health and the count of Alarms, Events, Alarm Acknowledgements, Resolutions and Unresolved Alarms in the system.'}</p>
			<br/>
			<p>{langdata && langdata ? langdata.DashboardContent2:'It further provides the details regarding Data Loss of the PIDS system in the current hour as well as the days average.'}</p>
			<br/>
			<p>{langdata && langdata ? langdata.DashboardContent3:'The graph provides a visual representation of the alarm counts separated by the alarm severity plotted against the hour.'}</p>
			<br/>
		</Card>
	);

	localStorage.setItem('AVAILABILITY_CHART_CURRENT_HOUR', 0);

	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const currDate = moment().format(settingsdateformat);
	const currTime = moment().format(settingstimeformat);
	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';

	const { token } = theme.useToken();
	const customPanelStyle = {
		// marginBottom: 12,
		// marginTop: 12,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: 'none',
	};

	const customPanelNone = {
		display : 'none'
	};

	let alarmCount = {
		total: 0,
		green: 0,
		amber: 0,
		red: 0,
		clear: 0,
	};

	let activityCount = {
		drumsbeating: 0,
		machinedigging: 0,
		manmovement: 0,
		manueldigging: 0,
		manueldiggingdeeper: 0,
		multipleactivity: 0,
		suspeciousactivity: 0,
		tunneling: 0,
		vehiclemovent: 0,
		aggregateactivityCount: 0,
		manualAugering : 0,
		machineAugering : 0,
		ploughingActivity : 0,
		waterPump : 0,
		trainmovement: 0,
		totalEvents: 0,
	};

	let acknowledgementcount = 0;
	let totalResolved = 0;
	let todayUnresolved = 0;
	let totalUnresolved = 0;
	// let channelCount = 0;
	data.dashboarddata.data && data.dashboarddata.data.map((item)=>{
		const alarmDetails =  item.alarmDetails;
		alarmCount = {
			total: alarmDetails.alarmCount !== undefined ? alarmCount.total + alarmDetails.alarmCount : alarmCount.total + 0,
			green: alarmDetails.greenCount !== undefined ? alarmCount.green + alarmDetails.greenCount : alarmCount.green + 0,
			amber: alarmDetails.amberCount !== undefined ? alarmCount.amber + alarmDetails.amberCount : alarmCount.amber + 0,
			red: alarmDetails.redCount !== undefined ? alarmCount.red + alarmDetails.redCount : alarmCount.red + 0,
			clear: alarmDetails.clearCount !== undefined ? alarmCount.clear + alarmDetails.clearCount : alarmCount.clear + 0,
		};

		activityCount = {
			drumsbeating: item.alarmDetails.drumsbeatingCount ? activityCount.drumsbeating + item.alarmDetails.drumsbeatingCount : activityCount.drumsbeating,
			machinedigging: item.alarmDetails.machinediggingCount ? activityCount.machinedigging + item.alarmDetails.machinediggingCount : activityCount.machinedigging,
			manmovement: item.alarmDetails.manmovementCount ? activityCount.manmovement + item.alarmDetails.manmovementCount : activityCount.manmovement,
			manueldigging: item.alarmDetails.manualdiggingCount ? activityCount.manueldigging + item.alarmDetails.manualdiggingCount : activityCount.manueldigging,
			manueldiggingdeeper: item.alarmDetails.manualdiggingdeeperCount ? activityCount.manueldiggingdeeper + item.alarmDetails.manualdiggingdeeperCount : activityCount.manueldiggingdeeper,
			multipleactivity: item.alarmDetails.multipleactivitiesCount ? activityCount.multipleactivity + item.alarmDetails.multipleactivitiesCount : activityCount.multipleactivity,
			suspeciousactivity: item.alarmDetails.suspiciousactivityCount ? activityCount.suspeciousactivity + item.alarmDetails.suspiciousactivityCount : activityCount.suspeciousactivity,
			tunneling: item.alarmDetails.tunnelingCount ? activityCount.tunneling + item.alarmDetails.tunnelingCount : activityCount.tunneling,
			vehiclemovent: item.alarmDetails.vehiclemovementCount ? activityCount.vehiclemovent + item.alarmDetails.vehiclemovementCount : activityCount.vehiclemovent,
			aggregateactivityCount: item.alarmDetails.aggregateactivityCount ? activityCount.aggregateactivityCount + item.alarmDetails.aggregateactivityCount : activityCount.aggregateactivityCount,
			machineAugering: item.alarmDetails.machineaugeringCount ? activityCount.machineAugering + item.alarmDetails.machineaugeringCount : activityCount.machineAugering,
			manualAugering: item.alarmDetails.manualaugeringCount ? activityCount.manualAugering + item.alarmDetails.manualaugeringCount : activityCount.manualAugering,
			ploughingActivity: item.alarmDetails.ploughingactivityCount ? activityCount.ploughingActivity + item.alarmDetails.ploughingactivityCount : activityCount.ploughingActivity,
			waterPump: item.alarmDetails.waterpumpCount ? activityCount.waterPump + item.alarmDetails.machineaugeringCount : activityCount.waterPump,
			trainmovement : item.alarmDetails.trainmovementCount ? activityCount.trainmovement + item.alarmDetails.trainmovementCount : activityCount.trainmovement,
			totalEvents: item.eventsDetails.totalEvents ? activityCount.totalEvents + Number(item.eventsDetails.totalEvents) : activityCount.totalEvents,
		};

		if (item.acknowledgemenDetails) {
			if (item.acknowledgemenDetails.acknowledgementCount) {
				acknowledgementcount += item.acknowledgemenDetails.acknowledgementCount;
			}
		}
		if (item.resolutionDetails) {
			if (item.resolutionDetails.totalResolved) {
				totalResolved += item.resolutionDetails.totalResolved;
			}
		}
		if (item.resolutionDetails) {
			if (item.resolutionDetails.todayUnresolved) {
				todayUnresolved += item.resolutionDetails.todayUnresolved;
			}
		}
		if (item.resolutionDetails) {
			if (item.resolutionDetails.totalUnresolved) {
				totalUnresolved += item.resolutionDetails.totalUnresolved;
			}
		}
		// if (item.channelcount) {
		// 	channelCount = item.channelcount;
		// }


	});
	let regions=[{value:null,
		label:`${langdata && langdata ? langdata.ALL :'ALL'}`
	}];
	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
			});
		});
	}


	let pids=[];

	let pidschannelcount=0;

	pidsData && pidsData.map((pid)=>{
		pidschannelcount +=  pid.channelId.length;
		pids.push({
			value:pid.id,
			label:pid.name.toUpperCase(),
			latitude:pid.latitude,
			longitude:pid.longitude
		});
	});
	localStorage.setItem(`${dashboardtype.toUpperCase()}_PIDS`,JSON.stringify(pids));
	localStorage.setItem(`${dashboardtype.toUpperCase()}_CHANNEL_COUNT`,pidschannelcount);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}

		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '1');

		if(dashboardtype==='oneview'){
			getPids(authtoken);
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadDashboard();
		localStorage.setItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_NOTIFICATION`, moment().utc().format('YYYY-MM-DD HH:mm:ss'));
	},[authtoken, date, regionId]);


	useEffect(() => {
		const interval = setInterval(() => {
			// loadDashboard();
			navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	const loadDashboard = () => {
		if(dashboardtype === 'sts' || dashboardtype === 'oneview' || (dashboardtype == 'tlp' && stsEnable == 'true')){
			let allocationParams = '';
			if(admin_roles.includes(permission)){
				// getRegion(authtoken);
				allocationParams = 'regioninfoId';
			}
			else{
				// getUserAllocation(authtoken);
				allocationParams = 'allocation';
			}
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/newdashboard?${allocationParams}=${regionId}&date=${date.split('/').reverse().join('-')}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
  
				}
			};
  
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({type:DASHBOARD_LIST_SUCCESS, payload:response.data.data});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({type:DASHBOARD_LIST_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						  });
					}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
				});
		}
	};

	const handleSetregion = (e) =>{
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REGION_ID`,e);
		setRegionId(e);
	};

	const handleSetDate = (e) =>{
		setDateTime(new Date(e && e.$d));
		setDate(new Date(e && e.$d).toLocaleDateString('en-UK', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}));
   
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleNaviagte =(e)=>{
		let newDate = new Date();
 
		if(e==3){
			navigate(( '/realtime-alarm'),{ state: { data: {severity:3, regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}
		else if(e==2){
			navigate(( '/realtime-alarm'),{ state: { data: {severity:2, regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e==1){
			navigate(( '/realtime-alarm'),{ state: { data: {severity:1, regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e==0){
			navigate(( '/realtime-alarm'),{ state: { data: {severity:0, regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='event'){
			navigate(( '/realtime-events'),{ state: { data : {regionId : regionId, date : datetime}}});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '120');
		}else if(e=='alarm'){
			navigate(( '/realtime-alarm'),{ state: { data : {regionId : regionId}}});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}
		else if(e=='Acknowledged'){
			navigate(( '/realtime-alarm'),{ state: { data: {status:'Acknowledged', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='Resolved'){
			navigate(( '/realtime-alarm'),{ state: { data: {status:'Resolved', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='resolutionAdded'){
			navigate(( '/realtime-alarm'),{ state: { data: {resolutionAdded:false, severity:3, regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='unresolved'){
			navigate(( '/realtime-alarm'),{ state: { data: {resolutionAdded:false, unresolved:'unresolved', severity:3,regionId : regionId, date : datetime,
				endeventtime:new Date(newDate.getFullYear(), 11, 31, 23,59,59),eventenddate:new Date(newDate.getFullYear(), 11, 31, 23,59,59),
				eventstartdate:new Date(newDate.getFullYear(), 0, 1),starteventtime:new Date(newDate.getFullYear(), 0, 1)
			}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='vehiclemovent'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Vehicle Movement', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='manmovement'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Man Movement', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='tunneling'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Tunneling', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='drumsbeating'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Drums Beating', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='suspeciousactivity'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Suspicious Activity', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='machinedigging'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Machine Digging', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='manueldigging'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Manual Digging', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='multipleactivity'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Multiple Activities', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='aggregateactivityCount'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Aggregate Activity', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='manueldiggingdeeper'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Manual Digging - Deeper (1.5 to 3 feets)', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}else if(e=='manualAugering'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Manual Augering', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}
		else if(e=='machineAugering'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Machine Augering', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}
		else if(e=='ploughingActivity'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Ploughing Activity', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');
		}
		else if(e=='waterPump'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Water Pump', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');		
		}
		else if(e=='trainmovement'){
			navigate(( '/realtime-alarm'),{ state: { data: {eventtype:'Train Movement', regionId : regionId, date : datetime}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '8');		
		}

		
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	// const items = [
	// 	{
	// 		  key: '1',
	// 		  label: 'TLP Details',
	// 		  children: <TlpContent regionId = {regionId} date={datetime}  />,
	// 	},
	// 	  ];

	let oneview = (<>
									
		<Row style={{
			margin: '10px',
			// padding: '10px',
			alignItems:'center'
		}} >
			<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', color: '#808080', paddingRight:'10px'}}>{langdata && langdata ? langdata.Lastupdatedon:'Last updated on'}: {currDate}{' '}{currTime}{' '}</Col>	
		</Row>
		{dashboardtype === 'sts' || dashboardtype === 'oneview' || (dashboardtype == 'tlp' && stsEnable == 'true') ?
			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}} style={{
			// marginTop:'20px',
				marginLeft:'0px',
				marginRight:'0px',
				marginBottom: '20px'
			}}>
				<Col span={10}>
					<Card bordered={false} style={{height: 'auto', minHeight:'220px'}}>
						<Card.Grid style={{height: 'auto', minHeight:'220px', width:'100%'}}>

							<span style={{ cursor: 'pointer'}} onClick={()=>{handleNaviagte('alarm');}} className="heading-alarmcount-label">{langdata && langdata ? langdata.TOTALALARMS:'TOTAL ALARMS'}<h4> &nbsp; 
								{alarmCount.total}</h4> </span>
							<Divider style={{marginTop:'10px'}} />
							<Row 
							// gutter={{
							// 	xs: 8,
							// 	sm: 16,
							// 	md: 24,
							// 	lg: 32,

								// }}
								span={24}
								style={{ display : 'flex', justifyContent : 'space-evenly', overflowX: 'auto',}}
							>
								<Space>
									<Col onClick={()=>{handleNaviagte(3);}} className="gutter-row" span={5} style={{ maxWidth : '600px', cursor: 'pointer'}}>
										<Col style={{ display: 'flex', justifyContent: 'center', marginBottom:'4px' }}>
											<img shape="square" src={redAlarmIcon} alt='alarm image' style={{ minwidth : '30px', maxWidth:'30px', minHeight:'30px', maxHeight:'30px' }}/>
										</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center' }}>
											{langdata && langdata ? langdata.RED:'RED'}
										</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginTop:'0px' }}>
											<Typography style={{ fontSize : '25px'}}><b> {alarmCount.red}</b> </Typography>
										</Col>		
									</Col>
								</Space>
								<Space>&nbsp;</Space>
								<Space>&nbsp;</Space>
								<Space>
									<Col onClick={()=>{handleNaviagte(2);}} className="gutter-row" span={5} style={{ maxWidth : '600px', cursor: 'pointer'}}>

										<Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom:'4px'}}>
											{/* <Avatar shape="square" size={35} icon="user" src={yellowAlarmIcon} /> */}
											<img shape="square" src={yellowAlarmIcon} alt='alarm image' style={{ minwidth : '30px', maxWidth:'30px', minHeight:'30px', maxHeight:'30px' }}/>

										</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center' }}>{langdata && langdata ? langdata.AMBER:'AMBER'}</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginTop:'0px' }}>
											<Typography style={{ fontSize : '25px'}}><b> {alarmCount.amber}</b> </Typography>
										</Col>
									</Col>
								</Space>
								<Space>&nbsp;</Space>
								<Space>&nbsp;</Space>
								<Space>
									<Col onClick={()=>{handleNaviagte(1);}} className="gutter-row" span={5} style={{ maxWidth : '600px', cursor: 'pointer'}}>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom:'4px' }}>
											{/* <BellOutlined style={{color:'green', fontSize:'25px'}} /> */}
											{/* <Avatar shape="square" size={35} icon="user" src={greenAlarmIcon} /> */}
											<img shape="square" src={greenAlarmIcon} alt='alarm image' style={{ minwidth : '30px', maxWidth:'30px', minHeight:'30px', maxHeight:'30px' }}/>

										</Col>

										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center' }}>{langdata && langdata ? langdata.GREEN :'GREEN'}</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginTop:'0px' }}>
											<Typography style={{ fontSize : '25px'}}><b> {alarmCount.green}</b> </Typography>
										</Col>
									</Col>
								</Space>
								<Space>&nbsp;</Space>
								<Space>&nbsp;</Space>
								<Space>
									<Col onClick={()=>{handleNaviagte(0);}} className="gutter-row" style={{ maxWidth : '600px', cursor: 'pointer'}}>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom:'4px' }}>
											{/* <BellOutlined style={{color:'violet', fontSize:'25px'}} /> */}
											{/* <Avatar shape="square" size={35} icon="user" src={clearAlarmIcon} /> */}
											<img shape="square" src={clearAlarmIcon} alt='alarm image' style={{ minwidth : '30px', maxWidth:'30px', minHeight:'30px', maxHeight:'30px' }}/>


										</Col>
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center' }}>{langdata && langdata ? langdata.CLEAR :'CLEAR'}</Col>
		
										<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', marginTop:'0px' }}>
											<Typography style={{ fontSize : '25px'}}><b> {alarmCount.clear}</b> </Typography>
										</Col>
									</Col>
								</Space>
							</Row>
						</Card.Grid>

					</Card>
				</Col>


				<Col span={14} >
					<Card bordered={false} style={{ height: 'auto', minHeight:'220px'}}>
						<Card.Grid style={{height: 'auto', minHeight:'220px', width:'100%'}}>
							{alarmCount && alarmCount.total >=1
								?

								<Row style={{ overflowX: 'auto', height:'150px', marginTop:'14px'}} span={24}>

									{activityCount.vehiclemovent && activityCount.vehiclemovent > 0 ?
									// <Col className="gutter-row" span={4}>
									// 	<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={Vechilemovement} alt="img" />
									// 	<h6>Vehicle Movement</h6>
									// </Col>
										<Space>
											<Col onClick={()=>{handleNaviagte('vehiclemovent');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={Vechilemovement} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px' }}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.VehicleMovement : 'Vehicle Movement'} </Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.vehiclemovent}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.manmovement && activityCount.manmovement > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={manmove} alt="img" />
				<h6>Man Movement</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('manmovement');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={manmove} alt='event image' style={{minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.ManMovement : 'Man Movement'} </Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.manmovement}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.tunneling && activityCount.tunneling > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={tunnling} alt="img" />
				<h6>Tunneling</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('tunneling');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={tunnling} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.Tunneling : 'Tunneling'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.tunneling}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.drumsbeating && activityCount.drumsbeating > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={drumbeat} alt="img" />
				<h6>Drums Beating</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('drumsbeating');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={drumbeat} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px' }}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.DrumsBeating : 'Drums Beating'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.drumsbeating}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.suspeciousactivity && activityCount.suspeciousactivity > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('suspeciousactivity');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={unidentified} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.SuspiciousActivity : 'Suspicious Activity'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.suspeciousactivity}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.machinedigging && activityCount.machinedigging > 0 ?
										<Space>
											<Col onClick={()=>{handleNaviagte('machinedigging');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={machinedig} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.MachineDigging : 'Machine Digging'} </Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.machinedigging}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.manueldigging && activityCount.manueldigging > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={manualdig} alt="img" />
				<h6>Manual Digging</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('manueldigging');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={manualdig} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.ManualDigging : 'Manual Digging'} </Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.manueldigging}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.multipleactivity && activityCount.multipleactivity > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Multiple Activities</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('multipleactivity');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={mutipleimg} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.MultipleActivities : 'Multiple Activities'} </Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.multipleactivity}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.aggregateactivityCount && activityCount.aggregateactivityCount > 0 ?
										<Space>
											<Col onClick={()=>{handleNaviagte('aggregateactivityCount');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={agreegrateimg} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'40px', maxHeight:'40px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.AggregateActivity : 'Aggregate Activity'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.aggregateactivityCount}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.manueldiggingdeeper && activityCount.manueldiggingdeeper > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={manualdigdeep} alt="img" />
				<h6>Manual Digging - Deeper (1.5 to 3 feets)</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('manueldiggingdeeper');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={manualdigdeep} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.ManualDiggingDeeper : 'Manual Digging - Deeper (1.5 to 3 feets)'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.manueldiggingdeeper}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.manualAugering && activityCount.manualAugering > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('manualAugering');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={unidentified} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.ManualAugering : 'Manual Augering'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.manualAugering}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.machineAugering && activityCount.machineAugering > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('machineAugering');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={unidentified} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.MachineAugering : 'Machine Augering'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.machineAugering}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.ploughingActivity && activityCount.ploughingActivity > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('ploughingActivity');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={unidentified} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.PloughingActivity : 'Ploughing Activity'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.ploughingActivity}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.waterPump && activityCount.waterPump > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('waterPump');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={unidentified} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.WaterPump : 'Water Pump'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.waterPump}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

									{activityCount.trainmovement && activityCount.trainmovement > 0 ?
										<Space>
											{/* <Col className="gutter-row" span={4}>
				<img style={{width:'auto', height:'60px', marginTop:'35px'}} src={unidentified} alt="img" />
				<h6>Suspicious Activity</h6>
			</Col> */}
											<Col onClick={()=>{handleNaviagte('trainmovement');}} className="gutter-row" span={4} style={{ maxWidth : '600px', margin : '1rem', cursor: 'pointer'}}>
												<Col style={{ display : 'flex', justifyContent : 'center' }}>
													<img shape="square" src={trainimg} alt='event image' style={{ minwidth : '50px', maxWidth:'50px', minHeight:'50px', maxHeight:'50px'}}/>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
													<Typography>{langdata && langdata ? langdata.TrainMovement : 'Train Movement'}</Typography>
												</Col>
												<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
													<Typography style={{ fontSize : '25px'}}><b> {activityCount.trainmovement}</b> </Typography>
												</Col>
											</Col>
										</Space>
										:null}

								</Row>:
								null}

						</Card.Grid>
					</Card>
				</Col>
			</Row>:null	}
		{dashboardtype === 'sts' || dashboardtype === 'oneview' || (dashboardtype == 'tlp' && stsEnable == 'true') ? 
			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}} style={{
				marginTop:'20px',
				marginLeft:'0px',
				marginRight:'0px',
				marginBottom: '20px',
			}}>
				{dashboardtype === 'oneview' ?
					<Space>
						<Col onClick={()=>{handleNaviagte('event');}} style={{marginBottom:'20px'}} span={6}> 
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{height: 'auto', minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={eventsImg} alt='event image' style={{ width : '30px'}}/>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.EVENTS : 'EVENTS'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {activityCount.totalEvents}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					: null }

				<Space>
					<Col onClick={()=>{handleNaviagte('Acknowledged');}} style={{marginBottom:'20px'}} span={6}>
						<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
							<Card.Grid style={{height: 'auto', minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
								{/* <Statistic
title="ACKNOWLEDGEMENTS"
value={acknowledgementcount}
precision={0}
// valueStyle={{ color: 'crimson' }}
prefix={<OneToOneOutlined />}
suffix=""
/> */}														
								<Col style={{ display : 'flex', justifyContent : 'center' }}>
									<img shape="square" src={acknowledgementImg} alt='acknowledgement count' style={{ width : '30px'}}/>

								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
									<Typography>{langdata && langdata ? langdata.ACKNOWLEDGEMENTS : 'ACKNOWLEDGEMENTS'} </Typography>
								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
									<Typography style={{ fontSize : '25px'}}><b> {acknowledgementcount}</b> </Typography>
								</Col>
							</Card.Grid>
						</Card>
					</Col>
				</Space>

				<Space>
					<Col onClick={()=>{handleNaviagte('Resolved');}} style={{marginBottom:'20px'}} span={6}> 
						<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
							<Card.Grid style={{height: 'auto', minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
								{/* <Statistic
title="RESOLUTIONS"
value={totalResolved}
precision={0}
// valueStyle={{ color: 'blue' }}
prefix={<BellOutlined />}
suffix=""
/> */}
								<Col style={{ display : 'flex', justifyContent : 'center' }}>
									<img shape="square" src={resolvedImg} alt='resolved count' style={{ width : '30px'}}/>

								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
									<Typography>{langdata && langdata ? langdata.RESOLUTIONS : 'RESOLUTIONS'} </Typography>
								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
									<Typography style={{ fontSize : '25px'}}><b> {totalResolved}</b> </Typography>
								</Col>
							</Card.Grid>
						</Card>
					</Col>
				</Space>

				<Space>
					<Col onClick={()=>{handleNaviagte('resolutionAdded');}} style={{marginBottom:'20px'}} span={6}> 
						<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
							<Card.Grid style={{height: 'auto', minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
								{/* <Statistic
title="DAY UNRESOLVED ALARMS"
value={todayUnresolved}
precision={0}
// valueStyle={{ color: 'gold' }}
prefix={<BellOutlined />}
suffix=""
/> */}
								<Col style={{ display : 'flex', justifyContent : 'center' }}>
									<img shape="square" src={unresolvedImg} alt='unresolved count' style={{ width : '30px'}}/>

								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
									<Typography>{langdata && langdata ? langdata.DAYUNRESOLVEDALARMS : 'DAY UNRESOLVED ALARMS'} </Typography>
								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
									<Typography style={{ fontSize : '25px'}}><b> {todayUnresolved}</b> </Typography>
								</Col>
							</Card.Grid>
						</Card>
					</Col>
				</Space>

				<Space>
					<Col onClick={()=>{handleNaviagte('unresolved');}} style={{marginBottom:'20px'}} span={6}>
						<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
							<Card.Grid style={{height: 'auto', minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
								{/* <Statistic
title="TOTAL UNRESOLVED ALARMS"
value={totalUnresolved}
precision={0}
// valueStyle={{ color: 'gold' }}
// prefix={<BellOutlined />}
suffix=""
/> */}
								<Col style={{ display : 'flex', justifyContent : 'center' }}>
									<img shape="square" src={unresolvedImg} alt='unresolved count' style={{ width : '30px'}}/>

								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
									<Typography>{langdata && langdata ? langdata.TOTALUNRESOLVEDALARMS : 'TOTAL UNRESOLVED ALARMS'} </Typography>
								</Col>
								<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
									<Typography style={{ fontSize : '25px'}}><b> {totalUnresolved}</b> </Typography>
								</Col>
							</Card.Grid>
						</Card>
					</Col>
				</Space>
			</Row>:null}
		{dashboardtype!=='oneview' ? '' : 
			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}} style={{
				marginTop:'-20px',
				marginLeft:'0px',
				marginRight:'0px',
				marginBottom: '-20px'
			}}>
				<Col span={6}>
					<Card title={langdata && langdata ? langdata.DATALOSS : 'DATA LOSS %'} style={{ height : height, minWidth:'220px', minHeight:'150px', marginBottom: '20px', textAlign : 'center'}} hoverable>
						<DataLoss date = {date} regionId={regionId}/>
					</Card>
				</Col>
				<Col span={18}> 
					<Card ref={ref} style={{minWidth:'220px', minHeight:'150px', marginBottom: '20px'}} hoverable>
						<HourChart data = {data.dashboarddata.data}/>
					</Card>
				</Col>
			</Row>
		}
	</>);
  
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={5}>
						<Row>
							<Space>
								<DashboardOutlined style={{fontSize:'30px'}}/>
								{/* &nbsp; */}
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata ? langdata.Dashboard : 'Dashboard'}</Title>
							</Space>
						</Row>
					</Col>
					<Col span={10}>
						{dashboardtype !== 'oneview' ? null :
                		< EdgeDown date = {date} channelCount = {pidschannelcount}/>
						 }
					</Col>
					<Col span={9}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Tooltip placement="bottom" title={langdata && langdata ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ width: '170px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder="Select Region"
											optionFilterProp="children"
											defaultValue={'ALL'}
											value={regionId}
											options={regions}
											onSelect={handleSetregion}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>

								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Date:</Text> */}
								<Tooltip placement="bottom" title={langdata && langdata ? langdata.Filterbydate : 'Filter by date'}>
									<Space>
										<DatePicker
											allowClear={false} 
											placeholder="Today" onChange={handleSetDate} style={{ margin:'5px'}} format={settingsdateformat} defaultValue={dayjs()} disabledDate={disabledDate}/>
										
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								{dashboardtype !== 'oneview' ? null :
									<Popover title={langdata && langdata ? langdata.Dashboard :'Dashboard'} content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								}
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				{/*  */}
				{ dashboardtype==='oneview' ?
				// <Space direction="vertical">
					// <div className="ant-collapse-content" >
					<Collapse
						// accordion
						// className="ant-collapse-content-box" 
						// collapsible="header"
						// defaultActiveKey={dashboardtype==='tlp' ? ['1'] : ['0']}
						// style={customPanelStyle}
						// size="large"
						bordered={false}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						style={{
							background: token.colorBgContainer,
						  }}
						defaultActiveKey={['1','2','3']}
						items={[
							{
								key: '1',
								label: <b>{langdata && langdata ? langdata.OneView: 'OneView'}</b>,
								children: oneview,
								style: customPanelStyle
							  },
							  oneviewType != 'well' && stsEnable != 'false'  ?
							  {
									key: '2',
									label: <b>{langdata && langdata ? langdata.SecurityTrackingSystem: 'Security Tracking System'}</b>,
									children: <StsContent regionId = {regionId} date={datetime}  />,
									style: customPanelStyle
							  }
							  : { style : {display: 'none'}},
							tlpEnable === 'enabled' && oneviewType != 'well' ?
							  {
									key: '3',
									label: <b>{langdata && langdata ? langdata.SmartCP: 'Smart CP'}</b>,
									children: <TlpContent regionId = {regionId} date={datetime}  />,
									style: customPanelStyle
							  } : {
									key: '3',
									label: <b>{langdata && langdata ? langdata.SmartCP: 'Smart CP'}</b>,
									children: <TlpContent regionId = {regionId} date={datetime}  />,
									style: customPanelNone
							  }
						]}
				
					/>
					// </div>
					:
					null
				}
				{dashboardtype==='tlp' ? 
					// <TlpContent regionId = {regionId} date={datetime}  />
					<Collapse
					// accordion
					// className="ant-collapse-content-box" 
					// collapsible="header"
					// defaultActiveKey={dashboardtype==='tlp' ? ['1'] : ['0']}
					// style={customPanelStyle}
					// size="large"
						bordered={false}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						style={{
							background: token.colorBgContainer,
					  }}
						defaultActiveKey={['1','2','3']}
						items={[
							stsEnable === 'true' && oneviewType != 'well' ?

							// (

								{
									key: '1',
									label: <b>{langdata && langdata ? langdata.OneView: 'OneView'}</b>,
									children: oneview,
									style: customPanelStyle
						  }
						  : { style : {display: 'none'}},
							{
								key: '2',
								label: <b>{langdata && langdata ? langdata.SmartCP: 'Smart CP'}</b>,
								children: <TlpContent regionId = {regionId} date={datetime}  />,
								style: customPanelStyle
							},
							stsEnable === 'true' && oneviewType != 'well' ?

							  {
									key: '3',
									label: <b>{langdata && langdata ? langdata.SecurityTrackingSystem: 'Security Tracking System'}</b>,
									children: <StsContent regionId = {regionId} date={datetime}  />,
									style: customPanelStyle
						  
								}
						  : { style : {display: 'none'}},

							// )
						  
						]}
					/>
					: null
				}
				{dashboardtype==='sts' ? 
					<Collapse
					// accordion
					// className="ant-collapse-content-box" 
					// collapsible="header"
					// defaultActiveKey={dashboardtype==='tlp' ? ['1'] : ['0']}
					// style={customPanelStyle}
					// size="large"
						bordered={false}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						style={{
							background: token.colorBgContainer,
					  }}
						defaultActiveKey={['1','2','3']}
						items={[
							{
								key: '1',
								label: <b>{langdata && langdata ? langdata.OneView: 'OneView'}</b>,
								children: oneview,
								style: customPanelStyle
						  },
						  {
								key: '2',
								label: <b>{langdata && langdata ? langdata.SecurityTrackingSystem: 'Security Tracking System'}</b>,
								children: <StsContent regionId = {regionId} date={datetime}  />,
								style: customPanelStyle
						  },
						  tlpEnable === 'enabled' && oneviewType != 'well' ?
							  {
									key: '3',
									label: <b>{langdata && langdata ? langdata.SmartCP: 'Smart CP'}</b>,
									children: <TlpContent regionId = {regionId} date={datetime}  />,
									style: customPanelStyle
							  } : {
									key: '3',
									label: <b>{langdata && langdata ? langdata.SmartCP: 'Smart CP'}</b>,
									children: <TlpContent regionId = {regionId} date={datetime}  />,
									style: customPanelNone
							  }
						]}
					/>
					: null
				}
			</Content>
			<Spin fullscreen spinning={data.pending} />
		</>
	);
}