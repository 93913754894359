/* eslint-disable react/react-in-jsx-scope */
import { createContext, useContext, useState } from 'react';
import axios from 'axios';

export const LoginContext = createContext();


const LoginContextProvider = (props) => {

	const [regiondata, setRegionData] =useState();
	const [mapdata, setMapData] =useState();
	const [eventsData, setEventsData] = useState();
	const [pidsData, setPidsData] = useState();
	const [channelData, setChannelData] = useState();
	const [userTypeData, setUserTypeData] = useState();
	const [optionalpipedata, setMapOptionalPipelineData] = useState();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const [getUserAllocationData, setUserAllocationData] = useState();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [pipelineConfig, setPipielineConfig] = useState();
	const [pidsRegionData, setPidsRegionData] = useState();
	const [wellinfo, setWellInfo] = useState();
	const [wellzoneinfo, setWellZoneInfo] = useState();
	const [wellTypeinfo, setWellTypeInfo] = useState();
	const [wellSeismicType, setWellSeismicType] = useState();
	const [wellchanneldata, setWellChannelData] = useState();
	const [wellFractureData, setWellFractureData] = useState();
	const [wellalgorithmdata, setWellAlgorithamData] = useState();

	
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	
	const handleGetMapdata = (token,regioninfoId,start,end) =>{
		const regionparams = `&regioninfoId=${regioninfoId}`;
		const pipelineChainageparams=`&pipelineChainage[$gte]=${start}&pipelineChainage[$lte]=${end}`;
		const pipelineParams = `&pipelineId=${pipelineId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipelineconfig?$limit=10000&$sort[pipelineChainage]=1${ regioninfoId && regioninfoId ? regionparams:''}${ end && end || end==0 ? pipelineChainageparams:''}${pipelineParams}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setMapData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getRegion =(token) =>{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/regioninfo?$limit=1000`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: {
      
			}
		};
      
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setRegionData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getAllEvents =(token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/allevents?$sort[eventType]=1`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setEventsData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getPids =(token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pidsinfo-list?$limit=1000&pipelineId=${pipelineId}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setPidsData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getPidsRegion =(token,regionId) => {
		
		// let params = regionId ? `&regioninfoId=${regionId}` : '';
		let allocationParams = '';
		if(admin_roles.includes(permission)){
			// getRegion(authtoken);
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			// getUserAllocation(authtoken);
			allocationParams = regionId ? `&allocationId=${regionId}` : '';
		}
		let url = '';
		if(admin_roles.includes(permission)){
			url = `/pidsinfo-regionfilter?$limit=1000&pipelineId=${pipelineId}${allocationParams}`;
		} else {
			url = `/pidsinfo-list?$limit=1000&pipelineId=${pipelineId}${allocationParams}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}${url}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					if(admin_roles.includes(permission)){
						setPidsRegionData(response.data);
					} else {
						setPidsRegionData(response.data.data);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getChannels =(token, pidsId) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?pidsinfoId=${pidsId}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setChannelData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getUserTypes = (token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/usertype?id[$ne]=1`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
      
		axios.request(config)
			.then((response) => {
				setUserTypeData(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
      
	};

	const handleOptionalPipelinedata = (token,pipelineId,regioninfoId,start,end) =>{

		const pipelineChainageparams=`&pipelineChainage[$gte]=${start}&pipelineChainage[$lte]=${end}`;
		const regionparams = `&regioninfoId=${regioninfoId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/patchpipelineconfig?$limit=100000&$sort[pipelineChainage]=1${ regioninfoId && regioninfoId ? regionparams:''}&pipelineId=${pipelineId}${ end && end || end == 0 ? pipelineChainageparams:''}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: {
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setMapOptionalPipelineData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	const getUserAllocation = (token) => {

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/userallocation?userId=${localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`)}`,
			headers: { 
				Authorization:`Bearer ${token}`  
			}
		};

		axios.request(config)
			.then((response) => {
				setUserAllocationData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});

	};


	const getPipelineConfig = (token, pidsinfoId, channelId) =>{
		const pidsinfoIdparams=`&pidsinfoId=${pidsinfoId}`;
		const channelIdparams = `&channelId=${channelId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipelineconfig?$limit=10000&$sort[pipelineChainage]=1${ pidsinfoId && pidsinfoId ? pidsinfoIdparams : ''}${ channelId && channelId  ? channelIdparams : ''}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setPipielineConfig(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const getWellInfo = (token) =>{
		// const pidsinfoIdparams=`&pidsinfoId=${pidsinfoId}`;
		// const channelIdparams = `&channelId=${channelId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellinfo?$sort[id]=1`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setWellInfo(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const getWellZoneInfo = (token,wellId) =>{
		// const pidsinfoIdparams=`&pidsinfoId=${pidsinfoId}`;
		// const channelIdparams = `&channelId=${channelId}`;

		if(wellId){
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/wellzoneinfo?$sort[id]=1&wellinfoId=${wellId}`,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${token}`
				},
				data: { 
	
				}
			};
	
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						setWellZoneInfo(response.data.data);
					}
				})
				.catch((error) => {
					console.log(error);
					// setDropOpen(false);
					// setOpen(true);
					// setErrorMessasge(error.response.data.message);
				});
		}

		

	};

	const getWellTypeInfo = (token) =>{
		// const pidsinfoIdparams=`&pidsinfoId=${pidsinfoId}`;
		// const channelIdparams = `&channelId=${channelId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/welltypes?$sort[id]=1`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setWellTypeInfo(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getWellSeismicType = (token) => {

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/wellseismictypes`,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${token}`			
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					setWellSeismicType(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});

	};


	const getWellChannels =(token, pidsId, wellinfoId) => {
		let params='';

		if(wellinfoId){
			params += `wellinfoId=${wellinfoId}`;
		}
		if(pidsId){
			params += `&pidsinfoId=${pidsId}`;
		}		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?${params}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setWellChannelData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getWellFracture = (token, fractureid) => {

		let params = '';
		if(fractureid){
			params += `&seismicTypeId=${fractureid}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellfracturing?$sort[id]=1${params}`,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${token}`
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					setWellFractureData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});

	};


	const getWellAlgorithm = (token) => {

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellalgorithm?$sort[id]=1`,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${token}`
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					setWellAlgorithamData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});

	};


	return (
		<LoginContext.Provider value={{getRegion, regiondata, getAllEvents, eventsData, getPids, pidsData, getChannels, channelData,
			// eslint-disable-next-line react/prop-types
			handleGetMapdata, mapdata, getUserTypes, userTypeData, optionalpipedata,handleOptionalPipelinedata, getUserAllocation ,getUserAllocationData, getPipelineConfig, pipelineConfig, pidsRegionData, getPidsRegion, wellinfo, getWellInfo,wellzoneinfo,getWellZoneInfo, wellTypeinfo, getWellTypeInfo, wellSeismicType, getWellSeismicType, wellchanneldata, getWellChannels, wellFractureData, getWellFracture, wellalgorithmdata,getWellAlgorithm }}>{props.children}</LoginContext.Provider>
	);
};

export const useAuth = () => useContext(LoginContext);

export default LoginContextProvider;