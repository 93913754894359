import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Select, Button, Input, Card, Form, Space, message, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UserOutlined, LockOutlined, ToolOutlined, GlobalOutlined } from '@ant-design/icons';
// import login from '../../images/LoginWallpaper1.png';
import logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { AUTHENTICATION_LIST, AUTHENTICATION_LIST_ERROR, AUTHENTICATION_LIST_SUCCESS, COMPANY_LIST, COMPANY_LIST_ERROR, COMPANY_LIST_SUCCESS, COMPANY_MAP, COMPANY_MAP_ERROR, COMPANY_MAP_SUCCESS, SETTINGS_LIST, SETTINGS_LIST_ERROR, SETTINGS_LIST_SUCCESS } from './constants';
import logindatareducer from './reducer';
import axios from 'axios';
import '../../App.css';
import wellimg from '../../images/oilwell.jpg';
import borderimg from '../../images/bordersecurity.jpeg';

export default function Login() {

	// const success = (msg) => {
	// 	messageApi.open({
	// 		type: 'success',
	// 		content: msg,
	// 	});
	// };
	const errorvalidation = (msg) => {
		messageApi.open({
			type: 'error',
			content: msg,
		});
	};

	// const warning = (msg) => {
	// 	messageApi.open({
	// 		type: 'warning',
	// 		content: msg,
	// 	});
	// };


	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const [passwordVisible, setPasswordVisible] = React.useState(false);
	const navigate = useNavigate();
	const [apiUrlBox, setApiUrlBox] = useState(false);
	const initalState = { logindata: [],companydata:[], company_mapdata:[], settings:[], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(logindatareducer, initalState);
	const [companyId, setCompanyId] = useState(null);
	const [pipelineId, setPipelineId] = useState(null);
	const [messageApi, contextHolder] = message.useMessage();
	// const envtlpEnable = process.env.REACT_APP_TLP == undefined || process.env.REACT_APP_TLP == 'undefined' ? 'disabled' : process.env.REACT_APP_TLP;
	// const tlpEnable = !localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) || localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == null || localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == undefined || localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'undefined' || localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'null' ? envtlpEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`);
	const [form] = Form.useForm();

	let companies=[];
	let pipelines=[];

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const [bgImage, setBgImage ] =useState();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE.toUpperCase() : 'PIPELINE';
	const defaultbgimage = (oneviewType.toLowerCase()=='well' && dashboardtype == 'oneview') ? wellimg : (oneviewType.toLowerCase()=='border' && dashboardtype == 'oneview' ) ? borderimg : '';

	data.companydata && data.companydata.map((company)=>{
		companies.push({
			value:company.id,
			label:company.companyName.toUpperCase()});
	});

	data.companydata && data.companydata.map((company)=>{
		company && company.assetName.map((pipeline)=>{
			if(company.id==companyId){
				pipelines.push({
					value:pipeline.id,
					label:pipeline.pipelineName.toUpperCase()
				});
			}
		});
	});

	useEffect(() =>{
		loadBackGroundImage();
		localStorage.removeItem(`${dashboardtype.toUpperCase()}_API_URL`);
		localStorage.removeItem(`${dashboardtype.toUpperCase()}_ACTIVE_MENU_ITEM`);
		if (dashboardtype !== 'oneview') {
			dispatch({type:COMPANY_LIST});
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${process.env.REACT_APP_CORE_HOST}/company-pipelines`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json'
				},
				data: {

				}
			};

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch( { type: COMPANY_LIST_SUCCESS, payload: response.data.data });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: COMPANY_LIST_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
				});
		}
		else if(dashboardtype == 'oneview'){

			dispatch({type:COMPANY_LIST});
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${process.env.REACT_APP_CORE_HOST}/company-pipelines`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json'
				},
				data: {

				}
			};

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch( { type: COMPANY_LIST_SUCCESS, payload: response.data.data });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: COMPANY_LIST_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
				});

		}

	},[]);

	const loadBackGroundImage = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_CORE_HOST}/bg-image`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json'
			},
			data: {

			}
		};

		axios
			.request(config)
			.then((response) => {
				if(response.status == 200) {
					setBgImage(response.data.data.replace('public/',''));
				}else{
					setBgImage(null);
				}
			})
			.catch((error) => {
				console.log(error);
				setBgImage(null);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const handleApiUrlBox = () => {
		apiUrlBox === false ?
			setApiUrlBox(true) :
			setApiUrlBox(false);
	};


	const handleSelectCompany= (e)=>{
		setCompanyId(e);
		// setPipelineId(null)
    
	};


	const handleSelectPipeline= (e)=>{
		if(dashboardtype === 'tlp'){

			data && data.companydata.map((item)=>{
				item && item.assetName.map((inneritem)=>{
					if(inneritem.id==e){
						localStorage.setItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`,item.companyName);							
						localStorage.setItem(`${dashboardtype.toUpperCase()}_FEATURES`,JSON.stringify(inneritem.features));
					}
				});

			});
		}
		setPipelineId(e);
	};


	const getCompanySettings = ()=>{

		const dashboardtype = process.env.REACT_APP_IDENTIFIER;
		const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
		const companyId = localStorage.getItem(`${dashboardtype.toUpperCase()}_companyId`);
		const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
		const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/company/${companyId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		dispatch({ type: SETTINGS_LIST });
		
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_IDENTIFIER`,response.data.appIdentifier != null ? response.data.appIdentifier : 'oneview');
					localStorage.setItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`,response.data && response.data.downloadBatchSize !=null ? response.data.downloadBatchSize : 1000);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`,response.data && response.data.dateFormat !=null ? response.data.dateFormat : 'YYYY-MM-DD');
					localStorage.setItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`,response.data && response.data.timeFormat != null ? response.data.timeFormat : 'HH:mm');
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_EDGE`,response.data && response.data.isEdge != null ? response.data.isEdge : false);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_TLP`,response.data && response.data.tlpEnabled != null ? response.data.tlpEnabled : 'disabled');
					localStorage.setItem(`${dashboardtype.toUpperCase()}_DASHBOARD_VERSION`,response.data.dashboardVersion);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_API_VERSION`,response.data.apiVersion);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_TIMEOUT`,response.data && response.data.timeoutInterval !=null ? response.data.timeoutInterval : 5000);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`,response.data && response.data.extendedTimeoutInterval != null ? response.data.extendedTimeoutInterval : 180000);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`,response.data && response.data.refreshInterval != null ? response.data.refreshInterval : 180000);
					dispatch({type: SETTINGS_LIST_SUCCESS, payload: response.data});
					getPipelineSettings();
					// navigate('/dashboard');
					
				} else {
					dispatch({type: SETTINGS_LIST_ERROR});  
				}
			})
			.catch((error) => {
				console.log('# Error caught: ', error);

				dispatch({type: SETTINGS_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		
	};

	const getPipelineSettings = () => {

		const dashboardtype = process.env.REACT_APP_IDENTIFIER;
		const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
		const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
		const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipeline/${dashboardtype==='oneview' ? 1 : pipelineId}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
        
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					const data = response.data;
					// response.data && response.data.data.map((item) => {
					delete data['id'];	
					delete data['name'];	
					delete data['createdAt'];	
					delete data['updatedAt'];	
					delete data['companyId'];	
					delete data['tlpHardwareUsername'];	
					delete data['tlpHardwarePassword'];	
					localStorage.setItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`, JSON.stringify(data));						
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_STS`,data.stsEnabled);
					navigate('/dashboard');
					// });
				} 
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',					});
				}
			});
	};


	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			pipelineId:pipelineId,
			companyId:companyId,
			apiUrl: ''
		},
		onSubmit: (values) => {
			const { email, password, apiUrl} = values;

			let api;
			if(apiUrlBox === true && apiUrl.length > 0 ) {
				api = apiUrl;
			} else {
				api = process.env.REACT_APP_CORE_HOST;
			}

			if (dashboardtype === 'oneview') {
				localStorage.setItem(`${dashboardtype.toUpperCase()}_pipelineId`,1);
				localStorage.setItem(`${dashboardtype.toUpperCase()}_companyId`,1);
			} 
			else{
				localStorage.setItem(`${dashboardtype.toUpperCase()}_pipelineId`,pipelineId);
				localStorage.setItem(`${dashboardtype.toUpperCase()}_companyId`,companyId);
			}   

			if (!email) {
				errorvalidation('Please enter the email');
			} else if (!password) {
				errorvalidation('Please enter the password');
			}
			else if (!companyId && dashboardtype!=='oneview') {
				errorvalidation('Please select the company');
			}
			else if (!pipelineId && dashboardtype!=='oneview') {
				errorvalidation(`Please select the ${oneviewType.toLowerCase()}`);
			}
			else {
				dispatch({type:AUTHENTICATION_LIST});
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/authentication`,
					timeout: extendTimeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json'
					},
					data: {
						email: values.email,
						password: values.password,
						strategy: 'local',
						fromDashboard: 1,
						pipelineId : dashboardtype==='oneview' ? 1 : pipelineId
					}
				};
  
				axios
					.request(config)
					.then((response) => {
						if (response.status === 201) {
							dispatch( { type: AUTHENTICATION_LIST_SUCCESS, payload: response.data.data });
							
							
							if(response.data.user.permissions == 'linewalker' || response.data.user.permissions == 'supervisor' || response.data.user.permissions == 'nightpatrol' || response.data.user.permissions == 'tlp' ) {
								errorvalidation('Invalid login');
							} else {

								localStorage.setItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`,response.data.accessToken);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_USER_ID`,response.data.user.id);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_PERMISSION`,response.data.user.permissions);
								if(dashboardtype == 'oneview'){
									data && data.companydata.map((item)=>{
										item && item.assetName.map((inneritem)=>{
											if(inneritem.id==1){
												localStorage.setItem(`${dashboardtype.toUpperCase()}_FEATURES`,JSON.stringify(inneritem.features));
											}
										});
							
									});
								}
								if(dashboardtype == 'oneview'){
									data && data.companydata.map((item)=>{
										if(item.id==1){
											localStorage.setItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`,JSON.stringify(item.companyName));							
										}
									});
								}

								let config = {
									method: 'get',
									maxBodyLength: Infinity,
									url: `${api}/company/${companyId ? companyId : 1}`,
									timeout: timeout,
									headers: {
										accept: 'application/json',
										'Content-Type': 'application/json',
										Authorization:`Bearer ${response.data.accessToken}`
									},
									data:''
								};
				
								dispatch( { type: COMPANY_MAP });
						
								axios
									.request(config)
									.then((response) => {
										if (response.status === 200 || response.status === 201) {
											localStorage.setItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`,response.data && response.data.mapType ==1 ? 'googleMap' : 'leaflet');
											dispatch({type: COMPANY_MAP_SUCCESS, payload: response.data});
										} else {
											dispatch({ type: COMPANY_MAP_ERROR });
										}
									})
									.catch((error) => {
										console.log(error);
										dispatch({ type: COMPANY_MAP_ERROR });
										if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
											message.config({
												duration : 5,
												maxCount : 1,
											});
											message.open({
												type: 'error',
												content:  'It seems the network is unreachable. Please refresh the application.',
											});
										}
									});
								if(apiUrlBox==true){
									localStorage.setItem(`${dashboardtype.toUpperCase()}_API_URL`, apiUrl);
								}
								// localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_IDENTIFIER`,response.data.appIdentifier);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`,1000);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`,'YYYY-MM-DD');
								localStorage.setItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`, 'HH:mm');
								localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_EDGE`, false);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_TLP`, 'disabled');
								// localStorage.setItem(`${dashboardtype.toUpperCase()}_DASHBOARD_VERSION`, response.data.dashboardVersion);
								// localStorage.setItem(`${dashboardtype.toUpperCase()}_API_VERSION`,response.data.apiVersion);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_TIMEOUT`, 5000);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`, 180000);
								localStorage.setItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`, 180000);

								if(response.data.user.permissions !== 'admin' && response.data.user.permissions !== 'superadmin' && response.data.user.permissions !== 'linewalker' && 
								response.data.user.permissions !== 'supervisor' && response.data.user.permissions !== 'nightpatrol' && response.data.user.permissions !== 'Station-In-Charge' && 
								response.data.user.permissions !== 'Pipeline-In-Charge' && response.data.user.permissions !== 'Pipeline-HO' && response.data.user.permissions !== 'HQO-HSE' && response.data.user.permissions !== 'tlp'){
									if(response.data.user.allocationsList !==null){
										getCompanySettings();
										// navigate('/dashboard');
									}else{
										errorvalidation('You don\'t have any allocation');
									} 
									
									// localStorage.setItem(`${dashboardtype.toUpperCase()}_ALLOCATION_ID`,response.data.user.allocationsList && response.data.user.allocationsList[0].id);
								}else{
									getCompanySettings();
									// navigate('/dashboard');
								}

							}


							// success('Login success');
							// Modal.success({ title: 'Login success' });

						}
					})
					.catch((error) => {
						if(error.response && error.response.status === 401){
							errorvalidation(error.response.data.message);
						} else {
							errorvalidation('Login failed, please try again.');
						}
						dispatch({ type: AUTHENTICATION_LIST_ERROR});
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});

			}
		}
    
	});

	const handleFormValuesChange = (changedValues) => {
		const formFieldName = Object.keys(changedValues)[0];
		if (formFieldName === 'companyId') {
		//   setSelectedCompanyId(changedValues[formFieldName]);  // perform setState here
			form.setFieldsValue({pipelineId: undefined}); //reset product selection
		}
	};

	return (
		<>
			{contextHolder}
			
			<Content className="container-login100" style={{ backgroundImage : bgImage ? `url(${api+'/'+bgImage}` : defaultbgimage ? `url(${defaultbgimage})` : ''}}>
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}

				

					// style={{
					// 	backgroundImage: ` url(${login})`,
					// 	width: '200.4vh',
					// 	height: '100vh',
					// 	backgroundSize: 'cover',
					// 	backgroundRepeat: 'no-repeat',
					// 	display: 'flex',
					// 	justifyContent: 'center',
					// 	alignItems: 'center'
					// }}
   
				>
    
					<Col className="gutter-row" span={6}>&nbsp;</Col>
					<Col style={{display:'flex', justifyContent:'center', alignItems:'center'}} className="gutter-row" span={12}>
						<Row style={{display:'flex', justifyContent:'center', alignItems:'center'}} gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}>
							<Col style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom: '10px'}} className="gutter-row" span={24}>
								<img src={logo} alt="Logo" style={{ width: 'auto', height: '60px',display:'flex', justifyContent:'center', alignItems:'center'}} />
							</Col>
							<Row gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}} style={{width:'640px'}}>
								<Form onKeyUp={(ev) => {
								
									if (ev.keyCode === 13) {
										formik.handleSubmit();
									}
								}} layout="vertical" style={{width:'100%'}} 
								form={form} onValuesChange={handleFormValuesChange}>
									<Col style={{ paddingLeft: '0px', paddingRight: '0px', display:'flex', justifyContent:'center', alignItems:'center'}} className="gutter-row" span={24}>
										<Card title='OneView Pro Login' bordered={false} style={{width:'100%', justifyContent:'center', textAlign:'center', padding:'5px'}}>
											<Form.Item name="email" id='email' type='email' onChange={formik.handleChange}  rules={[{ required: true, message: 'Enter your email' }]} >
												<Input placeholder="Email" prefix={<UserOutlined />} />
											</Form.Item>
											<Form.Item name="password" id='password' type='password' onChange={formik.handleChange} rules={[{ required: true, message: 'Enter your password' }]} >
												<Input.Password placeholder="Password" visibilityToggle={{
													visible: passwordVisible,
													onVisibleChange: setPasswordVisible,
												}} prefix={<LockOutlined /> } />
											</Form.Item>

											{ dashboardtype === 'oneview' ? null :

												<Form.Item name="companyId" id='companyId' type='companyId' rules={[{ required: true, message: 'Please select your company' }]} >
													<Select value={companyId}  onSelect={handleSelectCompany} defaultValue={companyId} placeholder='Company' options={companies} />      
												</Form.Item> }

											{ dashboardtype === 'oneview' ? null :

												<Form.Item name="pipelineId" id='pipelineId'  rules={[{ required: true, message: 'Please select your pipeline' }]} >
													<Select value={pipelineId && pipelineId} onSelect={handleSelectPipeline} defaultValue={pipelineId}
														disabled={companyId ? false : true}
														placeholder={oneviewType} options={pipelines} />
												</Form.Item> }

											{apiUrlBox === true ? 
												<Form.Item name="apiUrl" onChange={formik.handleChange} rules={[{ required: false, message: 'Please select custom API URL' }]} >
													<Input placeholder='CUSTOM API URL' prefix={<GlobalOutlined />} />      
												</Form.Item>
												: null }
											<Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'center', paddingLeft : '0px', paddingRight : '0px'}}>
												<Button type="primary" style={{ width : '100%'}}  onClick={formik.handleSubmit}>Login</Button>
											</Col>
											<Row gutter={{
												xs: 8,
												sm: 16,
												md: 24,
												lg: 32,
        
											}} style={{ marginTop :'20px'}}>
												<Col className="gutter-row" style={{ display : 'flex', justifyContent :'left'}} span={12} >Version {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.0'}</Col>  
												<Col className="gutter-row" style={{ display : 'flex', justifyContent :'right'}} span={12}>	
													<Space> 
														<Button onClick={handleApiUrlBox}><ToolOutlined /></Button>
													</Space>
												</Col>
											</Row>
										</Card>
									</Col>
								</Form>
							</Row>
						</Row>
					</Col>
					<Col className="gutter-row" span={6}>&nbsp;
					</Col>
				</Row>
			</Content>
			<Spin fullscreen spinning={data.pending} />
		</>
	);
}