import React, { useEffect, useReducer, useState } from 'react';

import { Typography, Row, Col, Button, Form, Input, Select, Empty, Spin, Space, Modal, message, Tabs, Switch, Popover, Avatar, Card, Upload } from 'antd';
import { Content } from 'antd/es/layout/layout';
import TabPane from 'antd/es/tabs/TabPane';

import { SETTINGS_LIST, SETTINGS_LIST_SUCCESS, SETTINGS_LIST_ERROR } from './constants';
import { UPDATE_SETTINGS, UPDATE_SETTINGS_ERROR } from './constants';
import { GET_TLP_HARDWARE_SETTINGS, GET_TLP_HARDWARE_SETTINGS_SUCCESS, GET_TLP_HARDWARE_SETTINGS_ERROR } from './constants';
import { UPDATE_TLP_HARDWARE_SETTINGS, UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS, UPDATE_TLP_HARDWARE_SETTINGS_ERROR } from './constants.js';

import settingsReducer from './reducer.js';

import { useFormik } from 'formik';
import axios from 'axios';
import { SettingOutlined, InfoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import { useAuth } from '../Context';
import Features from './features.jsx';
import ImgCrop from 'antd-img-crop';


const { Title } = Typography;

export default function Settings() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const companyId = localStorage.getItem(`${dashboardtype.toUpperCase()}_companyId`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`); 
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const initalState = {settings :  null, pending: false, error: false };
	const [data, dispatch] = useReducer(settingsReducer, initalState);
	useAuth();

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const maps = [{ value: 1, label: langdata && langdata.GoogleMaps ? langdata.GoogleMaps : 'Google Maps' }, { value: 2, label: langdata && langdata.OpenStreetMaps ? langdata.OpenStreetMaps : 'Open Street Maps' }];
	const dateFormats = [ { value: 'YYYY-MM-DD', label: langdata && langdata.YearMonthDayYYYYMMDD1 ? langdata.YearMonthDayYYYYMMDD1 : 'Year-Month-Day (YYYY-MM-DD)' },{ value :'DD-MM-YYYY', label : langdata && langdata.DayMonthYearDDMMYYYY1 ? langdata.DayMonthYearDDMMYYYY1 : 'Day-Month-Year (DD-MM-YYYY)'}, { value : 'MM-DD-YYYY', label : langdata && langdata.MonthDayYearMMDDYYYY1 ? langdata.MonthDayYearMMDDYYYY1 :  'Month-Day-Year (MM-DD-YYYY)'},{ value: 'YYYY/MM/DD', label: langdata && langdata.YearMonthDayYYYYMMDD2 ? langdata.YearMonthDayYYYYMMDD2 :  'Year/Month/Day (YYYY/MM/DD)' },{ value :'DD/MM/YYYY', label : langdata && langdata.DayMonthYearDDMMYYYY2 ? langdata.DayMonthYearDDMMYYYY2 :  'Day/Month/Year (DD/MM/YYYY)'}, { value : 'MM/DD/YYYY', label : langdata && langdata.MonthDayYearMMDDYYYY2 ? langdata.MonthDayYearMMDDYYYY2 : 'Month/Day/Year (MM/DD/YYYY)'} ];
	const timeFormats = [ { value: 'HH:mm', label: langdata && langdata.Time24hourtime ? langdata.Time24hourtime :  '24 hour time' }, { value: 'HH:mm:ss', label: langdata && langdata.Time24hourtimewithseconds ? langdata.Time24hourtimewithseconds :  '24 hour time with seconds' }, { value: 'hh:mm a', label: langdata && langdata.Time12hourtimewithampmindicator ? langdata.Time12hourtimewithampmindicator : '12 hour time with am/pm indicator' }, { value: 'hh:mm A', label:  langdata && langdata.Time12hourtimewithAMPMindicator ? langdata.Time12hourtimewithAMPMindicator : '12 hour time with AM/PM indicator' }, { value: 'hh:mm:ss a', label: langdata && langdata.Time12hourtimewithsecondsandampmindicator ? langdata.Time12hourtimewithsecondsandampmindicator : '12 hour time with seconds and am/pm indicator' },{ value: 'hh:mm:ss A', label: langdata && langdata.Time12hourtimewithsecondsandAMPMindicator ? langdata.Time12hourtimewithsecondsandAMPMindicator : '12 hour time with seconds and AM/PM indicator' } ];
	
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const navigate = useNavigate();
	const [openreloadmodal, setReloadModal] = useState(false);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const envtlpEnable = process.env.REACT_APP_TLP == undefined || process.env.REACT_APP_TLP == 'undefined' ? 'disabled' : process.env.REACT_APP_TLP;
	const tlpEnable = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'null' ? envtlpEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`);

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';

	const edgeenabledinfoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.edgeenablecontent ? langdata.edgeenablecontent : 'Edge enabled settings in the OneView Pro is only for the Edge supported system. This can manage Edge specific modules'}</p>
		</Card>
	);

	const edgeenabledinfo = (
		<Space>
			<label>{langdata && langdata.EdgeEnabled ? langdata.EdgeEnabled : 'Edge Enabled?'}</label>
			<Popover title={langdata && langdata.EnableEdge ? langdata.EnableEdge : 'Enable Edge'} content={edgeenabledinfoContent} trigger="click">
				<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
			</Popover>
		</Space>
	);

	const [fileList, setFileList] = useState([
		
	]);
	
	// const [isEdge, setIsEdge] = useState(false);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 112);
		loadBackGroundImage();
	}, []);

	useEffect(() => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/company/${companyId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		let config2 = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipeline/${pipelineId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		dispatch({ type: SETTINGS_LIST });
		
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: SETTINGS_LIST_SUCCESS, payload: response.data});
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_IDENTIFIER`,response.data.appIdentifier);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`,response.data.downloadBatchSize);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`,response.data.dateFormat);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`,response.data.timeFormat);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_EDGE`,response.data.isEdge);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_TLP`,response.data.tlpEnabled);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_DASHBOARD_VERSION`,response.data.dashboardVersion);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_API_VERSION`,response.data.apiVersion);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_TIMEOUT`,response.data.timeoutInterval);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`,response.data.extendedTimeoutInterval);
					localStorage.setItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`,response.data.refreshInterval);

				} else {
					dispatch({type: SETTINGS_LIST_ERROR});  
				}
			})
			.catch((error) => {
				console.log('# Error caught: ', error);

				dispatch({type: SETTINGS_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

		dispatch({ type: GET_TLP_HARDWARE_SETTINGS });

		axios
			.request(config2)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: GET_TLP_HARDWARE_SETTINGS_SUCCESS, payload: response.data});					
					localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_STS`, response.data.stsEnabled);
				} else {
					dispatch({type: GET_TLP_HARDWARE_SETTINGS_ERROR});  
				}
			})
			.catch((error) => {
				console.log('# Error caught: ', error);

				dispatch({type: GET_TLP_HARDWARE_SETTINGS_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	}, []);

	const settingsFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: data && data.settings ? data.settings.name : '',
			isEdge: data && data.settings && data.settings.isEdge,
			tlpEnabled: data && data.settings &&  data.settings.tlpEnabled,
			mapType: data && data.settings ? data.settings.mapType : 1,
			downloadBatchSize: data && data.settings ? data.settings.downloadBatchSize : 0,
			dateFormat: data && data.settings ? data.settings.dateFormat : 'YYYY-MM-DD',
			timeFormat: data && data.settings ? data.settings.timeFormat : 'HH:mm',
			timeoutInterval: data && data.settings ? data.settings.timeoutInterval : 0,
			extendedTimeoutInterval: data && data.settings ? data.settings.extendedTimeoutInterval : 0,
			refreshInterval: data && data.settings ? data.settings.refreshInterval : 0,
			stsEnabled: data && data.tlpHardwareSettings && data.tlpHardwareSettings.stsEnabled
		},

		onSubmit: (values) => {
			const name = values.name ? values.name : '';
			const isEdge = values.isEdge;
			const tlpEnabled = values.tlpEnabled;
			const mapType = values.mapType ? values.mapType : 1;
			const downloadBatchSize = values.downloadBatchSize ? values.downloadBatchSize : 0;
			const dateFormat = values.dateFormat ? values.dateFormat : 'YYYY-MM-DD';
			const timeFormat = values.timeFormat ? values.timeFormat : 'HH:mm';
			const timeoutInterval = values.timeoutInterval ? parseInt(values.timeoutInterval)*1000 : 0;
			const extendedTimeoutInterval = values.extendedTimeoutInterval ? parseInt(values.extendedTimeoutInterval)*1000 : 0;
			const refreshInterval = values.refreshInterval ? parseInt(values.refreshInterval)*1000 : 0;
			const stsEnabled = values.stsEnabled;			


			if (mapType === 1) {
				localStorage.setItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`, 'googleMap');
			} else {
				localStorage.setItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`, 'leaflet');
			}

			if(downloadBatchSize < 1000){
				Modal.warning({ title : langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'Download batch size must be greater than 1000 records'});
				return false;
			}

			if(timeoutInterval < 5000){
				Modal.warning({ title : langdata && langdata.Timeoutintervalmustbegreaterthan5seconds ? langdata.Timeoutintervalmustbegreaterthan5seconds : 'Timeout interval must be greater than 5 seconds'});
				return false;
			}

			if(extendedTimeoutInterval < 180000){
				Modal.warning({ title : langdata && langdata.Extendedtimeoutintervalmustbegreaterthan180seconds ? langdata.Extendedtimeoutintervalmustbegreaterthan180seconds : 'Extended timeout interval must be greater than 180 seconds'});
				return false;
			}

			if(refreshInterval < 180000){
				Modal.warning({ title : langdata && langdata.Autorefreshintervalmustbegreaterthan180seconds ? langdata.Autorefreshintervalmustbegreaterthan180seconds : 'Auto refresh interval must be greater than 180 seconds'});
				return false;
			}

			if (!name || name.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidcompanyname ? langdata.Pleaseprovideavalidcompanyname : 'Please provide a valid company name.' });
				return;
			} else {
				let config1 = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/pipeline/${pipelineId}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						stsEnabled: stsEnabled
					}
				};

				dispatch( { type: UPDATE_TLP_HARDWARE_SETTINGS });
	
				axios
					.request(config1)
					.then(() => {
						// if (response.status === 200 || response.status === 201) {
						// 	dispatch({type: UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS, payload: response.data});
						// 	Modal.success({ title: langdata && langdata.TLPhardwaresettingsupdatedsuccessfully ? langdata.TLPhardwaresettingsupdatedsuccessfully : 'TLP hardware settings updated successfully.' });
						// } else {
						// 	dispatch({ type: UPDATE_TLP_HARDWARE_SETTINGS_ERROR });
						// 	Modal.warning({ title: langdata && langdata.UnabletoupdatetheTLPhardwaresettings ? langdata.UnabletoupdatetheTLPhardwaresettings : 'Unable to update the TLP hardware settings.' });  
						// }
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: UPDATE_TLP_HARDWARE_SETTINGS_ERROR });
						// if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						// 	message.config({
						// 		duration : 5,
						// 		maxCount : 1,
						// 	});
						// 	message.open({
						// 		type: 'error',
						// 		content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
						// 	});
						// }  
					});

				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/company/${companyId}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						name: name,
						isEdge: isEdge,
						tlpEnabled: tlpEnabled == true ? 'enabled' : 'disabled',
						mapType: mapType,
						downloadBatchSize: downloadBatchSize,
						dateFormat: dateFormat,
						timeFormat: timeFormat,
						timeoutInterval: timeoutInterval,
						extendedTimeoutInterval: extendedTimeoutInterval,
						refreshInterval: refreshInterval
					}
				};

				dispatch( { type: UPDATE_SETTINGS });
        
				axios
					.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({type: SETTINGS_LIST_SUCCESS, payload: response.data});
							// Modal.success({ title: 'General settings updated successfully.' });
							localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_IDENTIFIER`,response.data.appIdentifier);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`,response.data.downloadBatchSize);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`,response.data.dateFormat);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`,response.data.timeFormat);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_EDGE`,response.data.isEdge);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_TLP`,response.data.tlpEnabled);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_DASHBOARD_VERSION`,response.data.dashboardVersion);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_API_VERSION`,response.data.apiVersion);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_TIMEOUT`,response.data.timeoutInterval);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`,response.data.extendedTimeoutInterval);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`,response.data.refreshInterval);
							localStorage.setItem(`${dashboardtype.toUpperCase()}_APP_STS`,stsEnabled);
							setReloadModal(true);
						} else {
							dispatch({ type: UPDATE_SETTINGS_ERROR });
							Modal.warning({ title: langdata && langdata.Unabletoupdatethegeneralsettings ? langdata.Unabletoupdatethegeneralsettings : 'Unable to update the general settings.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: UPDATE_SETTINGS_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
						else{
							Modal.warning({ title: langdata && langdata.Unabletoupdatethegeneralsettings ? langdata.Unabletoupdatethegeneralsettings : 'Unable to update the general settings.' });  
						}  
					});
			} 
		}
	});
	
	const tlpHardwareSettingsFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			tlpHardwareUsername: data && data.tlpHardwareSettings ? data.tlpHardwareSettings.tlpHardwareUsername : '',
			tlpHardwarePassword: data && data.tlpHardwareSettings ? data.tlpHardwareSettings.tlpHardwarePassword : ''
		},

		onSubmit: (values) => {
			const tlpHardwareUsername = values.tlpHardwareUsername ? values.tlpHardwareUsername : '';
			const tlpHardwarePassword = values.tlpHardwarePassword ? values.tlpHardwarePassword : '';

			if (!tlpHardwareUsername || tlpHardwareUsername.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidusername ? langdata.Pleaseprovideavalidusername : 'Please provide a valid user name.' });
				return;
			} else {

				if (!tlpHardwarePassword || tlpHardwarePassword.length <= 0) {
					Modal.warning({ title: langdata && langdata.Pleaseprovideavalidpassword ? langdata.Pleaseprovideavalidpassword : 'Please provide a valid password.' });
					return;
				} else {
					let config = {
						method: 'patch',
						maxBodyLength: Infinity,
						url: `${api}/pipeline/${pipelineId}`,
						timeout: timeout,
						headers: {
							accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization:`Bearer ${authtoken}`
						},
						data: {
							tlpHardwareUsername: tlpHardwareUsername,
							tlpHardwarePassword: tlpHardwarePassword
						}
					};

					dispatch( { type: UPDATE_TLP_HARDWARE_SETTINGS });
        
					axios
						.request(config)
						.then((response) => {
							if (response.status === 200 || response.status === 201) {
								dispatch({type: UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS, payload: response.data});
								Modal.success({ title: langdata && langdata.TLPhardwaresettingsupdatedsuccessfully ? langdata.TLPhardwaresettingsupdatedsuccessfully : 'TLP hardware settings updated successfully.' });
							} else {
								dispatch({ type: UPDATE_TLP_HARDWARE_SETTINGS_ERROR });
								Modal.warning({ title: langdata && langdata.UnabletoupdatetheTLPhardwaresettings ? langdata.UnabletoupdatetheTLPhardwaresettings : 'Unable to update the TLP hardware settings.' });  
							}
						})
						.catch((error) => {
							console.log(error);
							dispatch({ type: UPDATE_TLP_HARDWARE_SETTINGS_ERROR });
							if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
								message.config({
									duration : 5,
									maxCount : 1,
								});
								message.open({
									type: 'error',
									content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
								});
							}  
						});
				}
			} 
		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const applyChanges = ()=>{
		navigate(0);
	};

	const closeReloadModal = ()=>{
		setReloadModal(false);
	};

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		if(newFileList.length != 0 && newFileList && newFileList[0].type == 'image/png' || newFileList.length != 0 && newFileList && newFileList[0].type == 'image/jpg' || newFileList.length != 0 && newFileList && newFileList[0].type == 'image/jpeg'){
			if(newFileList.length != 0 && newFileList && newFileList[0].status !== 'uploading') {
				upload();
			} 
		} else if(newFileList.length != 0) {
			message.open({
				type: 'error',
				content:  `${langdata && langdata.Unabletoupload ? langdata.Unabletoupload : 'Unable to upload'} ${newFileList.length != 0 && newFileList && newFileList[0].type}.` ,
			});
		}
		else if(newFileList.length == 0){
			deleteBackGroundImage();
		}
	};
	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};
	
	const upload = () => {
		const formData = new FormData();
		formData.append('file',fileList && fileList[0] && fileList[0].originFileObj ? fileList[0].originFileObj : '');
		formData.append('id', parseInt(pipelineId));
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/pipelinebgupload`,
			timeout: timeout,
			headers: { 
				Authorization:`Bearer ${authtoken}`,
				// ...formData.getHeaders()
			},
			data : formData
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					message.open({
						type: 'success',
						content:  `${langdata && langdata.Imageuploadedsuccessfully ? langdata.Imageuploadedsuccessfully : 'Image uploaded successfully'}`,
					});
				}
				// loadBackGroundImage();
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	};

	const loadBackGroundImage = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_CORE_HOST}/bg-image`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json'
			},
			data: {

			}
		};

		axios
			.request(config)
			.then((response) => {
				if(response.status == 200) {
					if(response.data.data){
						setFileList([
							{
								uid: '-1',
								name: 'image.png',
								status: 'done',
								url: `${api+'/'+ response.data.data.replace('public/','')}`,
							},
						]);
					} else {
						setFileList ([]);
					}
				}

			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const deleteBackGroundImage = () => {
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_CORE_HOST}/bg-image`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json'
			},
			data: {

			}
		};

		axios
			.request(config)
			.then((response) => {
				if(response.status == 200) {
					message.open({
						type: 'success',
						content:  `${langdata && langdata.Imagedeletedsuccessfully ? langdata.Imagedeletedsuccessfully : 'Image deleted successfully'}`,
					});
				}

			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};
  
	return (
		permission==='superadmin' || permission === 'admin' ? 

			<>
				<Content>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

						<Col span={24}>
							<Row>
								<Space>
									<SettingOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.Settings ? langdata.Settings : 'Settings'}</Title>
							</Row>
						</Col>
					</Row>
					<Tabs defaultActiveKey="1" 
						style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
						}}>
						<TabPane tab={langdata && langdata.GeneralSettings ? langdata.GeneralSettings : 'General Settings'} key="1">
							<Row style={{
								minHeight: '50vh'
							}} >
								<Col span={24} style={{ marginTop: '20px'}}>
									{ data ? (
										<Form layout="vertical" onSubmit={settingsFormik.handleSubmit}>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
												<Col span={12}>
													<Form.Item label={langdata && langdata.CompanyName ? langdata.CompanyName : 'Company Name'} required>
														<Input name="name" placeholder={langdata && langdata.Pleaseenterthecompanyname ? langdata.Pleaseenterthecompanyname : 'Please enter the company name'} onChange={settingsFormik.handleChange} value={settingsFormik.values.name}
															disabled = {permission === 'superadmin' ? false : true}
														/>
													</Form.Item>
												</Col>
												<Col span={4}>
													<Form.Item label={edgeenabledinfo}>
														<Switch name="isEdge" checkedChildren="YES" unCheckedChildren="NO"  checked={settingsFormik.values.isEdge} onChange={(value) => settingsFormik.setFieldValue('isEdge', value)} />
													</Form.Item>
												</Col>
												<Col span={4}>
													<Form.Item label={langdata && langdata.TLPEnabled ? langdata.TLPEnabled : 'TLP Enabled?' }>
														<Switch name="tlpEnabled" checkedChildren="YES" unCheckedChildren="NO"  checked={settingsFormik.values.tlpEnabled} onChange={(value) => settingsFormik.setFieldValue('tlpEnabled', value)}/>
													</Form.Item>
												</Col>
												<Col span={4}>
													<Form.Item label={langdata && langdata.STSEnabled ? langdata.STSEnabled : 'STS Enabled?' }>
														<Switch name="stsEnabled" checkedChildren="YES" unCheckedChildren="NO"  checked={settingsFormik.values.stsEnabled} onChange={(value) => settingsFormik.setFieldValue('stsEnabled', value)}/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
												<Col span={12}>
													<Form.Item label={langdata && langdata.MapType ? langdata.MapType : 'Map Type'} required>
														<Select
															name="mapType" 
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Selectthemaptype ? langdata.Selectthemaptype : 'Select the map type'}
															optionFilterProp="children"
															options={maps}
															initialValue= {settingsFormik.values.mapType}
															value= {settingsFormik.values.mapType}
															onChange={(value) => settingsFormik.setFieldValue('mapType', value)}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Downloadbatchsize ? langdata.Downloadbatchsize : 'Download batch size'} required>
														<Input min={1000} type="number" name="downloadBatchSize" placeholder={langdata && langdata.Pleaseenterthedownloadbatchsize ? langdata.Pleaseenterthedownloadbatchsize : 'Please enter the download batch size'} onChange={settingsFormik.handleChange} value={settingsFormik.values.downloadBatchSize}
															disabled = {permission === 'superadmin' ? false : true}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Dateformat ? langdata.Dateformat : 'Date format'} placeholder="Select the date format" required rules={[{ required: true, message: 'Select the date format' }]}>
														<Select
															name="dateFormat" 
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Selectthedateformat ? langdata.Selectthedateformat : 'Select the date format'}
															optionFilterProp="children"
															options={dateFormats}
															initialValue= {settingsFormik.values.dateFormat && settingsFormik.values.dateFormat}
															value= {settingsFormik.values.dateFormat && settingsFormik.values.dateFormat}
															onChange={(value) => settingsFormik.setFieldValue('dateFormat', value)}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Timeformat ? langdata.Timeformat : 'Time format'} required rules={[{ required: true, message: 'Select the time format' }]}>
														<Select
															name="timeFormat" 
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Selectthetimeformat ? langdata.Selectthetimeformat :'Select the time format'}
															optionFilterProp="children"
															options={timeFormats}
															initialValue= {settingsFormik.values.timeFormat && settingsFormik.values.timeFormat}
															value= {settingsFormik.values.timeFormat && settingsFormik.values.timeFormat}
															onChange={(value) => settingsFormik.setFieldValue('timeFormat', value)}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Timeoutintervalseconds ? langdata.Timeoutintervalseconds : 'Timeout interval (seconds)'} required>
														<Input type="number" name="timeoutInterval"  min={5} placeholder={langdata && langdata.Pleaseenterthetimeoutinterval ? langdata.Pleaseenterthetimeoutinterval : 'Please enter the timeout interval'} onChange={settingsFormik.handleChange} value={(settingsFormik.values.timeoutInterval)}
															disabled = {permission === 'superadmin' ? false : true}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Extendedtimeoutintervalseconds ? langdata.Extendedtimeoutintervalseconds : 'Extended timeout interval (seconds)'} required>
														<Input type="number" name="extendedTimeoutInterval" min={180} placeholder={langdata && langdata.Pleaseentertheextendedtimeoutinterval ? langdata.Pleaseentertheextendedtimeoutinterval : 'Please enter the extended timeout interval'} onChange={settingsFormik.handleChange} value={(settingsFormik.values.extendedTimeoutInterval)}
															disabled = {permission === 'superadmin' ? false : true}
														/>
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
												<Col span={12}>
													<Form.Item label={langdata && langdata.Autorefreshintervalseconds ? langdata.Autorefreshintervalseconds : 'Auto refresh interval (seconds)'} required>
														<Input type="number" name="refreshInterval" min={180} placeholder={langdata && langdata.Pleaseentertheautorefreshinterval ? langdata.Pleaseentertheautorefreshinterval : 'Please enter the auto refresh interval'} onChange={settingsFormik.handleChange} value={(settingsFormik.values.refreshInterval)}
															disabled = {permission === 'superadmin' ? false : true}
														/>
													</Form.Item>
												</Col>
												{permission == 'superadmin' ?
													<Col span={12}>
														<Form.Item label={langdata && langdata.Backgroundimageryforthelogininterface ? langdata.Backgroundimageryforthelogininterface : 'Background imagery for the login interface'} required>
															<ImgCrop rotationSlider>
																<Upload
																	// action={`${api}/pipelinebgupload`}
																	customRequest={({  onSuccess }) => {
																		setTimeout(() => {
																			onSuccess('ok');
																		}, 0);
																	}}
																	listType="picture-card"
																	fileList={fileList}
																	onChange={onChange}
																	onPreview={onPreview}
																>
																	{fileList.length < 1 && '+ Upload'}
																</Upload>
															</ImgCrop>
														</Form.Item>
													</Col>
													: null }
											</Row>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: '20px', marginBottom: '20px'}}>
												<Col className="gutter-row" span={24} style={{ textAlign : 'center'}} >
													<Button key="submit" type="primary" onClick={settingsFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
												</Col>  
											</Row>
										</Form>
									)
										:
										(
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)}
								</Col>
							</Row>
						</TabPane>
						{ oneviewType != 'well' && (tlpEnable == 'enabled' || dashboardtype.toLowerCase() == 'tlp') ?
							<TabPane tab={langdata && langdata.TLPHardwareSettings ? langdata.TLPHardwareSettings : 'TLP Hardware Settings'} key="2">
								<Row style={{
									minHeight: '50vh'
								}} >
									<Col span={24} style={{ marginTop: '20px'}}>
										{ data ? (
											<Form layout="vertical" onSubmit={settingsFormik.handleSubmit}>
												<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
													<Col span={12}>
														<Form.Item label={langdata && langdata.UserName ? langdata.UserName : 'User Name'} required>
															<Input name="tlpHardwareUsername" placeholder={langdata && langdata.Pleaseentertheusername ? langdata.Pleaseentertheusername : 'Please enter the user name'} onChange={tlpHardwareSettingsFormik.handleChange} value={tlpHardwareSettingsFormik.values.tlpHardwareUsername}
																disabled = {permission === 'superadmin' ? false : true}
															/>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label={langdata && langdata.Password ? langdata.Password : 'Password' } required>
															<Input.Password name="tlpHardwarePassword" type="password" placeholder={langdata && langdata.Pleaseenterthepassword ? langdata.Pleaseenterthepassword : 'Please enter the password'} onChange={tlpHardwareSettingsFormik.handleChange} value={tlpHardwareSettingsFormik.values.tlpHardwarePassword}
																disabled = {permission === 'superadmin' ? false : true}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: '20px', marginBottom: '20px'}}>
													<Col className="gutter-row" span={24} style={{ textAlign : 'center'}} >
														<Button key="submit" type="primary" onClick={tlpHardwareSettingsFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE' }</Button>
													</Col>  
												</Row>
											</Form>
										)
											:
											(
												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
											)}
									</Col>
								</Row>
							</TabPane>
							: 
							null 
						}
						{permission == 'superadmin' ?
							<TabPane tab={langdata && langdata.Features ? langdata.Features : 'Features'} key="3">
								<Features />
							</TabPane>
							: null }
					</Tabs>
				
					<Modal
						title={langdata && langdata.Pleaseconfirm ? langdata.Pleaseconfirm : 'Please confirm'}
						centered
						destroyOnClose={true}
						open={openreloadmodal}
						onOk={{closeReloadModal}}
						onCancel={closeReloadModal}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Space>
								<Button onClick={closeReloadModal}>{langdata && langdata.No ? langdata.No : 'No' }</Button>
								{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={()=>{applyChanges();}}>{langdata && langdata.Yes ? langdata.Yes : 'Yes'}</Button> : null }
							</Space>
						]}
						width={540}
					>
						<Row gutter={16}>
							<Col span={24}>
								<Typography>
									{langdata && langdata.Generalsettingsupdatedsuccessfully ? langdata.Generalsettingsupdatedsuccessfully : 
										'General settings updated successfully. Do you want to apply the new changes immediately?' }
								</Typography>
							</Col>
						</Row>
					</Modal>
				</Content>

				<Spin fullscreen spinning={data.loading} />
			</> :
			null 
	);
}