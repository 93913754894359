import { DASHBOARD_LIST, DASHBOARD_LIST_ERROR, DASHBOARD_LIST_SUCCESS, LOAD_DATALOSS_GRAPH, LOAD_DATALOSS_GRAPH_ERROR, LOAD_DATALOSS_GRAPH_SUCCESS, PIDS_GRAPH, PIDS_GRAPH_ERROR, PIDS_GRAPH_SUCCESS, PIDS_STATUS, PIDS_STATUS_ERROR, PIDS_STATUS_SUCCESS, REGION_LIST, REGION_LIST_ERROR, REGION_LIST_SUCCESS, STS_DASHBOARD_LIST, STS_DASHBOARD_LIST_ERROR, STS_DASHBOARD_LIST_SUCCESS, TLP_DASHBOARD_LIST, TLP_DASHBOARD_LIST_ERROR, TLP_DASHBOARD_LIST_SUCCESS } from './constants';

export default function regiondata(state, action) {

	switch (action.type) {
      
	case REGION_LIST:
		return { ...state, pending: true };
	case REGION_LIST_SUCCESS:
		return { ...state, regiondata: action.payload, pending: false };
	case REGION_LIST_ERROR:
		return { ...state, error: true };


	case DASHBOARD_LIST:
		return { ...state, pending: true };
	case DASHBOARD_LIST_SUCCESS:
		return { ...state, dashboarddata: action.payload, pending: false };
	case DASHBOARD_LIST_ERROR:
		return { ...state, error: true };
	case PIDS_STATUS : 
		return { ...state, pending: true };
	case PIDS_STATUS_SUCCESS :
		return { ...state, pidsStatus: action.payload.data, pending: false };
	case PIDS_STATUS_ERROR :
		return { ...state, error: true };

	case PIDS_GRAPH : 
		return { ...state, pending: true };
	case PIDS_GRAPH_SUCCESS :
		return { ...state,pending: false };
	case PIDS_GRAPH_ERROR :
		return { ...state, error: true, pending:false };

	case TLP_DASHBOARD_LIST:
		return { ...state, pending: true };
	case TLP_DASHBOARD_LIST_SUCCESS:
		return { ...state, tlpdashboarddata: action.payload, pending: false };
	case TLP_DASHBOARD_LIST_ERROR:
		return { ...state, error: true };
	
	case STS_DASHBOARD_LIST:
		return { ...state, pending: true };
	case STS_DASHBOARD_LIST_SUCCESS:
		return { ...state, stsdashboarddata: action.payload, pending: false };
	case STS_DASHBOARD_LIST_ERROR:
		return { ...state, error: true };
	case LOAD_DATALOSS_GRAPH:
		return {...state, loading : true};
	case LOAD_DATALOSS_GRAPH_SUCCESS:
		return {...state, loading : false, heatmapData : action.payload};
	case LOAD_DATALOSS_GRAPH_ERROR:
		return {...state, loading : false};

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}