import { Button, Checkbox, Col, Form, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import { useAuth } from '../Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import settingsReducer from './reducer';
import { FEATURES_LIST, FEATURES_LIST_ERROR, FEATURES_LIST_SUCCESS} from './constants';

export default function Features() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`); 
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const [features, setFeatures] = useState({});

	const initalState = {loading: false, error: false };
	const [data, dispatch] = useReducer(settingsReducer, initalState);
	const [openreloadmodal, setReloadModal] = useState(false);

	const navigate = useNavigate();
	useAuth();

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 112);
	}, []);

	useEffect(() => {
		
		getFeatures();

	},[authtoken]);

	const getFeatures =()=>{
		dispatch({ type : FEATURES_LIST });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipeline/${pipelineId}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
        
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					// response.data && response.data.data.map((item) => {
					setFeatures({ realtimeEventsEnabled : response.data.realtimeEventsEnabled ,
						realtimeAlarmsEnabled : response.data.realtimeAlarmsEnabled,
						trackMyPIGEnabled : response.data.trackMyPIGEnabled,
						trackMyLWEnabled  : response.data.trackMyLWEnabled,
						reportEventsEnabled : response.data.reportEventsEnabled,
						reportAlarmsEnabled : response.data.reportAlarmsEnabled,
						reportPatchCoverageEnabled : response.data.reportPatchCoverageEnabled,
						reportVulnerableEnabled : response.data.reportVulnerableEnabled,
						reportGradeSystemEnabled : response.data.reportGradeSystemEnabled,
						retrainingDataEnabled : response.data.retrainingDataEnabled,
						retrainingSimulationsEnabled : response.data.retrainingSimulationsEnabled,
						retrainingHistoryEnabled : response.data.retrainingHistoryEnabled,
						retrainingModelInfoEnabled : response.data.retrainingModelInfoEnabled,
						retrainingModelConfigEnabled : response.data.retrainingModelConfigEnabled,
						inspectionsEnabled : response.data.inspectionsEnabled,
						eventAnalyticsEnabled : response.data.eventAnalyticsEnabled,
						alarmAnalyticsEnabled : response.data.alarmAnalyticsEnabled,
						chainageFilterEnabled : response.data.chainageFilterEnabled,
						vulnerablePointsEnabled : response.data.vulnerablePointsEnabled,
						snoozedPointsEnabled : response.data.snoozedPointsEnabled,
						terrainsEnabled : response.data.terrainsEnabled,
						realtimeCPSEnabled : response.data.realtimeCPSEnabled,
						fullCPSEnabled : response.data.fullCPSEnabled,
						observeCPSEnabled : response.data.observeCPSEnabled,
						dailyCPSEnabled : response.data.dailyCPSEnabled,
						cpsReportEnabled : response.data.cpsReportEnabled,
						allocationsEnabled : response.data.allocationsEnabled,
						userMgmtEnabled : response.data.userMgmtEnabled,
						speedClassificationEnabled : response.data.speedClassificationEnabled,
						tlpMgmtEnabled : response.data.tlpMgmtEnabled,
						tlpRuleEngineEnabled : response.data.tlpRuleEngineEnabled,
						mailConfigEnabled : response.data.mailConfigEnabled,
						escalationMatrixEnabled : response.data.escalationMatrixEnabled,
						pidsMgmtEnabled : response.data.pidsMgmtEnabled,
						alarmRuleEngineEnabled : response.data.alarmRuleEngineEnabled,
						eventTypesEnabled : response.data.eventTypesEnabled,
						fiberSplicingEnabled : response.data.fiberSplicingEnabled,
						channelInfoEnabled : response.data.channelInfoEnabled,
						regionMgmtEnabled : response.data.regionMgmtEnabled,
						gradeMgmtEnabled : response.data.gradeMgmtEnabled,
						edgeMgmtEnabled : response.data.edgeMgmtEnabled,
						notificationsEnabled : response.data.notificationsEnabled,
						wellVisualizationEnabled : response.data.wellVisualizationEnabled,
						wellTypeManagementEnabled : response.data.wellTypeManagementEnabled,
						wellFlowMonitoringEnabled : response.data.wellFlowMonitoringEnabled,
						wellSandIngressEnabled : response.data.wellSandIngressEnabled,
						wellLeakDetectionEnabled : response.data.wellLeakDetectionEnabled,
						wellFracturingEnabled : response.data.wellFracturingEnabled,
						wellMicroSeismicEnabled : response.data.wellMicroSeismicEnabled,
						wellAlgorithmEnabled : response.data.wellAlgorithmEnabled,
						wellGraphsEnabled : response.data.wellGraphsEnabled

						// });
					});
					dispatch({ type : FEATURES_LIST_SUCCESS });
				} 
				else {
					dispatch({ type : FEATURES_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : FEATURES_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const handleSubmit = () => {
		let config = {
			method: 'patch',
			maxBodyLength: Infinity,
			url: `${api}/pipeline/${pipelineId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
				realtimeEventsEnabled : features.realtimeEventsEnabled ,
				realtimeAlarmsEnabled : features.realtimeAlarmsEnabled,
				trackMyPIGEnabled : features.trackMyPIGEnabled,
				trackMyLWEnabled  : features.trackMyLWEnabled,
				reportEventsEnabled : features.reportEventsEnabled,
				reportAlarmsEnabled : features.reportAlarmsEnabled,
				reportPatchCoverageEnabled : features.reportPatchCoverageEnabled,
				reportVulnerableEnabled : features.reportVulnerableEnabled,
				reportGradeSystemEnabled : features.reportGradeSystemEnabled,
				retrainingDataEnabled : features.retrainingDataEnabled,
				retrainingSimulationsEnabled : features.retrainingSimulationsEnabled,
				retrainingHistoryEnabled : features.retrainingHistoryEnabled,
				retrainingModelInfoEnabled : features.retrainingModelInfoEnabled,
				retrainingModelConfigEnabled : features.retrainingModelConfigEnabled,
				inspectionsEnabled : features.inspectionsEnabled,
				eventAnalyticsEnabled : features.eventAnalyticsEnabled,
				alarmAnalyticsEnabled : features.alarmAnalyticsEnabled,
				chainageFilterEnabled : features.chainageFilterEnabled,
				vulnerablePointsEnabled : features.vulnerablePointsEnabled,
				snoozedPointsEnabled : features.snoozedPointsEnabled,
				terrainsEnabled : features.terrainsEnabled,
				realtimeCPSEnabled : features.realtimeCPSEnabled,
				fullCPSEnabled : features.fullCPSEnabled,
				observeCPSEnabled : features.observeCPSEnabled,
				dailyCPSEnabled : features.dailyCPSEnabled,
				cpsReportEnabled : features.cpsReportEnabled,
				allocationsEnabled : features.allocationsEnabled,
				userMgmtEnabled : features.userMgmtEnabled,
				speedClassificationEnabled : features.speedClassificationEnabled,
				tlpMgmtEnabled : features.tlpMgmtEnabled,
				tlpRuleEngineEnabled : features.tlpRuleEngineEnabled,
				mailConfigEnabled : features.mailConfigEnabled,
				escalationMatrixEnabled : features.escalationMatrixEnabled,
				pidsMgmtEnabled : features.pidsMgmtEnabled,
				alarmRuleEngineEnabled : features.alarmRuleEngineEnabled,
				eventTypesEnabled : features.eventTypesEnabled,
				fiberSplicingEnabled : features.fiberSplicingEnabled,
				channelInfoEnabled : features.channelInfoEnabled,
				regionMgmtEnabled : features.regionMgmtEnabled,
				gradeMgmtEnabled : features.gradeMgmtEnabled,
				edgeMgmtEnabled : features.edgeMgmtEnabled,
				notificationsEnabled : features.notificationsEnabled,
				wellVisualizationEnabled : features.wellVisualizationEnabled,
				wellTypeManagementEnabled : features.wellTypeManagementEnabled,
				wellFlowMonitoringEnabled : features.wellFlowMonitoringEnabled,
				wellSandIngressEnabled : features.wellSandIngressEnabled,
				wellLeakDetectionEnabled : features.wellLeakDetectionEnabled,
				wellFracturingEnabled : features.wellFracturingEnabled,
				wellMicroSeismicEnabled : features.wellMicroSeismicEnabled,
				wellAlgorithmEnabled : features.wellAlgorithmEnabled,
				wellGraphsEnabled : features.wellGraphsEnabled
			}
		};

		// dispatch( { type: UPDATE_SETTINGS });

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					// dispatch({type: SETTINGS_LIST_SUCCESS, payload: response.data});	
					delete response.data['id'];	
					delete response.data['name'];	
					delete response.data['createdAt'];	
					delete response.data['updatedAt'];	
					delete response.data['companyId'];	
					delete response.data['tlpHardwareUsername'];	
					delete response.data['tlpHardwarePassword'];	
					localStorage.setItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`, JSON.stringify(response.data));
					getFeatures();
					setReloadModal(true);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REALTIME_EVENTS`,response.data.realtimeEventsEnabled);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REALTIME_ALARMS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_MY_PIG`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_MY_LINEWALKER`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REPORT_EVENTS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REPORT_ALARMS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REPORT_STS_PATCH_COVERAGE`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REPORT_STS_VULNERABLE_PATCH_COVERAGE`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REPORT_STS_GRADE_SYSTEM`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_RETRAINING_DATA`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_SIMULATIONS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_RETRAINING_HISTORY`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_MODEL_INFO`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_MODEL_CONFIGURATION`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_STS_INSPECTIONS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_EVENT_ANALYTICS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_ALARM_ANALYTICS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_CHAINAGE_FILTER`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_VULNERABLE_POINTS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_SNOOZED_POINTS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_TERRAIN_CLASSIFICATION`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REALTIME_CPS`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_FULL_CPS_REPORT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_OBSERVE_CPS_REPORT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_DAILY_CPS_REPORT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_CPS_REPORT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_USER_ALLOCATION`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_USER_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_STS_SPEED_CLASSIFICATION`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_TLP_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_TLP_RULES`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_MAIL_CONFIGURATION`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_ESCALATION_MATRIX`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_PIDS_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_ALARM_RULE_ENGINE`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_EVENT_TYPES`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_FIBER_SPLICING`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_CHANNEL_INFO`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_REGION_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_STS_GRADE_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_EDGE_MANAGEMENT`,response.data);
					// localStorage.setItem(`${dashboardtype.toUpperCase()}_SHOW_NOTIFICATIONS`,response.data);
				} else {
					// dispatch({ type: UPDATE_SETTINGS_ERROR });
					Modal.warning({ title: langdata && langdata.Unabletoupdatethegeneralsettings ? langdata.Unabletoupdatethegeneralsettings : 'Unable to update the general settings.' });  
				}
			})
			.catch((error) => {
				console.log(error);
				// dispatch({ type: UPDATE_SETTINGS_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				else{
					Modal.warning({ title: langdata && langdata.Unabletoupdatethegeneralsettings ? langdata.Unabletoupdatethegeneralsettings : 'Unable to update the general settings.' });  
				}  
			});
	};

	const closeReloadModal = ()=>{
		setReloadModal(false);
	};

	const applyChanges = ()=>{
		navigate(0);
	};
		
	return (
		<>
			<Content>
				<Form>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.realtimeEventsEnabled} onClick={(value) => setFeatures({...features, realtimeEventsEnabled : value.target.checked })}>{langdata && langdata.RealtimeEvents ? langdata.RealtimeEvents : 'Realtime Events'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.realtimeAlarmsEnabled} onClick={(value) => setFeatures({...features, realtimeAlarmsEnabled : value.target.checked })}>{langdata && langdata.RealtimeAlarms ? langdata.RealtimeAlarms : 'Realtime Alarms'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.trackMyPIGEnabled} onClick={(value) => setFeatures({...features, trackMyPIGEnabled : value.target.checked })}>{langdata && langdata.MyPIG ? langdata.MyPIG : 'My PIG' } </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.trackMyLWEnabled} onClick={(value) => setFeatures({...features, trackMyLWEnabled : value.target.checked })}>{langdata && langdata.MyLinewalker ? langdata.MyLinewalker : 'My Linewalker'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.reportEventsEnabled} onClick={(value) => setFeatures({...features, reportEventsEnabled : value.target.checked })}>{langdata && langdata.ReportEvents ? langdata.ReportEvents : 'Report - Events'} </Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox  checked = {features.reportAlarmsEnabled} onClick={(value) => setFeatures({...features, reportAlarmsEnabled : value.target.checked })}>{langdata && langdata.ReportAlarms ? langdata.ReportAlarms :  'Report - Alarms'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.reportPatchCoverageEnabled}  onClick={(value) => setFeatures({...features, reportPatchCoverageEnabled : value.target.checked })}>{langdata && langdata.ReportSTSPatchCoverage ? langdata.ReportSTSPatchCoverage :  'Report - STS Patch Coverage'} </Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.reportVulnerableEnabled} onClick={(value) => setFeatures({...features, reportVulnerableEnabled : value.target.checked })}>{langdata && langdata.ReportSTSVulnerablePatchCoverage ? langdata.ReportSTSVulnerablePatchCoverage : 'Report - STS Vulnerable Patch Coverage'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.reportGradeSystemEnabled} onClick={(value) => setFeatures({...features, reportGradeSystemEnabled : value.target.checked })}>{langdata && langdata.ReportSTSGradeSystem ? langdata.ReportSTSGradeSystem : 'Report - STS Grade System'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{  margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.retrainingDataEnabled} onClick={(value) => setFeatures({...features, retrainingDataEnabled : value.target.checked })}>{langdata && langdata.RetrainingData ? langdata.RetrainingData : 'Retraining Data'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.retrainingSimulationsEnabled} onClick={(value) => setFeatures({...features, retrainingSimulationsEnabled : value.target.checked })}>{langdata && langdata.Simulations ? langdata.Simulations : 'Simulations'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.retrainingHistoryEnabled} onClick={(value) => setFeatures({...features, retrainingHistoryEnabled : value.target.checked })}>{langdata && langdata.RetrainingHistory ? langdata.RetrainingHistory : 'Retraining History'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{  margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.retrainingModelInfoEnabled} onClick={(value) => setFeatures({...features, retrainingModelInfoEnabled : value.target.checked })}>{langdata && langdata.ModelInfo ? langdata.ModelInfo : 'Model Info'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.retrainingModelConfigEnabled} onClick={(value) => setFeatures({...features, retrainingModelConfigEnabled : value.target.checked })}>{langdata && langdata.ModelConfiguration ? langdata.ModelConfiguration : 'Model Configuration'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.inspectionsEnabled} onClick={(value) => setFeatures({...features, inspectionsEnabled : value.target.checked })}>{langdata && langdata.STSInspections ? langdata.STSInspections : 'STS Inspections'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{  margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.eventAnalyticsEnabled} onClick={(value) => setFeatures({...features, eventAnalyticsEnabled : value.target.checked })}>{langdata && langdata.EventAnalytics ? langdata.EventAnalytics : 'Event Analytics'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.alarmAnalyticsEnabled} onClick={(value) => setFeatures({...features, alarmAnalyticsEnabled : value.target.checked })}>{langdata && langdata.AlarmAnalytics ? langdata.AlarmAnalytics : 'Alarm Analytics'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.chainageFilterEnabled} onClick={(value) => setFeatures({...features, chainageFilterEnabled : value.target.checked })}>{langdata && langdata.ChainageFilter ? langdata.ChainageFilter : 'Chainage Filter'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{  margin : '15px' }}>
						<Col span={8}>
							<Checkbox checked = {features.vulnerablePointsEnabled} onClick={(value) => setFeatures({...features, vulnerablePointsEnabled : value.target.checked })}>{langdata && langdata.VulnerablePoints ? langdata.VulnerablePoints : 'Vulnerable Points'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.snoozedPointsEnabled} onClick={(value) => setFeatures({...features, snoozedPointsEnabled : value.target.checked })}>{langdata && langdata.SnoozedPoints ? langdata.SnoozedPoints : 'Snoozed Points'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.terrainsEnabled} onClick={(value) => setFeatures({...features, terrainsEnabled : value.target.checked })}>{langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{  margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.realtimeCPSEnabled} onClick={(value) => setFeatures({...features, realtimeCPSEnabled : value.target.checked })}>{langdata && langdata.RealtimeCPS ? langdata.RealtimeCPS : 'Realtime CPS'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.fullCPSEnabled} onClick={(value) => setFeatures({...features, fullCPSEnabled : value.target.checked })}>{langdata && langdata.FullCPSReport ? langdata.FullCPSReport : 'Full CPS Report'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox  checked = {features.observeCPSEnabled} onClick={(value) => setFeatures({...features, observeCPSEnabled : value.target.checked })}>{langdata && langdata.ObserveCPSReport ? langdata.ObserveCPSReport : 'Observe CPS Report'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.dailyCPSEnabled} onClick={(value) => setFeatures({...features, dailyCPSEnabled : value.target.checked })}>{langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport : 'Daily CPS Report'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.cpsReportEnabled} onClick={(value) => setFeatures({...features, cpsReportEnabled : value.target.checked })}>{langdata && langdata.CPSReport ? langdata.CPSReport : 'CPS Report'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.allocationsEnabled} onClick={(value) => setFeatures({...features, allocationsEnabled : value.target.checked })}>{langdata && langdata.UserAllocation ? langdata.UserAllocation : 'User Allocation'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.userMgmtEnabled}  onClick={(value) => setFeatures({...features, userMgmtEnabled : value.target.checked })}>{langdata && langdata.UserManagement ? langdata.UserManagement : 'User Management'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.speedClassificationEnabled}  onClick={(value) => setFeatures({...features, speedClassificationEnabled : value.target.checked })}>{langdata && langdata.STSSpeedClassification ? langdata.STSSpeedClassification : 'STS Speed Classification'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.tlpMgmtEnabled} onClick={(value) => setFeatures({...features, tlpMgmtEnabled : value.target.checked })}>{langdata && langdata.TLPManagement ? langdata.TLPManagement : 'TLP Management'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.tlpRuleEngineEnabled} onClick={(value) => setFeatures({...features, tlpRuleEngineEnabled : value.target.checked })}>{langdata && langdata.TLPRules ? langdata.TLPRules :  'TLP Rules'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.mailConfigEnabled} onClick={(value) => setFeatures({...features, mailConfigEnabled : value.target.checked })}>{langdata && langdata.MailConfiguration ? langdata.MailConfiguration :  'Mail Configuration'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.escalationMatrixEnabled} onClick={(value) => setFeatures({...features, escalationMatrixEnabled : value.target.checked })}>{langdata && langdata.EscalationMatrix ? langdata.EscalationMatrix : 'Escalation Matrix'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.pidsMgmtEnabled} onClick={(value) => setFeatures({...features, pidsMgmtEnabled : value.target.checked })}>{langdata && langdata.PIDSManagement ? langdata.PIDSManagement : 'PIDS Management'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.alarmRuleEngineEnabled} onClick={(value) => setFeatures({...features, alarmRuleEngineEnabled : value.target.checked })}>{langdata && langdata.AlarmRuleEngine ? langdata.AlarmRuleEngine : 'Alarm Rule Engine'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.eventTypesEnabled} onClick={(value) => setFeatures({...features, eventTypesEnabled : value.target.checked })}>{langdata && langdata.EventTypes ? langdata.EventTypes : 'Event Types'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.fiberSplicingEnabled} onClick={(value) => setFeatures({...features, fiberSplicingEnabled : value.target.checked })}>{langdata && langdata.FiberSplicing ? langdata.FiberSplicing : 'Fiber Splicing'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.channelInfoEnabled} onClick={(value) => setFeatures({...features, channelInfoEnabled : value.target.checked })}>{langdata && langdata.ChannelInfo ? langdata.ChannelInfo :  'Channel Info'} </Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.regionMgmtEnabled} onClick={(value) => setFeatures({...features, regionMgmtEnabled : value.target.checked })}>{langdata && langdata.RegionManagement ? langdata.RegionManagement : 'Region Management'} </Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.gradeMgmtEnabled} onClick={(value) => setFeatures({...features, gradeMgmtEnabled : value.target.checked })}>{langdata && langdata.STSGradeManagement ? langdata.STSGradeManagement : 'STS Grade Management'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.edgeMgmtEnabled} onClick={(value) => setFeatures({...features, edgeMgmtEnabled : value.target.checked })}>{langdata && langdata.EdgeManagement ? langdata.EdgeManagement : 'Edge Management'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.notificationsEnabled} onClick={(value) => setFeatures({...features, notificationsEnabled : value.target.checked })}>{langdata && langdata.Notifications ? langdata.Notifications : 'Notifications'}</Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.wellVisualizationEnabled} onClick={(value) => setFeatures({...features, wellVisualizationEnabled : value.target.checked })}>{langdata && langdata.WellVisualization ? langdata.WellVisualization : 'Well Visualization'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellTypeManagementEnabled} onClick={(value) => setFeatures({...features, wellTypeManagementEnabled : value.target.checked })}>{langdata && langdata.WellTypeManagement ? langdata.WellTypeManagement : 'Well Type Management'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellFlowMonitoringEnabled} onClick={(value) => setFeatures({...features, wellFlowMonitoringEnabled : value.target.checked })}>{langdata && langdata.WellFlowMonitoring ? langdata.WellFlowMonitoring : 'Well Flow Monitoring'}</Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.wellSandIngressEnabled} onClick={(value) => setFeatures({...features, wellSandIngressEnabled : value.target.checked })}>{langdata && langdata.WellSandIngress ? langdata.WellSandIngress : 'Well Sand Ingress'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellLeakDetectionEnabled} onClick={(value) => setFeatures({...features, wellLeakDetectionEnabled : value.target.checked })}>{langdata && langdata.WellLeakDetection ? langdata.WellLeakDetection : 'Well Leak Detection'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellFracturingEnabled} onClick={(value) => setFeatures({...features, wellFracturingEnabled : value.target.checked })}>{langdata && langdata.WellFracturing ? langdata.WellFracturing : 'Well Fracturing'}</Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '15px'}}>
						<Col span={8}>
							<Checkbox checked = {features.wellMicroSeismicEnabled} onClick={(value) => setFeatures({...features, wellMicroSeismicEnabled : value.target.checked })}>{langdata && langdata.WellMicroseismicMonitoring ? langdata.WellMicroseismicMonitoring : 'Well Microseismic Monitoring'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellAlgorithmEnabled} onClick={(value) => setFeatures({...features, wellAlgorithmEnabled : value.target.checked })}>{langdata && langdata.WellAlgorithm ? langdata.WellAlgorithm : 'Well Algorithm'}</Checkbox>
						</Col>
						<Col span={8}>
							<Checkbox checked = {features.wellGraphsEnabled} onClick={(value) => setFeatures({...features, wellGraphsEnabled : value.target.checked })}>{langdata && langdata.WellGraphs ? langdata.WellGraphs : 'Well Graphs'}</Checkbox>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{ margin : '55px'}}>
						<Col span={24} style={{ display : 'flex', justifyContent : 'center'}}>
							<Button
								type='primary'
								onClick={handleSubmit}
							>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
						</Col>
                    
					</Row>
				</Form>
			</Content>
			<Modal
				title={langdata && langdata.Pleaseconfirm ? langdata.Pleaseconfirm : 'Please confirm'}
				centered
				destroyOnClose={true}
				open={openreloadmodal}
				onOk={{closeReloadModal}}
				onCancel={closeReloadModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeReloadModal}>{langdata && langdata.No ? langdata.No : 'No' }</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={()=>{applyChanges();}}>{langdata && langdata.Yes ? langdata.Yes : 'Yes'}</Button> : null }
					</Space>
				]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>
							{langdata && langdata.Featuresettingsupdatedsuccessfully ? langdata.Featuresettingsupdatedsuccessfully : 
								'Feature settings updated successfully. Do you want to apply the changes immediately?' }
						</Typography>
					</Col>
				</Row>
			</Modal>
			<Spin spinning={data.loading} fullscreen />
		</>
	);
}
