export const REGION_LIST = 'REGION_LIST';
export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS';
export const REGION_LIST_ERROR = 'REGION_LIST_ERROR';


export const DASHBOARD_LIST = 'DASHBOARD_LIST';
export const DASHBOARD_LIST_SUCCESS = 'DASHBOARD_LIST_SUCCESS';
export const DASHBOARD_LIST_ERROR = 'DASHBOARD_LIST_ERROR';

export const PIDS_STATUS = 'PIDS_STATUS';
export const PIDS_STATUS_SUCCESS = 'PIDS_STATUS_SUCCESS';
export const PIDS_STATUS_ERROR = 'PIDS_STATUS_ERROR';

export const PIDS_GRAPH = 'PIDS_GRAPH';
export const PIDS_GRAPH_SUCCESS = 'PIDS_GRAPH_SUCCESS';
export const PIDS_GRAPH_ERROR = 'PIDS_GRAPH_ERROR';

export const TLP_DASHBOARD_LIST = 'TLP_DASHBOARD_LIST';
export const TLP_DASHBOARD_LIST_SUCCESS = 'TLP_DASHBOARD_LIST_SUCCESS';
export const TLP_DASHBOARD_LIST_ERROR = 'TLP_DASHBOARD_LIST_ERROR';

export const STS_DASHBOARD_LIST = 'STS_DASHBOARD_LIST';
export const STS_DASHBOARD_LIST_SUCCESS = 'STS_DASHBOARD_LIST_SUCCESS';
export const STS_DASHBOARD_LIST_ERROR = 'STS_DASHBOARD_LIST_ERROR';

export const LOAD_DATALOSS_GRAPH = 'LOAD_DATALOSS_GRAPH';
export const LOAD_DATALOSS_GRAPH_SUCCESS = 'LOAD_DATALOSS_GRAPH_SUCCESS';
export const LOAD_DATALOSS_GRAPH_ERROR = 'LOAD_DATALOSS_GRAPH_ERROR';