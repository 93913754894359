import React, { useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';
import { useLocation, useNavigate } from 'react-router-dom';

import { Empty, Row, Col, Select, Typography, Space, Table, Spin, Button, Form, Modal, Input, Checkbox, Breadcrumb, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EditOutlined , HomeOutlined, ColumnWidthOutlined} from '@ant-design/icons';

import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { CHAINAGE_DETAILS, CHAINAGE_DETAILS_SUCCESS, CHAINAGE_DETAILS_ERROR } from './constants';
import { UPDATE_CHAINAGE, UPDATE_CHAINAGE_SUCCESS, UPDATE_CHAINAGE_ERROR } from './constants';

import chaingeFilterReducer from './reducer.js';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

import { useFormik } from 'formik';
import axios from 'axios';


const { Title } = Typography;

export default function ChainageListing() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { state } = useLocation();
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [regionId, setRegionId] = useState(state && state.region ? state.region : null);
	const [rouMarker, setRouMarker] = useState(null);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(state && state.start || state && state.start === 0 ? state.start : null);
	const [endCh, setEndCh] = useState(state && state.end || state && state.end === 0  ? state.end : null);
  
	const initalState = {chainages: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: false, error: false };
	const [data, dispatch] = useReducer(chaingeFilterReducer, initalState);

	const [showChainageModal, setShowChainageModal] = useState(false);
	const navigate = useNavigate();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;

	let regions = [ { value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'} ];
	let roumarkers = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}, 
		{ value: 'CH', label: langdata && langdata.CH ? langdata.CH : 'CH'},
		{ value: 'KMP', label: langdata && langdata.KMP ? langdata.KMP : 'KMP'},
		{ value: 'TP', label: langdata && langdata.TP ? langdata.TP : 'TP'},
		{ value: 'TLP', label: langdata && langdata.TLP ? langdata.TLP : 'TLP'},
		{ value: 'WB', label: langdata && langdata.WB ? langdata.WB : 'WB'}
	];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	let columns = [];

	permission === 'superadmin' || permission === 'admin' ? 
		columns = [
			{
				title: langdata && langdata.PipelineChainage ? langdata.PipelineChainage : 'Pipeline Chainage',
				dataIndex: 'pipelineChainage',
				key: 'pipelineChainage',
			},
			{
				title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
				dataIndex: 'ODMeter',
				key: 'ODMeter',
			},
			{
				title: langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker',
				dataIndex: 'rouMarker',
				key: 'rouMarker'
			},
			{
				title: langdata && langdata.Latitude ? langdata.Latitude : 'Latitude',
				dataIndex: 'latitude',
				key: 'latitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Longitude ? langdata.Longitude : 'Longitude',
				dataIndex: 'longitude',
				key: 'longitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
				key: 'action',
				render: () => (
					permission === 'superadmin' || permission === 'admin' ? (
						<Space size="middle">
							<a title="View Chainage" onClick={openChainageModal}><EditOutlined /></a>
						</Space>
					) : (
						null
					) 
				)
			}
		] :
		columns = [
			{
				title: langdata && langdata.PipelineChainage ? langdata.PipelineChainage : 'Pipeline Chainage',
				dataIndex: 'pipelineChainage',
				key: 'pipelineChainage',
			},
			{
				title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
				dataIndex: 'ODMeter',
				key: 'ODMeter',
			},
			{
				title: langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker',
				dataIndex: 'rouMarker',
				key: 'rouMarker'
			},
			{
				title: langdata && langdata.Latitude ? langdata.Latitude : 'Latitude',
				dataIndex: 'latitude',
				key: 'latitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Longitude ? langdata.Longitude : 'Longitude',
				dataIndex: 'longitude',
				key: 'longitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			}
		];

	const openChainageModal = () => {
		setShowChainageModal(true);
	};

	const closeChainageModal = () => {
		setShowChainageModal(false);
	};

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if (admin_roles.includes(permission)) {
			getRegion(authtoken);
		} else{
			getUserAllocation(authtoken);
		}

		loadChainages(1);
	}, [allocationId, regionId, rouMarker]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleSetRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		
		if (admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const handleSetROUMarker = (e) => {
		setRouMarker(e);
	};

	if (admin_roles.includes(permission)) {
		regiondata && regiondata.map((region) => {
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region) => {
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}
  
	const loadChainages = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		let filterByregionIdPart = '';
		let filterByROUMarkerPart = '';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${state.region ? state.region : regionId}`;
		} else {
			filterByregionIdPart = '';
		}
		
		if (rouMarker && rouMarker !== null) {
			filterByROUMarkerPart = `&rouMarker=${rouMarker}`;
		} else {
			filterByROUMarkerPart = '';
		}
		const pipelineParams = `&pipelineId=${pipelineId}`;

		let url = `${api}/pipelineconfig?$limit=${_pagination.limit}&$skip=${_pagination.skip}${filterByregionIdPart}${filterByROUMarkerPart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1${pipelineParams}`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		dispatch( { type: CHAINAGES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: CHAINAGES_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: CHAINAGES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: CHAINAGES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadChainageDetails = ((id) => {
		let details = null;

		dispatch({ type: CHAINAGE_DETAILS });
    
		if (data && data.chainages) {
			data.chainages && data.chainages.map((item) => {
				if (item.id === id) {
					details = item;
				}
				return true;
			});

			dispatch({ type: CHAINAGE_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: CHAINAGE_DETAILS_ERROR });
		}
	});

	const updateChainageFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: data.details ? data.details.id : null,
			pipelineChainage: data.details ? data.details.pipelineChainage : null,
			rouMarker: data.details ? data.details.rouMarker : '',
			latitude: data.details ? data.details.latitude : null,
			longitude: data.details ? data.details.longitude : null,
			vulnerablePoint: data.details ? data.details.vulnerablePoint : false,
			isSnoozed: data.details ? data.details.isSnoozed : false,
		},
		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const rouMarker = values.rouMarker ? values.rouMarker : '';
			const isSnoozed = values.isSnoozed ? values.isSnoozed : false;
			const vulnerablePoint = values.vulnerablePoint ? values.vulnerablePoint : false;

			if (!rouMarker || rouMarker == null) {
				Modal.warning({ title: langdata && langdata.PleaseprovideavalidROUmarkername ? langdata.PleaseprovideavalidROUmarkername : 'Please provide a valid ROU marker name.' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/pipelineconfig/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					rouMarker: rouMarker,
					isSnoozed: isSnoozed,
					vulnerablePoint: vulnerablePoint
				}
			};

			dispatch( { type: UPDATE_CHAINAGE });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_CHAINAGE_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Chainagedetailsupdatedsuccessfully ? langdata.Chainagedetailsupdatedsuccessfully : 'Chainage details updated successfully.' });
						closeChainageModal();
						loadChainages({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: UPDATE_CHAINAGE_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatethechainagedetails ? langdata.Unabletoupdatethechainagedetails : 'Unable to update the chainage details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_CHAINAGE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				<Row 
					style={{
						marginLeft:'15px',
						marginTop: '5px',
					}}            
				>
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title:  <a onClick={()=>{navigate(-1);}}>{ langdata && langdata.ChainageFilter ? langdata.ChainageFilter : 'Chainage Filter'}</a> 
							},
							{
								title: langdata && langdata.Chainage ? langdata.Chainage : 'Chainage',
							},
						]}
					/>
				</Row>
				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={5}>
						<Row>
							<Space>
								<ColumnWidthOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.Chainages ? langdata.Chainages : 'Chainages'}</Title>
						</Row>
					</Col>
					<Col style ={{justifyContent:'right', display:'flex'}} span={19} >
						<Row >
							<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
								<Space>
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'} >
										<Select
											style={{ width: '200px', margin : '5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={regionId}
											disabled = {state && state.start || state && state.start === 0 || state.region ? true : false}
											options={regions}
											onSelect={(e, options)=>handleSetRegion(e, options)}
										/>
									</Tooltip>
								</Space>
							</Tooltip>
							<Space>&nbsp;&nbsp;</Space>
							<Tooltip placement="bottom" title={langdata && langdata.FilterbyROUmarker ? langdata.FilterbyROUmarker : 'Filter by ROU marker'}>
								<Space>
									<Select
										style={{ width: '200px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectROUmarker ? langdata.SelectROUmarker : 'Select ROU marker'}
										optionFilterProp="children"
										defaultValue={null}
										options={roumarkers}
										onSelect={(e, options)=>handleSetROUMarker(e, options)}
									/>
								</Space>
							</Tooltip>
							<Space>&nbsp;&nbsp;</Space>
						</Row>
					</Col>
				</Row>
				
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.chainages && data.chainages.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} 
								dataSource={data.chainages} 
								pagination = { data.chainages.length > 0 ? data.pagination : 0 }
								onChange = { loadChainages }
								onRow = {(record) => {
									return {
										onClick: () => { 
											loadChainageDetails(record.id);
										}
									};
								}} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />

			{/* Chainage Details Modal Starts */}
			<Modal
				title={langdata && langdata.ChainageDetails ? langdata.ChainageDetails : 'Chainage Details'}
				centered
				destroyOnClose={true}
				open={showChainageModal}
				onOk={{closeChainageModal}}
				onCancel={closeChainageModal}
				footer={[
					<Space key ='options'>
						<Button onClick={closeChainageModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={updateChainageFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={updateChainageFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} required>
								<Input name="pipelineChainage" placeholder={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} defaultValue={ updateChainageFormik.values.pipelineChainage } value={ updateChainageFormik.values.pipelineChainage } onChange={updateChainageFormik.handleChange}  />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker'} required>
								<Select
									showSearch
									placeholder={langdata && langdata.SelectROUmarker ? langdata.SelectROUmarker : 'Select ROU Marker'}
									optionFilterProp="children"
									options={roumarkers}
									onChange={(value) => updateChainageFormik.setFieldValue('rouMarker', value)}
									filterOption={filterOption}
									value={ updateChainageFormik.values.rouMarker }
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} required>
								<Input name="latitude" placeholder={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} defaultValue={ updateChainageFormik.values.latitude } value={ updateChainageFormik.values.latitude } onChange={updateChainageFormik.handleChange}  />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} required>
								<Input name="longitude" placeholder={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} defaultValue={ updateChainageFormik.values.longitude } value={ updateChainageFormik.values.longitude } onChange={updateChainageFormik.handleChange}  />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable?'} >
								<Checkbox name="vulnerablePoint" placeholder={langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable?'} checked={ updateChainageFormik.values.vulnerablePoint } value={ updateChainageFormik.values.vulnerablePoint } onChange={updateChainageFormik.handleChange} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.IsSnoozed ? langdata.IsSnoozed : 'Is Snoozed?'} >
								<Checkbox name="isSnoozed" placeholder={langdata && langdata.IsSnoozed ? langdata.IsSnoozed : 'Is Snoozed?'} checked={ updateChainageFormik.values.isSnoozed } value={ updateChainageFormik.values.isSnoozed } onChange={updateChainageFormik.handleChange} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Chainage Details Modal Ends */}
		</>
	);
}
