/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

import { Card, Col, Empty, message, Radio, Row, Spin, Table, Tag } from 'antd';
import { useEffect, useReducer, useState } from 'react';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import Plot from 'react-plotly.js';
import axios from 'axios';
import reportWellReducer from './reducer';
import { DAS_COUNT_GRAPH_INJECTION_AMP_DATA, DAS_COUNT_GRAPH_INJECTION_AMP_ERROR, DAS_COUNT_GRAPH_INJECTION_AMP_SUCCESS, DAS_GRAPH_INJECTION_AMP_DATA, DAS_GRAPH_INJECTION_AMP_ERROR, DAS_GRAPH_INJECTION_AMP_SUCCESS, DAS_LIST_INJECTION_AMP_DATA, DAS_LIST_INJECTION_AMP_ERROR, DAS_LIST_INJECTION_AMP_SUCCESS, DTS_COUNT_GRAPH_INJECTION_TEMP_DATA, DTS_COUNT_GRAPH_INJECTION_TEMP_ERROR, DTS_COUNT_GRAPH_INJECTION_TEMP_SUCCESS, DTS_GRAPH_INJECTION_TEMP_DATA, DTS_GRAPH_INJECTION_TEMP_ERROR, DTS_GRAPH_INJECTION_TEMP_SUCCESS, DTS_LIST_INJECTION_TEMP_DATA, DTS_LIST_INJECTION_TEMP_ERROR, DTS_LIST_INJECTION_TEMP_SUCCESS } from './constants';
import {FullscreenOutlined} from '@ant-design/icons';


export default function Injection(props){
	const {onClick} = props;
	const viewType = props.viewType;
	const graphScroll = props.graphScroll;
	const wellFrom = props.wellFrom;
	const wellTo = props.wellTo;

	const wellId = props.wellId;
	const channelId = props.channelId;
	const startDate = props.startDate;
	const endDate = props.endDate;
	const fullsize = props.fullsize;


	const [view, setView] = useState(props.view ? props.view : 'List');

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const initalState = {details: null, pending: false, error: false, dts_injection_temp_data:[], das_injection_amp_data:[], dts_list_injection_temp_data:[], das_list_injection_amp_data:[],
		das_injection_count_amp_data:[], dts_injection_count_temp_data:[], 
		dasinjectionlistpending: false, dasinjectionheatmappending: false, dasinjectioncountpending:false,
		dtsinjectionlistpending: false, dtsinjectionheatmappending: false, dtsinjectioncountpending:false
	};
	const [data, dispatch] = useReducer(reportWellReducer, initalState);


	useEffect(() => {

		if(view=='List'){
			if(viewType=='DAS'){
				loadInjectionDAS();                
			}
			else{
				loadInjectionDTS();                

			}
		}else if (view=='Heatmap'){
			if(viewType=='DAS'){
				getWellAmplitudedataDAS();

			}
			else{
				getWellTempdataDTS();

			}
		}
		else{
			if(viewType=='DAS'){
				wellAmplitudeInjectionCountDAS();
			}
			else{
				wellAmplitudeInjectionCountDTS();
			}
		}

	},[wellId,endDate, startDate, channelId, props.viewType, view]);	


	const loadInjectionDAS = () => {
		dispatch({ type : DAS_LIST_INJECTION_AMP_DATA });
		let params = '';
		// let params='anomaly_type=Hydraulic Injection';

		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/wellreportsdas-list?anomaly_type[]=Hydraulic Injection${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : DAS_LIST_INJECTION_AMP_SUCCESS, payload : response});
				} else {
					dispatch({ type : DAS_LIST_INJECTION_AMP_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : DAS_LIST_INJECTION_AMP_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};


	const loadInjectionDTS = () => {
		dispatch({ type : DTS_LIST_INJECTION_TEMP_DATA });
		let params = '';
		// let params='anomaly_type=Hydraulic Injection';

		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/wellreportsdts-list?anomaly_type[]=Hydraulic Injection${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : DTS_LIST_INJECTION_TEMP_SUCCESS, payload : response});
				} else {
					dispatch({ type : DTS_LIST_INJECTION_TEMP_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : DTS_LIST_INJECTION_TEMP_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};


	const getWellTempdataDTS =()=>{
		dispatch({ type : DTS_GRAPH_INJECTION_TEMP_DATA});

		let params='anomaly_type=Hydraulic Injection';
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		params += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellreportsdts?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DTS_GRAPH_INJECTION_TEMP_SUCCESS, payload : response.data.data});
				}else{
					dispatch({ type : DTS_GRAPH_INJECTION_TEMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DTS_GRAPH_INJECTION_TEMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getWellAmplitudedataDAS =()=>{
		dispatch({ type : DAS_GRAPH_INJECTION_AMP_DATA});

		let params='anomaly_type=Hydraulic Injection';
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		params += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellreportsdas?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DAS_GRAPH_INJECTION_AMP_SUCCESS, payload : response.data.data});
				}else{
					dispatch({ type : DAS_GRAPH_INJECTION_AMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_GRAPH_INJECTION_AMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};



	const wellAmplitudeInjectionCountDTS =()=>{
		dispatch({ type : DTS_COUNT_GRAPH_INJECTION_TEMP_DATA});

		let params='anomaly_type=Hydraulic Injection';
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		params += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalysdtsCount?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DTS_COUNT_GRAPH_INJECTION_TEMP_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DTS_COUNT_GRAPH_INJECTION_TEMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DTS_COUNT_GRAPH_INJECTION_TEMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const wellAmplitudeInjectionCountDAS =()=>{
		dispatch({ type : DAS_COUNT_GRAPH_INJECTION_AMP_DATA});

		let params='anomaly_type=Hydraulic Injection';
		if(wellId){
			params += `&wellinfoId=${wellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId){
			params += `&channelId=${channelId}`;
		}
		params += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalysdasCount?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DAS_COUNT_GRAPH_INJECTION_AMP_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DAS_COUNT_GRAPH_INJECTION_AMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_COUNT_GRAPH_INJECTION_AMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};



	let dtsshapes = [];
	// let datetime1 = ['2024-09-13T06:59:59.9999Z', '2024-09-13T07:00:00.000Z'];
	data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_datetime && data.dts_injection_temp_data.anomaly_datetime.map((item, index) => {
		dtsshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_datetime && data.dts_injection_temp_data.anomaly_datetime[index],
				y0: 0,
				x1: data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_datetime && data.dts_injection_temp_data.anomaly_datetime[index],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_datetime && data.dts_injection_temp_data.anomaly_datetime[index],
		
				y0: 0,
		
				x1: data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_datetime && data.dts_injection_temp_data.anomaly_datetime[index],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: data && data.dts_injection_temp_data && data.dts_injection_temp_data.anomaly_type && data.dts_injection_temp_data.anomaly_type[index],
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

		);});


	let dasshapes = [];
	data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_datetime && data.das_injection_amp_data.anomaly_datetime.map((item, index) => {
		dasshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_datetime && data.das_injection_amp_data.anomaly_datetime[index],
				y0: 0,
				x1: data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_datetime && data.das_injection_amp_data.anomaly_datetime[index],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_datetime && data.das_injection_amp_data.anomaly_datetime[index],
		
				y0: 0,
		
				x1: data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_datetime && data.das_injection_amp_data.anomaly_datetime[index],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: data && data.das_injection_amp_data && data.das_injection_amp_data.anomaly_type && data.das_injection_amp_data.anomaly_type[index],
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

		);});



	const dtsHeatMap = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.HydraulicInjectionHistory ? langdata.HydraulicInjectionHistory + ' DTS' : 'Hydraulic Injection History DTS',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time', type: 'date' },
		yaxis: { 
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth', 
			// autorange: 'reversed',  
			range: wellFrom || wellFrom == 0 && wellTo || wellTo == 0 ? [wellTo , wellFrom ] : [],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,

		},
		shapes : dtsshapes,
		height: graphScroll == true ? 10000 :0,		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};	


	const dasHeatMap = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.HydraulicInjectionHistory ? langdata.HydraulicInjectionHistory + ' DAS' : 'Hydraulic Injection History DAS',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time', type: 'date' },
		yaxis: { 
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth', 
			// autorange: 'reversed',  
			range: wellFrom || wellFrom == 0 && wellTo || wellTo == 0 ? [wellTo , wellFrom ] : [],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		shapes : dasshapes,
		height: graphScroll == true ? 10000 :0,		// paper_bgcolor: 'transparent', // Remove background color of the entire chart

		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};

	const dasAnomalyHeatMap = [{
		z: data && data.das_injection_amp_data && data.das_injection_amp_data && data.das_injection_amp_data.avg_depth_amps ? data.das_injection_amp_data.avg_depth_amps : [],
		x:  data && data.das_injection_amp_data && data.das_injection_amp_data && data.das_injection_amp_data.dates_arrays ? data.das_injection_amp_data.dates_arrays : [],
		y:  data && data.das_injection_amp_data && data.das_injection_amp_data && data.das_injection_amp_data.depth_arrays ? data.das_injection_amp_data.depth_arrays : [],
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		// reversescale:true
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},

		orientation: graphScroll == true ? 'h' : ''


	},
	];	

	const dtsAnomalyHeatMap = [{

		z: data && data.dts_injection_temp_data && data.dts_injection_temp_data && data.dts_injection_temp_data.avg_depth_temps ? data.dts_injection_temp_data.avg_depth_temps : [],
		x:  data && data.dts_injection_temp_data && data.dts_injection_temp_data && data.dts_injection_temp_data.dates_arrays ? data.dts_injection_temp_data.dates_arrays : [],
		y:  data && data.dts_injection_temp_data && data.dts_injection_temp_data && data.dts_injection_temp_data.depth_arrays ? data.dts_injection_temp_data.depth_arrays : [],
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true,
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},

		orientation: graphScroll == true ? 'h' : ''

	}];


	const columns = [
		{
			title: `${langdata && langdata.WellName ? langdata.WellName:'Well Name'}`,
			dataIndex: 'wellname',
			key: 'wellname',
			//   render: (_,text) => <a onClick={showEditDrawer}>{text}</a>

		},
		{
			title: `${langdata && langdata.ChannelName ? langdata.ChannelName:'Channel Name'}`,
			dataIndex: 'channelname',
			key: 'channelname',
		},
		{
			title: `${langdata && langdata.DateTime ? langdata.DateTime:'Date Time'}`,
			dataIndex: 'datetime',
			key: 'datetime',
		},
		{
			title: `${langdata && langdata.Events ? langdata.Events:'Events'}`,
			dataIndex: 'anomaly_type',
			key: 'anomaly_type',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={'red'}>
							{status.anomaly_type	}
						</Tag>}
				</>
			),
		},
		// {
		// 	title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
		// 	key: 'action',
		// 	align:'center',
		// 	render: (_, record) => (
		// 		<Space style={{justifyContent:'center',display:'flex'}} size="middle">
		// 			<Button title={langdata && langdata.DeleteFractreMonitoring ? langdata.DeleteFractreMonitoring:'Delete Fractre Monitoring'} type='link' disabled = {record.status == 'New' ? false : true}  onClick={() => {handleDeleteMicroSeismic(record);}}><DeleteOutlined/></Button>
		// 		</Space>
		// 	)
		// }
    
	];


	// count graph 


	const dasCountGraphLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.InjectionEventsCount ? langdata.InjectionEventsCount + ' DAS' : 'Injection Events Count DAS',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Events ? langdata.Events : 'Events',
		},
	};


	const dtsCountGraphLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.InjectionEventsCount ? langdata.InjectionEventsCount + ' DTS' : 'Injection Events Count DTS',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Events ? langdata.Events : 'Events',
		},
	};



	const dascountGraph = [{
		// z: data && data.das_microseismic_amp_data && data.das_microseismic_amp_data && data.das_microseismic_amp_data.avg_depth_amps ? data.das_microseismic_amp_data.avg_depth_amps : [],
		x:  data && data.das_injection_count_amp_data && data.das_injection_count_amp_data && data.das_injection_count_amp_data.hour ? data.das_injection_count_amp_data.hour : [],
		y:  data && data.das_injection_count_amp_data && data.das_injection_count_amp_data && data.das_injection_count_amp_data.event_count ? data.das_injection_count_amp_data.event_count : [],
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		// colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'scatter',
		mode: 'lines+markers',
		line: { color: '#17BECF' }
		// reversescale:true
		// hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		// colorbar: {x: 1,   thickness: 10,
		// 	// thicknessmode: 'pixels',
		// 	// lenmode: 'fraction',
		// 	outlinewidth: 0},

		// orientation: graphScroll == true ? 'h' : ''


	},
	];		

	
	const dtscountGraph = [{
		// z: data && data.das_microseismic_amp_data && data.das_microseismic_amp_data && data.das_microseismic_amp_data.avg_depth_amps ? data.das_microseismic_amp_data.avg_depth_amps : [],
		x:  data && data.dts_injection_count_temp_data && data.dts_injection_count_temp_data && data.dts_injection_count_temp_data.hour ? data.dts_injection_count_temp_data.hour : [],
		y:  data && data.dts_injection_count_temp_data && data.dts_injection_count_temp_data && data.dts_injection_count_temp_data.event_count ? data.dts_injection_count_temp_data.event_count : [],
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		// colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'scatter',
		mode: 'lines+markers',
		line: { color: '#b32d1e' }
		// reversescale:true
		// hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		// colorbar: {x: 1,   thickness: 10,
		// 	// thicknessmode: 'pixels',
		// 	// lenmode: 'fraction',
		// 	outlinewidth: 0},

		// orientation: graphScroll == true ? 'h' : ''


	},
	];	

	const handlefullSize = (size,graph) =>{
		if(viewType=='DAS'){
			if(graph=='injectionCountdas'){
				onClick(size, graph, dascountGraph,dasCountGraphLayout, 2);

			}else if(graph=='dasHeatMap')
				onClick(size, graph, dasAnomalyHeatMap, dasHeatMap, 2);
		}
		else{
			if(graph=='injectionCountdts'){
				onClick(size, graph, dtscountGraph,dtsCountGraphLayout, 2);

			}else if(graph=='dtsHeatMap'){

				onClick(size, graph, dtsAnomalyHeatMap, dtsHeatMap, 2);
			}
		}
	};

	return(

		<>
			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}}
			style={{
				margin: '10px',
				marginBottom:'0px',
				marginTop:'10px',
				// paddingLeft: '10px',
				// paddingRight: '10px',
				padding:'10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col className="gutter-row" span={8}>
					<Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
						<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
						<Radio.Button value="Heatmap">{langdata && langdata.Heatmap ? langdata.Heatmap:'Heatmap'}</Radio.Button>
						<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
					</Radio.Group>
				</Col>
				<Col span={16}>
					&nbsp;
				</Col>
			</Row>

			{viewType=='DAS' ?
				<>

					{view && view === 'List' ?
						<Row span={24} style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >

							<Col span={24} style={{ margin : '10px'}}>
								<Spin spinning={data.dasinjectionlistpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
							
									{data && data.das_list_injection_amp_data && data.das_list_injection_amp_data.length > 0 ?
										<Table 
											scroll={{
												x: 900,
											}}
											columns={columns} dataSource={data.das_list_injection_amp_data}/>
										: 
										(
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)
									}
								</Spin>
							</Col>
						</Row>

						: view && view =='Heatmap' ?

							<Card style={{ minHeight: '450px', margin: '10px', padding : '5px' }}>
								<Row style={{
								// margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={24} style={{ minHeight : '450px'}}>
										<Spin spinning={data.dasinjectionheatmappending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '5px', marginRight : '5px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasHeatMap');}}
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={dasAnomalyHeatMap}
													layout={ dasHeatMap }
													style={{ width: '100%', height: '450px', minHeight : '450px' }}
													// onClick={(value) => {handleDTSClick(value, 'das');}}
													config={{ responsive: true, displaylogo : false }}

												/>
											</Col>
										</Spin>
									</Col>
								</Row>
							</Card>
							:
							<Card style={{ minHeight: '450px', margin: '10px', padding : '5px' }}>
								<Row style={{
									// margin: '10px',
									// marginBottom : '0px',
									// marginTop : '10px',
									// paddingLeft: '10px',
									// paddingRight: '10px',
									// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={24} style={{ minHeight : '450px'}}>
										<Spin spinning={data.dasinjectioncountpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '5px', marginRight : '5px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'injectionCountdas');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Col>
												<Plot
													data={dascountGraph}
													layout={ dasCountGraphLayout }
													style={{ width: '100%', height: '450px', minHeight : '450px' }}
													// onClick={(value) => {handleDTSClick(value, 'das');}}
													config={{ responsive: true, displaylogo : false }}
												/>
											</Col>
										</Spin>
									</Col>
								</Row>
							</Card>
					}
				</>
				:
				viewType=='DTS' ?
					<>
						{view && view === 'List' ?
							<Row span={24} style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems:'center'
							}} >
								<Col span={24} style={{ margin : '10px'}}>
									<Spin spinning={data.dtsinjectionlistpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>

								
										{data && data.dts_list_injection_temp_data && data.dts_list_injection_temp_data.length > 0 ?
											<Table 
												scroll={{
													x: 900,
												}}
												columns={columns} dataSource={data.dts_list_injection_temp_data}/>
											: 
											(
												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
											)
										}
									</Spin>
								</Col>
							</Row> :
							view && view =='Heatmap' ?
								<Card style={{ minHeight: '450px', margin: '10px', padding : '5px' }}>
									<Row style={{
										// margin: '10px',
										// marginBottom : '0px',
										// marginTop : '10px',
										// paddingLeft: '10px',
										// paddingRight: '10px',
										// backgroundColor: '#ffffff',
										alignItems:'center',
									}} >
										<Col span={24} style={{ minHeight : '450px'}}>
											<Spin spinning={data.dtsinjectionheatmappending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
												<button
													style={{ marginTop : '5px', marginRight : '5px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsHeatMap');}}
													className="graphZoomButton" ><FullscreenOutlined /></button>
												<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
													<Plot
														data={dtsAnomalyHeatMap}
														layout={ dtsHeatMap}
														style={{ width: '100%', height: '450px', minHeight : '450px' }}
														// onClick={(value) => {handleDTSClick(value, 'das');}}
														config={{ responsive: true, displaylogo : false }}

													/>
												</Col>
											</Spin>
										</Col>
									</Row>
								</Card>
								:

								<Card style={{ minHeight: '450px', margin: '10px', padding : '5px' }}>
									<Row style={{
										// margin: '10px',
										// marginBottom : '0px',
										// marginTop : '10px',
										// paddingLeft: '10px',
										// paddingRight: '10px',
										// backgroundColor: '#ffffff',
										alignItems:'center',
									}} >
										<Col span={24} style={{ minHeight : '450px'}}>
											<Spin spinning={data.dtsinjectioncountpending} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
												<button
													style={{ marginTop : '5px', marginRight : '5px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'injectionCountdts');}} 
													className="graphZoomButton" ><FullscreenOutlined /></button>
												<Col>
													<Plot
														data={dtscountGraph}
														layout={ dtsCountGraphLayout }
														style={{ width: '100%', height: '450px', minHeight : '450px' }}
														// onClick={(value) => {handleDTSClick(value, 'das');}}
														config={{ responsive: true, displaylogo : false }}
													/>
												</Col>
											</Spin>
										</Col>
									</Row>
								</Card>
						}

					</>
					:
					<>
						<Row span={24} style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24} style={{ margin : '10px'}}>
						
								{/* <Table 
								scroll={{
									x: 900,
								}}
							// columns={columns} dataSource={realtimeAlarmList}
							// pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
							/> */}
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</Col>
						</Row> 
					

					</>
			}
		</>
	);
}