/* eslint-disable react/prop-types */
import { Badge, Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Spin, Switch, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import axios from 'axios';
import wellGraphsReducer from './reducer';
import { WELL_GRAPH_LIST_ERROR, WELL_GRAPH_LIST_SUCCESS, WELL_GRAPH_UPDATE_DATA, WELL_GRAPH_LIST } from './constants';

// const { Title } = Typography;

export default function LeakDetection(props) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	// const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const initalState = { loading: false, error: false, sandIngressData: [], updateData: {}, dasData: {} };
	const [data, dispatch] = useReducer(wellGraphsReducer, initalState);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid == 1 ? arabiclan : langid == 2 ? englan : langid == 3 ? frenchlan : langid == 4 ? germanlan : langid == 5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const { wellinfo, getWellInfo, wellalgorithmdata, getWellAlgorithm } = useAuth();

	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [updateId, setUpdateId] = useState();
	const [dasModal, setDasModal] = useState(false);
	const [dtsModal, setDtsModal] = useState(false);
	const [pressureModal, setPressureModal] = useState(false);

	useEffect(() => {
		setAddModal(props.modal);
	}, [props.modal]);

	useEffect(() => {
		getWellInfo(authtoken);
		getWellAlgorithm(authtoken);
	}, [authtoken]);

	const welldata = [];
	wellinfo && wellinfo.map((item) => {
		welldata.push({
			value: item.id,
			label: item.name.toUpperCase(),
		});
	});

	const algoritham = [];
	wellalgorithmdata && wellalgorithmdata.data.map((item) => {
		algoritham.push({
			value: item.id,
			label: item.name
		});
	});


	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<text>{record.wellinfo.name}</text>
			)
		},
		{
			title: langdata && langdata.Algorithm ? langdata.Algorithm : 'Algorithm',
			dataIndex: 'algorithm',
			key: 'algorithm',
			render: (_, record) => (
				<text>{record.wellalgorithm.name}</text>
			)
		},
		{
			title: langdata && langdata.System ? langdata.System : 'System',
			key: 'system',
			render: (_, record) => (
				<Space size="middle">
					<Space style={{ justifyContent: '', display: 'flex' }} size="middle">
						<>
							<Button style={{ display: 'contents', color: '#1FA8FF' }} onClick={() => { handleDasData(record); }}>{langdata && langdata.DAS ? langdata.DAS : 'DAS'}</Button>
							| <Button style={{ display: 'contents', color: '#1FA8FF' }} onClick={() => { handleDtsData(record); }}>{langdata && langdata.DTS ? langdata.DTS : 'DTS'}</Button>
							| <Button style={{ display: 'contents', color: '#1FA8FF' }} onClick={() => { handlePressureData(record); }}>{langdata && langdata.Pressure ? langdata.Pressure : 'Pressure'}</Button>
						</>
					</Space>
				</Space>
			)
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.EditLeakDetection ? langdata.EditLeakDetection : 'Edit Leak Detection'} onClick={() => { handleEditData(record); }}><EditOutlined /></a>
					{permission === 'superadmin' ?
						<a title={langdata && langdata.DeleteLeakDetection ? langdata.DeleteLeakDetection : 'Delete Leak Detection'} onClick={() => { openDeleteModal(record); }} ><DeleteOutlined /></a>
						: null}
				</Space>
			)
		}
	];

	const handleDasData = (value) => {
		setDasModal(true);
		dispatch({ type: WELL_GRAPH_UPDATE_DATA, payload: value });
		setUpdateId(value.id);
	};

	const handleDtsData = (value) => {
		setDtsModal(true);
		dispatch({ type: WELL_GRAPH_UPDATE_DATA, payload: value });
		setUpdateId(value.id);
	};

	const handlePressureData = (value) => {
		setPressureModal(true);
		dispatch({ type: WELL_GRAPH_UPDATE_DATA, payload: value });
		setUpdateId(value.id);
	};

	useEffect(() => {
		loadSandingress();
	}, []);

	const openDeleteModal = (value) => {
		setDeleteId(value.id);
		setDeleteModal(true);
	};

	const loadSandingress = () => {
		dispatch({ type: WELL_GRAPH_LIST });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/leakdetectionconfig`,
			timeout: timeout,
			headers: {
				Authorization: `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if (response.status == 200) {
					dispatch({ type: WELL_GRAPH_LIST_SUCCESS, payload: response.data.data });
				} else {
					dispatch({ type: WELL_GRAPH_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: WELL_GRAPH_LIST_ERROR });
				if (error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration: 5,
						maxCount: 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const handleEditData = (value) => {
		setUpdateId(value.id);
		dispatch({ type: WELL_GRAPH_UPDATE_DATA, payload: value });
		setEditModal(true);
	};

	const addFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			WellId: null,
			algorithamId: null,
			sampleRate: null,
			dasIp: null,
			dasPort: null,
			minAmpThreshold: null,
			maxAmpThreshold: null,
			minAmpFreqThresholds: null,
			maxAmpFreqThresholds: null,
			dtsIp: null,
			dtsPort: null,
			minTempThreshold: null,
			maxTempThreshold: null,
			minTempFreqThresholds: null,
			maxTempFreqThresholds: null,
			pressureip: null,
			pressureport: null,
			minPressureThreshold: null,
			maxPressureThreshold: null,
			minPressFreqThresholds: null,
			maxPressFreqThresholds: null,
			dasSocketurl: null,
			dtsSocketurl: null,
			pressSocketurl: null,
			dasSocketurlEnabled: false,
			dtsSocketurlEnabled: false,
			pressSocketurlEnabled: false
		},
		onSubmit: (values) => {

			const { WellId, algorithamId, sampleRate, dasIp, dasPort, minAmpThreshold, maxAmpThreshold, minAmpFreqThresholds, maxAmpFreqThresholds, dtsIp, dtsPort,
				minTempThreshold, maxTempThreshold, minTempFreqThresholds, maxTempFreqThresholds, pressureip, pressureport, minPressureThreshold, maxPressureThreshold,
				minPressFreqThresholds, maxPressFreqThresholds, dasSocketurl, dtsSocketurl, pressSocketurl, dasSocketurlEnabled, dtsSocketurlEnabled, pressSocketurlEnabled } = values;
			if (WellId == null || algorithamId == null || sampleRate == null || dasIp == null || dasPort == null || minAmpThreshold == null ||
				maxAmpThreshold == null || minAmpFreqThresholds == null || maxAmpFreqThresholds == null ||
				dtsIp == null || dtsPort == null || minTempThreshold == null || maxTempThreshold == null ||
				minTempFreqThresholds == null || maxTempFreqThresholds == null || pressureip == null || pressureport == null || minPressureThreshold == null || maxPressureThreshold == null ||
				minPressFreqThresholds == null || maxPressFreqThresholds == null, dasSocketurl == null || dtsSocketurl == null || pressSocketurl == null) {
				Modal.warning({ title: langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields' });
				return false;
			}

			let data = JSON.stringify({
				'wellinfoId': WellId,
				'dasip': dasIp,
				'dasport': dasPort,
				'dtsip': dtsIp,
				'dtsport': dtsPort,
				'pressureip': pressureip,
				'pressureport': pressureport,
				'algorithmId': algorithamId,
				'sampleRate': sampleRate,
				'ampThresholds': {
					'maxThreshold': maxAmpThreshold,
					'minThreshold': minAmpThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'ampThresholdsOverride': false,
				'tempThresholds': {
					'maxThreshold': maxTempThreshold,
					'minThreshold': minTempThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'tempThresholdsOverride': false,
				'pressThresholds': {
					'maxThreshold': maxPressureThreshold,
					'minThreshold': minPressureThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'pressThresholdsOverride': false,
				'ampFreqThresholds': {
					'maxThreshold': maxAmpFreqThresholds,
					'minThreshold': minAmpFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'ampFreqThresholdsOverride': false,
				'tempFreqThresholds': {
					'maxThreshold': maxTempFreqThresholds,
					'minThreshold': minTempFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'tempFreqThresholdsOverride': false,
				'pressFreqThresholds': {
					'maxThreshold': maxPressFreqThresholds,
					'minThreshold': minPressFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'pressFreqThresholdsOverride': false,
				'pipelineId': parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`)),
				'dasSocketurl': dasSocketurl,
				'dtsSocketurl': dtsSocketurl,
				'pressSocketurl': pressSocketurl,
				'dasSocketurlEnabled': dasSocketurlEnabled,
				'dtsSocketurlEnabled': dtsSocketurlEnabled,
				'pressSocketurlEnabled': pressSocketurlEnabled
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/leakdetectionconfig`,
				timeout: timeout,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authtoken}`
				},
				data: data
			};

			axios.request(config)
				.then((response) => {
					if (response.status == 200 || response.status == 201) {
						Modal.success({ title: langdata && langdata.Graphdetailssavedsuccessfully ? langdata.Graphdetailssavedsuccessfully : 'Graph details saved successfully.' });
						loadSandingress();
						setAddModal(false);
						props.setOpenModal(false);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletosavegraphdetails ? langdata.Unabletosavegraphdetails : 'Unable to save graph details' });
					}
				})
				.catch((error) => {
					console.log(error);
					if (error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration: 5,
							maxCount: 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletosavegraphdetails ? langdata.Unabletosavegraphdetails : 'Unable to save graph details' });
					}
				});
			addFormik.resetForm();
		}
	});

	const editFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			WellId: data.updateData && data.updateData.wellinfoId,
			algorithamId: data.updateData && data.updateData.algorithmId,
			sampleRate: data.updateData && data.updateData.sampleRate,
			dasIp: data.updateData && data.updateData.dasip,
			dasPort: data.updateData && data.updateData.dasport,
			minAmpThreshold: data.updateData && data.updateData.ampThresholds && data.updateData.ampThresholds.minThreshold,
			maxAmpThreshold: data.updateData && data.updateData.ampThresholds && data.updateData.ampThresholds.maxThreshold,
			minAmpFreqThresholds: data.updateData && data.updateData.ampFreqThresholds && data.updateData.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds: data.updateData && data.updateData.ampFreqThresholds && data.updateData.ampFreqThresholds.maxThreshold,
			dtsIp: data.updateData && data.updateData.dtsip,
			dtsPort: data.updateData && data.updateData.dtsport,
			minTempThreshold: data.updateData && data.updateData.tempThresholds && data.updateData.tempThresholds.minThreshold,
			maxTempThreshold: data.updateData && data.updateData.tempThresholds && data.updateData.tempThresholds.maxThreshold,
			minTempFreqThresholds: data.updateData && data.updateData.tempFreqThresholds && data.updateData.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds: data.updateData && data.updateData.tempFreqThresholds && data.updateData.tempFreqThresholds.maxThreshold,
			pressureip: data.updateData && data.updateData.pressureip,
			pressureport: data.updateData && data.updateData.pressureport,
			minPressureThreshold: data.updateData && data.updateData.pressThresholds && data.updateData.pressThresholds.minThreshold,
			maxPressureThreshold: data.updateData && data.updateData.pressThresholds && data.updateData.pressThresholds.maxThreshold,
			minPressFreqThresholds: data.updateData && data.updateData.pressFreqThresholds && data.updateData.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds: data.updateData && data.updateData.pressFreqThresholds && data.updateData.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride: data.updateData && data.updateData.ampThresholdsOverride,
			tempThresholdsOverride: data.updateData && data.updateData.tempThresholdsOverride,
			pressThresholdsOverride: data.updateData && data.updateData.pressThresholdsOverride,
			ampFreqThresholdsOverride: data.updateData && data.updateData.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride: data.updateData && data.updateData.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride: data.updateData && data.updateData.pressFreqThresholdsOverride,
			defaultminAmpThreshold: data.updateData && data.updateData.ampThresholds && data.updateData.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold: data.updateData && data.updateData.ampThresholds && data.updateData.ampThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds: data.updateData && data.updateData.ampFreqThresholds && data.updateData.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds: data.updateData && data.updateData.ampFreqThresholds && data.updateData.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold: data.updateData && data.updateData.tempThresholds && data.updateData.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold: data.updateData && data.updateData.tempThresholds && data.updateData.tempThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds: data.updateData && data.updateData.tempFreqThresholds && data.updateData.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds: data.updateData && data.updateData.tempFreqThresholds && data.updateData.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold: data.updateData && data.updateData.pressThresholds && data.updateData.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold: data.updateData && data.updateData.pressThresholds && data.updateData.pressThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds: data.updateData && data.updateData.pressFreqThresholds && data.updateData.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds: data.updateData && data.updateData.pressFreqThresholds && data.updateData.pressFreqThresholds.defaultmaxThreshold,
			dasSocketurl: data.updateData && data.updateData.dasSocketurl,
			dtsSocketurl: data.updateData && data.updateData.dtsSocketurl,
			pressSocketurl: data.updateData && data.updateData.pressSocketurl,
			dasSocketurlEnabled: data.updateData && data.updateData.dasSocketurlEnabled,
			dtsSocketurlEnabled: data.updateData && data.updateData.dtsSocketurlEnabled,
			pressSocketurlEnabled: data.updateData && data.updateData.pressSocketurlEnabled
		},
		onSubmit: (values) => {
			const { WellId, algorithamId, sampleRate, dasIp, dasPort, minAmpThreshold, maxAmpThreshold, minAmpFreqThresholds, maxAmpFreqThresholds, dtsIp, dtsPort, minTempThreshold, maxTempThreshold,
				minTempFreqThresholds, maxTempFreqThresholds, pressureip, pressureport,
				minPressureThreshold, maxPressureThreshold, minPressFreqThresholds, maxPressFreqThresholds,
				dasSocketurl, dtsSocketurl, pressSocketurl, dasSocketurlEnabled, dtsSocketurlEnabled, pressSocketurlEnabled } = values;


			if (WellId == null || algorithamId == null || !isNaN(parseInt(sampleRate)) == false || dasIp == null || !isNaN(parseInt(dasPort)) == false || !isNaN(parseInt(minAmpThreshold)) == false ||
				!isNaN(parseInt(maxAmpThreshold)) == false || !isNaN(parseInt(minAmpFreqThresholds)) == false || !isNaN(parseInt(maxAmpFreqThresholds)) == false ||
				dtsIp == null || !isNaN(parseInt(dtsPort)) == false || !isNaN(parseInt(minTempThreshold)) == false || !isNaN(parseInt(maxTempThreshold)) == false ||
				!isNaN(parseInt(minTempFreqThresholds)) == false || !isNaN(parseInt(maxTempFreqThresholds)) == false || !isNaN(parseInt(pressureip)) == false ||
				!isNaN(parseInt(pressureport)) == false || !isNaN(parseInt(minPressureThreshold)) == false || !isNaN(parseInt(maxPressureThreshold)) == false ||
				!isNaN(parseInt(minPressFreqThresholds)) == false || !isNaN(parseInt(maxPressFreqThresholds || dasSocketurl == null || dtsSocketurl == null || pressSocketurl == null)) == false) {
				Modal.warning({ title: langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields' });
				return false;
			}

			let data = JSON.stringify({
				'wellinfoId': WellId,
				'dasip': dasIp,
				'dasport': dasPort,
				'dtsip': dtsIp,
				'dtsport': dtsPort,
				'pressureip': pressureip,
				'pressureport': pressureport,
				'algorithmId': algorithamId,
				'sampleRate': sampleRate,
				'ampThresholds': {
					'maxThreshold': maxAmpThreshold,
					'minThreshold': minAmpThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'ampThresholdsOverride': editFormik.values.ampThresholdsOverride,
				'tempThresholds': {
					'maxThreshold': maxTempThreshold,
					'minThreshold': minTempThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'tempThresholdsOverride': editFormik.values.tempThresholdsOverride,
				'pressThresholds': {
					'maxThreshold': maxPressureThreshold,
					'minThreshold': minPressureThreshold,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'pressThresholdsOverride': editFormik.values.pressThresholdsOverride,
				'ampFreqThresholds': {
					'maxThreshold': maxAmpFreqThresholds,
					'minThreshold': minAmpFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'ampFreqThresholdsOverride': editFormik.values.ampFreqThresholdsOverride,
				'tempFreqThresholds': {
					'maxThreshold': maxTempFreqThresholds,
					'minThreshold': minTempFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'tempFreqThresholdsOverride': editFormik.values.tempFreqThresholdsOverride,
				'pressFreqThresholds': {
					'maxThreshold': maxPressFreqThresholds,
					'minThreshold': minPressFreqThresholds,
					'defaultmaxThreshold': 100,
					'defaultminThreshold': 10
				},
				'pressFreqThresholdsOverride': editFormik.values.pressFreqThresholdsOverride,
				'dasSocketurl': dasSocketurl,
				'dtsSocketurl': dtsSocketurl,
				'pressSocketurl': pressSocketurl,
				'dasSocketurlEnabled': dasSocketurlEnabled,
				'dtsSocketurlEnabled': dtsSocketurlEnabled,
				'pressSocketurlEnabled': pressSocketurlEnabled
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/leakdetectionconfig/${updateId}`,
				timeout: timeout,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authtoken}`
				},
				data: data
			};

			axios.request(config)
				.then((response) => {
					if (response.status == 200 || response.status == 201) {
						loadSandingress();
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setEditModal(false);
						setDasModal(false);
						setDtsModal(false);
						setPressureModal(false);
						dispatch({ type: WELL_GRAPH_UPDATE_DATA, payload: null });
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if (error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration: 5,
							maxCount: 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
			editFormik.resetForm();
		}
	});

	const handleDelete = () => {

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/leakdetectionconfig/${deleteId}`,
			timeout: timeout,
			headers: {
				Authorization: `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					Modal.success({ title: langdata && langdata.Graphdetailssuccessfullydeleted ? langdata.Graphdetailssuccessfullydeleted : 'Graph details successfully deleted.' });
					loadSandingress();
				} else {
					Modal.warning({ title: langdata && langdata.Unabletodeletegraphdetails ? langdata.Unabletodeletegraphdetails : 'Unable to delete graph details' });
				}
			})
			.catch((error) => {
				console.log(error);
				if (error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration: 5,
						maxCount: 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
					});
				} else {
					Modal.warning({ title: langdata && langdata.Unabletodeletegraphdetails ? langdata.Unabletodeletegraphdetails : 'Unable to delete graph details' });
				}
			});
		setDeleteModal(false);
	};

	const handleOk = () => {
		setAddModal(false);
		props.setOpenModal(false);
		setEditModal(false);
		setDeleteModal(false);
		setDasModal(false);
		setDtsModal(false);
		setPressureModal(false);
		editFormik.resetForm();
		addFormik.resetForm();
	};
	const handleCancel = () => {
		setAddModal(false);
		props.setOpenModal(false);
		setEditModal(false);
		setDeleteModal(false);
		setDasModal(false);
		setDtsModal(false);
		setPressureModal(false);
		editFormik.resetForm();
		addFormik.resetForm();
	};

	return (
		<>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems: 'center',
			}}
			>
				<Col span={24}>
					<Row justify="end">

					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems: 'center'
			}} >
				<Col span={24}>
					<Table
						scroll={{
							x: 900,
						}}
						columns={columns}
						dataSource={data.sandIngressData}
					/>

				</Col>
			</Row>
			<Modal
				title={langdata && langdata.AddLeakDetection ? langdata.AddLeakDetection : 'Add Leak Detection'}
				centered
				open={addModal}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.WellName ? langdata.WellName : 'Well Name'}
								rules={[{ required: true, message: langdata && langdata.Pleaseselectthewellname ? langdata.Pleaseselectthewellname : 'Please select the well name' }]} required>
								<Select
									style={{ minWidth: '200px', margin: '5px' }}
									showSearch
									// filterOption={filterOption}
									placeholder={langdata && langdata.Pleaseselectthewell ? langdata.Pleaseselectthewell : 'Please select the well'}
									optionFilterProp="children"
									options={welldata}
									onChange={(value) => addFormik.setFieldValue('WellId', value)}
								// onSelect={handleWellInfo}
								// value={wellInfoId}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.Algoritham ? langdata.Algoritham : 'Algoritham'}
								rules={[{ required: true, message: langdata && langdata.Pleaseselectthealgoritham ? langdata.Pleaseselectthealgoritham : 'Please select the algoritham' }]} required>
								<Select
									style={{ minWidth: '200px', margin: '5px' }}
									showSearch
									// filterOption={filterOption}
									placeholder={langdata && langdata.Pleaseselectthealgoritham ? langdata.Pleaseselectthealgoritham : 'Please select the algoritham'}
									optionFilterProp="children"
									options={algoritham}
									onChange={(value) => addFormik.setFieldValue('algorithamId', value)}
								// onSelect={handleWellInfo}
								// value={wellInfoId}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.SampleRate ? langdata.SampleRate : 'Sample Rate'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthesamplerate ? langdata.Pleaseenterthesamplerate : 'Please enter the sample rate' }]} required>
								<Input
									name="sampleRate"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthesamplerate ? langdata.Pleaseenterthesamplerate : 'Please enter the DAS IP'}
									onChange={addFormik.handleChange}
									value={addFormik.values.sampleRate}
									min={0}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS : 'DAS'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {addFormik.values.dasSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP' }]} required>
								<Input
									name="dasIp"
									placeholder={langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP'}
									onChange={addFormik.handleChange}
									value={addFormik.values.dasIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port' }]} required>
								<Input
									name="dasPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port'}
									onChange={addFormik.handleChange}
									value={addFormik.values.dasPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { addFormik.setFieldValue('dasSocketurlEnabled', value); }}
										defaultChecked={addFormik.values.dasSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Badge.Ribbon text={addFormik.values.dasSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={addFormik.values.dasSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}</p>&nbsp;&nbsp;&nbsp;&nbsp;
										<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdas.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dasSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={addFormik.handleChange}
										value={addFormik.values.dasSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumAmplitudeThreshold ? langdata.MinimumAmplitudeThreshold : 'Minimum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold' }]} required>
								<Input
									name="minAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minAmpThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MaximumAmplitudeThreshold ? langdata.MaximumAmplitudeThreshold : 'Maximum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold' }]} required>
								<Input
									name="maxAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxAmpThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minAmpFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxAmpFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>

					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS : 'DTS'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {addFormik.values.dtsSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DTSIP ? langdata.DTSIP : 'DTS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP' }]} required>
								<Input
									name="dtsIp"
									placeholder={langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP'}
									onChange={addFormik.handleChange}
									value={addFormik.values.dtsIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DTSPort ? langdata.DTSPort : 'DTS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port' }]} required>
								<Input
									name="dtsPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port'}
									onChange={addFormik.handleChange}
									value={addFormik.values.dtsPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { addFormik.setFieldValue('dtsSocketurlEnabled', value); }}
										defaultChecked={addFormik.values.dtsSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={addFormik.values.dtsSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={addFormik.values.dtsSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}</p>&nbsp;&nbsp;&nbsp;&nbsp;
										<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdts.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dtsSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={addFormik.handleChange}
										value={addFormik.values.dtsSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumTemperatureThreshold ? langdata.MinimumTemperatureThreshold : 'Minimum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold' }]} required>
								<Input
									name="minTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minTempThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MaximumTemperatureThreshold ? langdata.MaximumTemperatureThreshold : 'Maximum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold' }]} required>
								<Input
									name="maxTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxTempThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minTempFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxTempFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure : 'Pressure'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {addFormik.values.pressureSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.PressureIP ? langdata.PressureIP : 'Pressure IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP' }]} required>
								<Input
									name="pressureip"
									placeholder={langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP'}
									onChange={addFormik.handleChange}
									value={addFormik.values.pressureip}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.PressurePort ? langdata.PressurePort : 'Pressure Port'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port' }]} required>
								<Input
									name="pressureport"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port'}
									onChange={addFormik.handleChange}
									value={addFormik.values.pressureport}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { addFormik.setFieldValue('pressSocketurlEnabled', value); }}
										defaultChecked={addFormik.values.pressSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={addFormik.values.pressSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={addFormik.values.pressSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakpress.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="pressSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={addFormik.handleChange}
										value={addFormik.values.pressSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumPressureThreshold ? langdata.MinimumPressureThreshold : 'Minimum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold' }]} required>
								<Input
									name="minPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minPressureThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MaximumPressureThreshold ? langdata.MaximumPressureThreshold : 'Maximum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum pressure threshold' }]} required>
								<Input
									name="maxPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumpressurethreshold ? langdata.Pleaseenterthemaximumpressurethreshold : 'Please enter the maximum pressure threshold'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxPressureThreshold}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.minPressFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={addFormik.handleChange}
									value={addFormik.values.maxPressFreqThresholds}
									min={0}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>



			<Modal
				title={langdata && langdata.EditLeakDetection ? langdata.EditLeakDetection : 'Edit Leak Detection'}
				centered
				open={editModal}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.WellName ? langdata.WellName : 'Well Name'}
								rules={[{ required: true, message: langdata && langdata.Pleaseselectthewellname ? langdata.Pleaseselectthewellname : 'Please select the well name' }]} required>
								<Select
									style={{ minWidth: '200px', margin: '5px' }}
									showSearch
									// filterOption={filterOption}
									placeholder={langdata && langdata.Pleaseselectthewell ? langdata.Pleaseselectthewell : 'Please select the well'}
									optionFilterProp="children"
									options={welldata}
									onChange={(value) => editFormik.setFieldValue('WellId', value)}
									// onSelect={handleWellInfo}
									value={editFormik.values.WellId}
									disabled
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.Algoritham ? langdata.Algoritham : 'Algoritham'}
								rules={[{ required: true, message: langdata && langdata.Pleaseselectthealgoritham ? langdata.Pleaseselectthealgoritham : 'Please select the algoritham' }]} required>
								<Select
									style={{ minWidth: '200px', margin: '5px' }}
									showSearch
									// filterOption={filterOption}
									placeholder={langdata && langdata.Pleaseselectthealgoritham ? langdata.Pleaseselectthealgoritham : 'Please select the algoritham'}
									optionFilterProp="children"
									options={algoritham}
									onChange={(value) => editFormik.setFieldValue('algorithamId', value)}
									// onSelect={handleWellInfo}
									value={editFormik.values.algorithamId}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.SampleRate ? langdata.SampleRate : 'Sample Rate'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthesamplerate ? langdata.Pleaseenterthesamplerate : 'Please enter the sample rate' }]} required>
								<Input
									name="sampleRate"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthesamplerate ? langdata.Pleaseenterthesamplerate : 'Please enter the DAS IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.sampleRate}
									min={0}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS : 'DAS'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {editFormik.values.dasSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP' }]} required>
								<Input
									name="dasIp"
									placeholder={langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dasIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port' }]} required>
								<Input
									name="dasPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dasPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('dasSocketurlEnabled', value); }}
										checked={editFormik.values.dasSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.dasSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.dasSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdas.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dasSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.dasSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumAmplitudeThreshold ? langdata.MinimumAmplitudeThreshold : 'Minimum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold' }]} required>
								<Input
									name="minAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampThresholdsOverride == true ? editFormik.values.minAmpThreshold : editFormik.values.defaultminAmpThreshold}
									disabled={editFormik.values.ampThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>

						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumAmplitudeThreshold ? langdata.MaximumAmplitudeThreshold : 'Maximum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold' }]} required>
								<Input
									name="maxAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampThresholdsOverride == true ? editFormik.values.maxAmpThreshold : editFormik.values.defaultmaxAmpThreshold}
									disabled={editFormik.values.ampThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('ampThresholdsOverride', value); }} checked={editFormik.values.ampThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampFreqThresholdsOverride == true ? editFormik.values.minAmpFreqThresholds : editFormik.values.defaultminAmpFreqThresholds}
									disabled={editFormik.values.ampFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampFreqThresholdsOverride == true ? editFormik.values.maxAmpFreqThresholds : editFormik.values.defaultmaxAmpFreqThresholds}
									disabled={editFormik.values.ampFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('ampFreqThresholdsOverride', value); }} checked={editFormik.values.ampFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS : 'DTS'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {editFormik.values.dtsSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DTSIP ? langdata.DTSIP : 'DTS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP' }]} required>
								<Input
									name="dtsIp"
									placeholder={langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dtsIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DTSPort ? langdata.DTSPort : 'DTS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port' }]} required>
								<Input
									name="dtsPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dtsPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('dtsSocketurlEnabled', value); }}
										checked={editFormik.values.dtsSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.dtsSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.dtsSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdts.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dtsSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.dtsSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumTemperatureThreshold ? langdata.MinimumTemperatureThreshold : 'Minimum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold' }]} required>
								<Input
									name="minTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempThresholdsOverride == true ? editFormik.values.minTempThreshold : editFormik.values.defaultminTempThreshold}
									disabled={editFormik.values.tempThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumTemperatureThreshold ? langdata.MaximumTemperatureThreshold : 'Maximum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold' }]} required>
								<Input
									name="maxTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempThresholdsOverride == true ? editFormik.values.maxTempThreshold : editFormik.values.defaultmaxTempThreshold}
									disabled={editFormik.values.tempThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('tempThresholdsOverride', value); }} checked={editFormik.values.tempThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempFreqThresholdsOverride == true ? editFormik.values.minTempFreqThresholds : editFormik.values.defaultminTempFreqThresholds}
									disabled={editFormik.values.tempFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempFreqThresholdsOverride == true ? editFormik.values.maxTempFreqThresholds : editFormik.values.defaultmaxTempFreqThresholds}
									disabled={editFormik.values.tempFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('tempFreqThresholdsOverride', value); }} checked={editFormik.values.tempFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{ fontSize: '16px', color: 'grey' }}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure : 'Pressure'}</b></Typography>
					<Divider />
					<Row gutter={24}>
						{/* {editFormik.values.pressureSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.PressureIP ? langdata.PressureIP : 'Pressure IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP' }]} required>
								<Input
									name="pressureip"
									placeholder={langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressureip}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.PressurePort ? langdata.PressurePort : 'Pressure Port'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port' }]} required>
								<Input
									name="pressureport"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressureport}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressSocketurlEnabled', value); }}
										checked={editFormik.values.pressSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.pressSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.pressSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakpress.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="pressSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.pressSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumPressureThreshold ? langdata.MinimumPressureThreshold : 'Minimum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold' }]} required>
								<Input
									name="minPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressThresholdsOverride == true ? editFormik.values.minPressureThreshold : editFormik.values.defaultminPressureThreshold}
									disabled={editFormik.values.pressThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumPressureThreshold ? langdata.MaximumPressureThreshold : 'Maximum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum pressure threshold' }]} required>
								<Input
									name="maxPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumpressurethreshold ? langdata.Pleaseenterthemaximumpressurethreshold : 'Please enter the maximum pressure threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressThresholdsOverride == true ? editFormik.values.maxPressureThreshold : editFormik.values.defaultmaxPressureThreshold}
									disabled={editFormik.values.pressThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressThresholdsOverride', value); }} checked={editFormik.values.pressThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressFreqThresholdsOverride == true ? editFormik.values.minPressFreqThresholds : editFormik.values.defaultminPressFreqThresholds}
									disabled={editFormik.values.pressFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressFreqThresholdsOverride == true ? editFormik.values.maxPressFreqThresholds : editFormik.values.defaultmaxPressFreqThresholds}
									disabled={editFormik.values.pressFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressFreqThresholdsOverride', value); }} checked={editFormik.values.pressFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>


			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={deleteModal} onOk={handleOk} onCancel={handleCancel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems: 'center'
				}} >
					<Col span={1}>
						<DeleteOutlined style={{ fontSize: '14px' }} />
					</Col>
					<Col span={23}>{langdata && langdata.Areyousureyouwanttodeletethisgraph ? langdata.Areyousureyouwanttodeletethisgraph : 'Are you sure you want to delete this graph?'}</Col>
				</Row>
			</Modal>


			<Modal
				title={langdata && langdata.DAS ? langdata.DAS : 'DAS'}
				centered
				open={dasModal}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" >

					<Row gutter={24}>
						{/* {editFormik.values.dasSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP' }]} required>
								<Input
									name="dasIp"
									placeholder={langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dasIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port' }]} required>
								<Input
									name="dasPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dasPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('dasSocketurlEnabled', value); }}
										checked={editFormik.values.dasSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.dasSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.dasSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdas.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dasSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.dasSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}

						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumAmplitudeThreshold ? langdata.MinimumAmplitudeThreshold : 'Minimum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold' }]} required>
								<Input
									name="minAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampThresholdsOverride == true ? editFormik.values.minAmpThreshold : editFormik.values.defaultminAmpThreshold}
									disabled={editFormik.values.ampThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>

						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumAmplitudeThreshold ? langdata.MaximumAmplitudeThreshold : 'Maximum Amplitude Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold' }]} required>
								<Input
									name="maxAmpThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampThresholdsOverride == true ? editFormik.values.maxAmpThreshold : editFormik.values.defaultmaxAmpThreshold}
									disabled={editFormik.values.ampThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('ampThresholdsOverride', value); }} checked={editFormik.values.ampThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampFreqThresholdsOverride == true ? editFormik.values.minAmpFreqThresholds : editFormik.values.defaultminAmpFreqThresholds}
									disabled={editFormik.values.ampFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxAmpFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.ampFreqThresholdsOverride == true ? editFormik.values.maxAmpFreqThresholds : editFormik.values.defaultmaxAmpFreqThresholds}
									disabled={editFormik.values.ampFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('ampFreqThresholdsOverride', value); }} checked={editFormik.values.ampFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>


			<Modal
				title={langdata && langdata.DTS ? langdata.DTS : 'DTS'}
				centered
				open={dtsModal}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						{/* {editFormik.values.dtsSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.DTSIP ? langdata.DTSIP : 'DTS IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP' }]} required>
								<Input
									name="dtsIp"
									placeholder={langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dtsIp}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.DTSPort ? langdata.DTSPort : 'DTS Port'}
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port' }]} required>
								<Input
									name="dtsPort"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.dtsPort}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('dtsSocketurlEnabled', value); }}
										checked={editFormik.values.dtsSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.dtsSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.dtsSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakdts.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="dtsSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.dtsSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumTemperatureThreshold ? langdata.MinimumTemperatureThreshold : 'Minimum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold' }]} required>
								<Input
									name="minTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempThresholdsOverride == true ? editFormik.values.minTempThreshold : editFormik.values.defaultminTempThreshold}
									disabled={editFormik.values.tempThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumTemperatureThreshold ? langdata.MaximumTemperatureThreshold : 'Maximum Temperature Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold' }]} required>
								<Input
									name="maxTempThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempThresholdsOverride == true ? editFormik.values.maxTempThreshold : editFormik.values.defaultmaxTempThreshold}
									disabled={editFormik.values.tempThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('tempThresholdsOverride', value); }} checked={editFormik.values.tempThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempFreqThresholdsOverride == true ? editFormik.values.minTempFreqThresholds : editFormik.values.defaultminTempFreqThresholds}
									disabled={editFormik.values.tempFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxTempFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.tempFreqThresholdsOverride == true ? editFormik.values.maxTempFreqThresholds : editFormik.values.defaultmaxTempFreqThresholds}
									disabled={editFormik.values.tempFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('tempFreqThresholdsOverride', value); }} checked={editFormik.values.tempFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>

				</Form>
			</Modal>

			<Modal
				title={langdata && langdata.Pressure ? langdata.Pressure : 'Pressure'}
				centered
				open={pressureModal}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						{/* {editFormik.values.pressureSSLOverride == false ?
							<> */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.PressureIP ? langdata.PressureIP : 'Pressure IP'}
								rules={[{ required: true, message: langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP' }]} required>
								<Input
									name="pressureip"
									placeholder={langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressureip}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.PressurePort ? langdata.PressurePort : 'Pressure Port'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port' }]} required>
								<Input
									name="pressureport"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressureport}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.SSLEnabled ? langdata.SSLEnabled : 'SSL Enabled'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressSocketurlEnabled', value); }}
										checked={editFormik.values.pressSocketurlEnabled} />
								</Tooltip>
							</Form.Item>
						</Col>
						{/* </>
							: */}
						<Col span={24}>
							<Badge.Ribbon text={editFormik.values.pressSocketurlEnabled == false ? langdata && langdata.Disabled ? langdata.Disabled : 'Disabled' : langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'}
								style={{ marginTop: '10px' }}
								color={editFormik.values.pressSocketurlEnabled == false ? '#a81e1e' : '#179668'}>
								<Form.Item
									label={<><p>{langdata && langdata.SocketURLL ? langdata.SocketURL : 'Socket URL'}
									</p>&nbsp;&nbsp;&nbsp;&nbsp;<Tag color="blue" style={{ marginBottom: '10px', textAlign: 'center', justifyContent: 'center', display: 'flex', margin: 'auto' }}>
											leakpress.tranzmeo.com</Tag></>}
									rules={[{ required: true, message: langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL' }]} required>
									<Input
										name="pressSocketurl"
										placeholder={langdata && langdata.PleaseentertheSocketURL ? langdata.PleaseentertheSocketURL : 'Please enter the Socket URL'}
										onChange={editFormik.handleChange}
										value={editFormik.values.pressSocketurl}
									/>
								</Form.Item>
							</Badge.Ribbon>
						</Col>
						{/* } */}
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.MinimumPressureThreshold ? langdata.MinimumPressureThreshold : 'Minimum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold' }]} required>
								<Input
									name="minPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressThresholdsOverride == true ? editFormik.values.minPressureThreshold : editFormik.values.defaultminPressureThreshold}
									disabled={editFormik.values.pressThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.MaximumPressureThreshold ? langdata.MaximumPressureThreshold : 'Maximum Pressure Threshold'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum pressure threshold' }]} required>
								<Input
									name="maxPressureThreshold"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthemaximumpressurethreshold ? langdata.Pleaseenterthemaximumpressurethreshold : 'Please enter the maximum pressure threshold'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressThresholdsOverride == true ? editFormik.values.maxPressureThreshold : editFormik.values.defaultmaxPressureThreshold}
									disabled={editFormik.values.pressThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressThresholdsOverride', value); }} checked={editFormik.values.pressThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={langdata && langdata.FrequencyLowerLimit ? langdata.FrequencyLowerLimit : 'Frequency Lower Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit' }]} required>
								<Input
									name="minPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencylowerlimit ? langdata.Pleaseenterthefrequencylowerlimit : 'Please enter the frequency lower limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressFreqThresholdsOverride == true ? editFormik.values.minPressFreqThresholds : editFormik.values.defaultminPressFreqThresholds}
									disabled={editFormik.values.pressFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={11}>
							<Form.Item
								label={langdata && langdata.FrequencyUpperLimit ? langdata.FrequencyUpperLimit : 'Frequency Upper Limit'}
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit' }]} required>
								<Input
									name="maxPressFreqThresholds"
									type='number'
									onWheel={(e) => e.target.blur()}
									placeholder={langdata && langdata.Pleaseenterthefrequencyupperlimit ? langdata.Pleaseenterthefrequencyupperlimit : 'Please enter the frequency upper limit'}
									onChange={editFormik.handleChange}
									value={editFormik.values.pressFreqThresholdsOverride == true ? editFormik.values.maxPressFreqThresholds : editFormik.values.defaultmaxPressFreqThresholds}
									disabled={editFormik.values.pressFreqThresholdsOverride == true ? false : true}
									min={0}
								/>
							</Form.Item>
						</Col>
						<Col span={1}>
							<Form.Item
								label={' '} >
								<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
									<Switch size="small" onClick={(value) => { editFormik.setFieldValue('pressFreqThresholdsOverride', value); }} checked={editFormik.values.pressFreqThresholdsOverride} />
								</Tooltip>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Spin fullscreen spinning={data.loading} />
		</>
	);
}